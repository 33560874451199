import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "@/locales";

Vue.use(VueI18n);

const lang = localStorage.getItem("lang") || "pt-br";

export const i18n = new VueI18n({
  locale: lang, // Idioma padrão
  fallbackLocale: "pt-br", // Idioma de fallback
  messages,
});
