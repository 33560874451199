var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.products && !_vm.hideLabels)?_c('v-tooltip',{attrs:{"top":"","height":_vm.height},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.hasSubProductsOrServices)?_c('v-badge',{attrs:{"avatar":"","bordered":"","overlap":"","bottom":"","color":"grey darken-1","offset-x":_vm.badgeXDistance,"offset-y":_vm.badgeYDistance},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(_vm.isConectaControlMobile)?_c('v-icon',{staticClass:"pt-2",attrs:{"color":"white","size":"12"},domProps:{"textContent":_vm._s('mdi-cellphone-key')}}):_vm._e(),(_vm.isConectaControlAD)?_c('span',{staticClass:"d-flex justify-center align-center conecta-ad"},[_vm._v(" AD ")]):_vm._e(),(_vm.isPontoMais)?_c('span',{staticClass:"d-flex justify-center align-center conecta-ad"},[_vm._v(" PM ")]):_vm._e(),(_vm.isSignAutomation)?_c('v-icon',{staticClass:"pt-2",attrs:{"color":"white","size":"15"},domProps:{"textContent":_vm._s('mdi-autorenew')}}):_vm._e()]},proxy:true}],null,true)},[_c('v-img',_vm._g(_vm._b({class:`${_vm.grey ? 'grey' : ''} ma-${_vm.margin} ${_vm.customClass} ${
          _vm.black ? 'invert' : ''
        }`,attrs:{"contain":"","src":_vm.src,"width":_vm.width || _vm.height,"height":_vm.height}},'v-img',attrs,false),on))],1):_c('v-img',_vm._g(_vm._b({class:`${_vm.grey ? 'grey' : ''} ma-${_vm.margin} ${_vm.customClass} ${
        _vm.black ? 'invert' : ''
      }`,attrs:{"contain":"","src":_vm.src,"width":_vm.width || _vm.height,"height":_vm.height}},'v-img',attrs,false),on))]}}],null,false,3526874749)},[_c('span',[_vm._v(_vm._s(_vm.productNames[_vm.product]))])]):(_vm.products && _vm.hideLabels)?_c('div',[(_vm.hasSubProductsOrServices)?_c('v-badge',{attrs:{"avatar":"","bordered":"","overlap":"","bottom":"","color":"grey darken-1","offset-x":_vm.noMargins ? 16 : 20,"offset-y":_vm.noMargins ? 11 : 13},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(_vm.isConectaControlMobile)?_c('v-icon',{staticClass:"pt-2",attrs:{"color":"white","size":"12"},domProps:{"textContent":_vm._s('mdi-cellphone-key')}}):_vm._e(),(_vm.isConectaControlAD)?_c('span',{staticClass:"d-flex justify-center align-center conecta-ad"},[_vm._v(" AD ")]):_vm._e(),(_vm.isPontoMais)?_c('span',{staticClass:"d-flex justify-center align-center conecta-ad"},[_vm._v(" PM ")]):_vm._e(),(_vm.isSignAutomation)?_c('v-icon',{staticClass:"pt-2",attrs:{"color":"white","size":"12"},domProps:{"textContent":_vm._s('mdi-autorenew')}}):_vm._e()]},proxy:true}],null,false,3887851669)},[_c('v-img',{class:`${_vm.grey ? 'grey' : ''} ma-${_vm.margin} ${_vm.customClass} ${
        _vm.black ? 'invert' : ''
      }`,attrs:{"contain":"","src":_vm.src,"width":_vm.width || _vm.height,"height":_vm.height}})],1):_c('v-img',{class:`${_vm.grey ? 'grey' : ''} ma-${_vm.margin} ${_vm.customClass} ${
      _vm.black ? 'invert' : ''
    }`,attrs:{"contain":"","src":_vm.src,"width":_vm.width || _vm.height,"height":_vm.height}})],1):(!_vm.isIcon)?_c('v-img',{class:`${_vm.grey ? 'grey' : ''} ma-${_vm.margin} ${_vm.customClass} ${
    _vm.black ? 'invert' : ''
  }`,attrs:{"contain":"","src":_vm.src,"width":_vm.width || _vm.height,"height":_vm.height}}):_c('div',{staticClass:"d-flex justify-center align-start"},[_c('v-icon',{attrs:{"color":"accent","size":_vm.height}},[_vm._v(_vm._s(_vm.icons[_vm.product]))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }