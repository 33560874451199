<template>
  <i :class="standardClass" />
</template>
<script>
export default {
  props: {
    tag: {
      type: String,
      default: () => "",
    },
    icon: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    standardClass() {
      if (this.icon) {
        const spaceSplit = this.icon.split(" ");
        if (spaceSplit.length > 1) {
          return this.icon;
        }
        const prefix = this.icon.split("-")[0];
        return `${prefix} ${this.icon}`;
      }
      return "mdi";
    },
  },
};
</script>
