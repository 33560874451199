const srcHubspotDev = "//js.hs-scripts.com/43532016.js";
const srcHubspotProd = "//js.hs-scripts.com/40115374.js";

function getSrcByEnv() {
  if (process.env.NODE_ENV === "production") {
    return srcHubspotProd;
  }
  return srcHubspotDev;
}

export function setTrackingPathsHubspot(path) {
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push(["setPath", path]);
  _hsq.push(["trackPageView"]);
}

export function setPathDefaultHubSpotTrack() {
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push(["setPath", "/"]);
  _hsq.push(["trackPageView"]);
}

export function loadHubSpotTracking(currentUser) {
  var srcHubspot = getSrcByEnv();
  var s = document.createElement("script");
  s.src = srcHubspot;
  s.type = "text/javascript";
  s.id = "hs-script-loader";
  s.async = true;
  s.defer = true;

  window.hsConversationsSettings = {
    loadImmediately: false,
  };

  document.body.appendChild(s);

  identifyUserOnHubSpot(currentUser);
}

function identifyUserOnHubSpot(currentUser) {
  var _hsq = (window._hsq = window._hsq || []);
  const user_data = {
    email: currentUser.email,
    firstName: currentUser.first_name,
    lastName: currentUser.last_name,
    company: currentUser.domain,
  };
  _hsq.push(["identify", user_data]);
}

export function reRenderHubSpotChat() {
  window.HubSpotConversations.widget.refresh();
}

export function loadHubSpotChat() {
  window.HubSpotConversations.widget.load();
}

export function removeHubSpotChat() {
  window.HubSpotConversations.widget.remove();
}

export function registerUserToHubspotChat(email, token, isSalesChat) {
  if (window.HubSpotConversations) {
    onConversationsAPIReady(email, token, isSalesChat);
  } else {
    window.hsConversationsOnReady = [
      () => {
        onConversationsAPIReady(email, token, isSalesChat);
      },
    ];
  }
}

function setChatParams(isSalesChat) {
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);

  if (isSalesChat) {
    searchParams.set("sales_chat", "true");
    searchParams.delete("success_chat");
  } else {
    searchParams.set("success_chat", "true");
    searchParams.delete("sales_chat");
  }
  window.history.pushState(
    {},
    "",
    `${currentUrl.pathname}?${searchParams.toString()}`
  );
}

function onConversationsAPIReady(email, token, isSalesChat) {
  window.hsConversationsSettings = {
    identificationEmail: email,
    identificationToken: token,
  };
  setChatParams(isSalesChat);

  window.HubSpotConversations.widget.load();
  window.HubSpotConversations.widget.refresh();
}
