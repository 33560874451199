<template>
  <v-tooltip v-if="products && !hideLabels" top :height="height">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        v-if="hasSubProductsOrServices"
        avatar
        bordered
        overlap
        bottom
        color="grey darken-1"
        :offset-x="badgeXDistance"
        :offset-y="badgeYDistance"
      >
        <template v-slot:badge>
          <v-icon
            v-if="isConectaControlMobile"
            v-text="'mdi-cellphone-key'"
            color="white"
            class="pt-2"
            size="12"
          />
          <span
            v-if="isConectaControlAD"
            class="d-flex justify-center align-center conecta-ad"
          >
            AD
          </span>
          <span
            v-if="isPontoMais"
            class="d-flex justify-center align-center conecta-ad"
          >
            PM
          </span>
          <v-icon
            v-if="isSignAutomation"
            v-text="'mdi-autorenew'"
            color="white"
            class="pt-2"
            size="15"
          />
        </template>
        <v-img
          v-bind="attrs"
          v-on="on"
          contain
          :src="src"
          :width="width || height"
          :height="height"
          :class="`${grey ? 'grey' : ''} ma-${margin} ${customClass} ${
            black ? 'invert' : ''
          }`"
        ></v-img>
      </v-badge>
      <v-img
        v-else
        v-bind="attrs"
        v-on="on"
        contain
        :src="src"
        :width="width || height"
        :height="height"
        :class="`${grey ? 'grey' : ''} ma-${margin} ${customClass} ${
          black ? 'invert' : ''
        }`"
      ></v-img>
    </template>
    <span>{{ productNames[product] }}</span>
  </v-tooltip>
  <div v-else-if="products && hideLabels">
    <v-badge
      v-if="hasSubProductsOrServices"
      avatar
      bordered
      overlap
      bottom
      color="grey darken-1"
      :offset-x="noMargins ? 16 : 20"
      :offset-y="noMargins ? 11 : 13"
    >
      <template v-slot:badge>
        <v-icon
          v-if="isConectaControlMobile"
          v-text="'mdi-cellphone-key'"
          color="white"
          class="pt-2"
          size="12"
        />
        <span
          v-if="isConectaControlAD"
          class="d-flex justify-center align-center conecta-ad"
        >
          AD
        </span>
        <span
          v-if="isPontoMais"
          class="d-flex justify-center align-center conecta-ad"
        >
          PM
        </span>
        <v-icon
          v-if="isSignAutomation"
          v-text="'mdi-autorenew'"
          color="white"
          class="pt-2"
          size="12"
        />
      </template>
      <v-img
        contain
        :src="src"
        :width="width || height"
        :height="height"
        :class="`${grey ? 'grey' : ''} ma-${margin} ${customClass} ${
          black ? 'invert' : ''
        }`"
      ></v-img>
    </v-badge>
    <v-img
      v-else
      contain
      :src="src"
      :width="width || height"
      :height="height"
      :class="`${grey ? 'grey' : ''} ma-${margin} ${customClass} ${
        black ? 'invert' : ''
      }`"
    ></v-img>
  </div>
  <v-img
    v-else-if="!isIcon"
    contain
    :src="src"
    :width="width || height"
    :height="height"
    :class="`${grey ? 'grey' : ''} ma-${margin} ${customClass} ${
      black ? 'invert' : ''
    }`"
  ></v-img>
  <div v-else class="d-flex justify-center align-start">
    <v-icon color="accent" :size="height">{{ icons[product] }}</v-icon>
  </div>
</template>
<script>
import { productNames } from "@/helpers/variables/translateString";
export default {
  props: {
    product: { type: String, required: true },
    width: { type: [String, Number], default: () => "" },
    height: { type: [String, Number], default: () => "65" },
    white: { type: Boolean, default: () => false },
    black: { type: Boolean, default: () => false },
    grey: { type: Boolean, default: () => false },
    full: { type: Boolean, default: () => false },
    noMargins: { type: Boolean, default: () => false },
    customClass: { type: String, default: () => "" },
    products: { type: Boolean, default: () => false },
    hideLabels: { type: Boolean, default: () => false },
  },
  data() {
    return {
      icons: {
        manage_admins: "mdi-account-key",
        reseller: "mdi-bank",
        company_profile: "mdi-store",
        billing: "mdi-credit-card-outline",
        users: "mdi-account-multiple",
        inventory: "mdi-application",
        reports: "mdi-poll",
        application: "mdi-application",
        contact_us: "mdi-face-agent",
        video_plus: "mdi-video-plus",
        compass: "mdi-compass",
        frequently_asked_questions: "mdi-frequently-asked-questions",
        message_alert_outline: "mdi-message-alert-outline",
        workspace_diagnosis: "mdi-format-list-checks",
      },
      productNames,
      logos_icons: {
        conecta_suite: require("@/assets/appslogos/icons/icon-suite.png"),
        conecta_track: require("@/assets/appslogos/icons/icon-track.png"),
        conecta_sign: require("@/assets/appslogos/icons/icon-sign.png"),
        sign_automation: require("@/assets/appslogos/icons/icon-suite.png"),
        conecta_control: require("@/assets/appslogos/icons/icon-control.png"),
        conecta_control_integrations: require("@/assets/appslogos/icons/icon-control.png"),
        conecta_transfer: require("@/assets/appslogos/icons/icon-transfer.png"),
        conecta_support: require("@/assets/appslogos/icons/icon-support.png"),
        plans: require("@/assets/appslogos/icons/iconPlans.png"),
        figma: require("@/assets/appslogos/icons/icon-figma.png"),
        atlassian: require("@/assets/appslogos/icons/icon-atlassian.png"),
        whatsapp: require("@/assets/appslogos/icons/icon-whatsapp.png"),
        chrome: require("@/assets/appslogos/icons/icon-chrome.png"),
        apple: require("@/assets/appslogos/icons/icon-apple.png"),
        android: require("@/assets/appslogos/icons/icon-android.png"),
      },
      logos_icons_white: {
        conecta_suite: require("@/assets/appslogos/icons/IconSuiteBranco.png"),
        conecta_track: require("@/assets/appslogos/icons/IconTrackBranco.png"),
        conecta_sign: require("@/assets/appslogos/icons/IconSuiteBranco.png"),
        conecta_control: require("@/assets/appslogos/icons/IconControlBranco.png"),
        conecta_control_integrations: require("@/assets/appslogos/icons/IconControlBranco.png"),
        conecta_transfer: require("@/assets/appslogos/icons/IconTransferBranco.png"),
        conecta_support: require("@/assets/appslogos/icons/IconSupportBranco.png"),
        reseller: require("@/assets/appslogos/icons/IconSuiteBranco.png"),
        manage_admins: require("@/assets/appslogos/icons/IconSuiteBranco.png"),
        plans: require("@/assets/appslogos/icons/IconPlansBranco.png"),
      },
      logos_full: {
        conecta_suite: require("@/assets/appslogos/full/conecta_suite_full.png"),
        conecta_track: require("@/assets/appslogos/full/conecta_track_full.png"),
        conecta_sign: require("@/assets/appslogos/full/conecta_suite_full.png"),
        conecta_control: require("@/assets/appslogos/full/conecta_control_full.png"),
        conecta_transfer: require("@/assets/appslogos/full/conecta_transfer_full.png"),
        conecta_support: require("@/assets/appslogos/full/conecta_support_full.png"),
        reseller: require("@/assets/appslogos/full/conecta_suite_full.png"),
        manage_admins: require("@/assets/appslogos/full/conecta_suite_full.png"),
        plans: "",
      },
      logos_full_white: {
        conecta_suite: require("@/assets/appslogos/full/ConectaSuiteBrancoFull.png"),
        conecta_track: require("@/assets/appslogos/full/ConectaTrackBrancoFull.png"),
        conecta_sign: require("@/assets/appslogos/full/ConectaSuiteBrancoFull.png"),
        conecta_control: require("@/assets/appslogos/full/ConectaControlBrancoFull.png"),
        conecta_transfer: require("@/assets/appslogos/full/ConectaTransferBrancoFull.png"),
        conecta_support: require("@/assets/appslogos/full/ConectaSupportBrancoFull.png"),
        reseller: require("@/assets/appslogos/full/ConectaSuiteBrancoFull.png"),
        manage_admins: require("@/assets/appslogos/full/ConectaSuiteBrancoFull.png"),
        plans: "",
      },
    };
  },
  computed: {
    hasSubProductsOrServices() {
      return [
        "conecta_control_mobile",
        "conecta_ad_sync",
        "integracao_ponto_mais",
        "sign_automation",
      ].includes(this.product);
    },
    isSignAutomation() {
      return this.product === "sign_automation";
    },
    isConectaControlMobile() {
      return this.product === "conecta_control_mobile";
    },
    isConectaControlAD() {
      return this.product === "conecta_ad_sync";
    },
    isPontoMais() {
      return this.product === "integracao_ponto_mais";
    },
    margin() {
      if (!this.noMargins) {
        let height = 0;
        if (this.height) {
          if (typeof this.height === "string") {
            height = this.height;
            height.replace("px", "");
          }
          height = Math.trunc(parseInt(height) / 12);
          return height;
        }
        let logo = new Image();
        logo.src = this.src;
        height = Math.trunc(logo.height / 12);
        return height <= 6 ? height : 16;
      } else {
        return 0;
      }
    },

    badgeXDistance() {
      if (this.width && this.width > 0) {
        return this.width / 3;
      } else if (this.height && this.height > 0) {
        return this.height / 3;
      }
      return this.noMargins ? 16 : 20;
    },

    badgeYDistance() {
      if (this.width && this.width > 0) {
        return this.width / 4;
      } else if (this.height && this.height > 0) {
        return this.height / 4;
      }
      return this.noMargins ? 11 : 13;
    },

    src() {
      if (this.full) {
        if (this.white || this.black) {
          return Object.keys(this.logos_full_white).includes(this.product)
            ? this.logos_full_white[this.product]
            : this.logos_icons_white["conecta_suite"];
        }
        return Object.keys(this.logos_full).includes(this.product)
          ? this.logos_full[this.product]
          : this.logos_full["conecta_suite"];
      } else if ((this.white || this.black) && !this.full) {
        return Object.keys(this.logos_icons_white).includes(this.product)
          ? this.logos_icons_white[this.product]
          : this.logos_icons_white["conecta_suite"];
      } else if (this.products && this.hasSubProductsOrServices) {
        if (
          [
            "conecta_control_mobile",
            "conecta_ad_sync",
            "integracao_ponto_mais",
          ].includes(this.product)
        ) {
          return this.logos_icons.conecta_control;
        } else {
          return this.logos_icons.conecta_sign;
        }
      }
      return Object.keys(this.logos_icons).includes(this.product)
        ? this.logos_icons[this.product]
        : this.logos_icons["conecta_suite"];
    },

    isIcon() {
      return this.icons[this.product];
    },
  },
};
</script>
<style scoped>
.grey {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; /* IE */
}
.conecta-ad {
  font-size: 9px !important;
  margin-top: 7px !important;
}
.invert {
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}
</style>
