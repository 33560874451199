<template>
  <v-chip
    v-if="chip"
    :class="`${styles} ${$vuetify.theme.dark ? 'white' : 'black'}--text`"
    :small="small"
    :large="large"
    :to="userProfileLink"
  >
    <v-avatar v-if="!hideImage" left :size="size">
      <img :src="userImage" :alt="user.name" />
    </v-avatar>
    {{ userName }}
  </v-chip>
  <v-list-item
    v-else
    :to="redirectToUser ? userProfileLink : null"
    :class="`user-badge transparent px-0 ${styles} ${
      link ? 'link blue--text text--darken-2' : ''
    } ${small ? 'pa-1' : ''} px-0 ma-0`"
    :style="`max-width: ${maxWidth}`"
  >
    <v-list-item-action v-if="!hideImage && !inverse" class="pr-0 mr-3 ml-0">
      <v-badge
        v-if="
          hasConectaSign &&
          !simpleView &&
          !hideBadges &&
          (!isUserGmailDisabled || canNotEditUserSignature)
        "
        color="secondary lighten-1"
        overlap
        bottom
      >
        <template v-slot:badge>
          <TooltipIcon
            v-if="!isUserGmailDisabled"
            color="white"
            :label="$t('common.emailServiceIsDisabled')"
            max-width="400"
            icon="mdi-email-off"
            size="13"
          />
          <TooltipIcon
            v-else-if="canNotEditUserSignature"
            color="white"
            :label="$t('common.userRestrictDescription')"
            max-width="400"
            icon="mdi-lock"
            size="13"
          />
        </template>

        <v-avatar left :size="size">
          <img :src="userImage" :alt="user.name" />
        </v-avatar>
      </v-badge>

      <v-avatar v-else left :size="size">
        <img :src="userImage" :alt="user.name" />
      </v-avatar>
    </v-list-item-action>
    <v-list-item-content class="px-0">
      <v-list-item-title
        v-if="userName || simpleView"
        :class="`text-subtitle-2 ${link ? 'blue--text text--darken-2' : ''} ${
          small ? 'caption' : ''
        } break-word`"
      >
        <span v-if="!simpleView" :class="inverse ? '' : 'mr-2'">
          {{ userName }}
        </span>

        <span v-else-if="!showUserEmail" :class="inverse ? '' : 'mr-2'">
          {{ userName }} ({{ userEmail }})
        </span>

        <TooltipIcon
          v-if="showAppliedVacation && !simpleView"
          :color="getVacationIconColor"
          size="24"
          icon="mdi-umbrella-beach"
          :label="`<b>Mensagem de férias</b> definida de ${formatDate(
            user.vacation_init_date
          )} à ${formatDate(user.vacation_end_date)}`"
        />
      </v-list-item-title>
      <v-list-item-subtitle
        v-if="user.email && showUserEmail"
        class="text-wrap break-word mt-1 caption"
      >
        {{ user.email }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="showUserPosition">
        {{ userPosition }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="!hideImage && inverse" class="pr-0 ml-3 mr-0">
      <v-badge
        v-if="
          canNotEditUserSignature &&
          hasConectaSign &&
          !simpleView &&
          !hideBadges
        "
        color="secondary lighten-1"
        overlap
        bottom
      >
        <template v-slot:badge>
          <TooltipIcon
            color="white"
            :label="$t('common.userRestrictDescription')"
            max-width="400"
            icon="mdi-lock"
            size="13"
          />
        </template>

        <v-avatar left :size="size">
          <img :src="userImage" :alt="user.name" />
        </v-avatar>
      </v-badge>

      <v-avatar v-else left :size="size">
        <img :src="userImage" :alt="user.name" />
      </v-avatar>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import {
  formatDate,
  checkExpiredDate,
  checkIfItsNotToday,
} from "@/helpers/services/utils";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    color: { type: String, default: "transparent" },
    user: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    small: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    size: { type: [String, Number], default: 40 },
    link: { type: Boolean, default: false },
    chip: { type: Boolean, default: false },
    styles: { type: String, default: "" },
    redirectToUser: { type: Boolean, default: false },
    simpleView: { type: Boolean, default: false },
    hideImage: { type: Boolean, default: false },
    showUserEmail: { type: Boolean, default: false },
    maxWidth: { type: String, default: "100%" },
    secondaryEmail: { type: Boolean, default: false },
    inverse: { type: Boolean, default: false },
    hideBadges: { type: Boolean, default: false },
    showUserPosition: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["hasConectaSign", "primaryColor"]),

    canNotEditUserSignature() {
      if (this.secondaryEmail) {
        return (
          this.user.email === this.user.user.email && !this.user.is_editable
        );
      }
      return !this.user.is_editable;
    },

    userImage() {
      if (!this.hideImage) {
        return (
          this.user?.photo ||
          this.user.user?.photo ||
          require("@/assets/user-default.png")
        );
      }
      return "";
    },

    userName() {
      if (this.user.user) {
        return this.user.user.name;
      }
      return this.user.name || "Usuário";
    },

    userPosition() {
      if (this.user.user) {
        return this.user.user.position;
      }
      return this.user.position || "Colaborador";
    },

    isUserGmailDisabled() {
      if (this.user.user) {
        return !this.user.user.is_gmail_enabled;
      }
      return ![null, false].includes(this.user.is_gmail_enabled);
    },

    userEmail() {
      if (this.user.user) {
        return this.user.user.email;
      }
      return this.user.email || "email não encontrado";
    },

    vacationIconColor() {
      if (this.$route.meta.app == "conecta_suite") {
        return "accent";
      } else {
        return this.primaryColor;
      }
    },

    userProfileLink() {
      return `/user?id=${
        this.user.user ? this.user.user.id_google : this.user.id_google
      }`;
    },

    showAppliedVacation() {
      if (this.user.applied_vacation_key) {
        if (this.vacationEnded) {
          return false;
        } else if (this.vacationInTheFuture) {
          return true;
        }
        return true;
      }
      return false;
    },

    vacationEnded() {
      return (
        this.user.applied_vacation_key &&
        checkExpiredDate(this.user.vacation_end_date)
      );
    },

    vacationInTheFuture() {
      return (
        this.user.applied_vacation_key &&
        checkIfItsNotToday(this.user.vacation_init_date) &&
        !checkExpiredDate(this.user.vacation_end_date)
      );
    },

    getVacationIconColor() {
      if (this.user.applied_vacation_key) {
        if (this.vacationInTheFuture) {
          return "grey";
        }
        return this.vacationIconColor;
      }
      return "";
    },
  },
  methods: {
    formatDate,

    ...mapActions(["goToUserPage"]),
  },
};
</script>
<style>
.user-badge .v-list-item:hover {
  background: "transparent";
}
</style>
