<template>
  <v-navigation-drawer
    v-model="showNotifications"
    :class="`notifications ${$vuetify.theme.dark ? '' : 'grey lighten-3'}`"
    width="450"
    app
    temporary
    right
  >
    <v-app-bar flat fixed dark class="mx-0" :color="color">
      <v-toolbar-title class="px-4">Notificações</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="showCloseNotificationsSidebar"
        ><v-icon v-text="'mdi-close'"
      /></v-btn>
    </v-app-bar>

    <v-card
      :class="`pt-12 pb-4 px-4 overflow-y-auto fill-height transparent`"
      flat
      tile
      v-if="hasNotifications || !filterByUnread"
    >
      <v-subheader
        v-if="filterByUnread"
        class="mt-5 mb-3 text-uppercase font-weight-medium"
        >Não lidas ({{ unreadNotifiactions.length }})</v-subheader
      >

      <v-card
        v-for="(notification, i) in notifiactions"
        :key="i"
        :class="`mx-2 mb-4  pb-2 ${!filterByUnread && i === 0 ? 'mt-9' : ''}`"
      >
        <v-card-text
          v-if="notification.read_at"
          class="caption text-uppercase py-1 green lighten-4 text-center d-flex align-center flex-wrap justify-center"
        >
          <v-icon
            v-text="'mdi-check-circle'"
            color="success"
            class="mr-2"
            small
          />
          MENSAGEM LIDA
        </v-card-text>

        <v-btn
          v-if="!notification.read_at"
          x-small
          color="secondary lighten-2"
          class="text-none caption font-weight-medium ml-3 px-0 mt-2"
          dark
          text
          @click="handleNotificationView(notification, 'mark_as_read')"
        >
          {{
            notification.read_at ? "Marcar não como lida" : "Marcar como lida"
          }}
        </v-btn>

        <v-card-title class="font-weight-medium text-body-1 pt-2 my-0 py-0">
          {{ notification.title }}
        </v-card-title>

        <v-card-subtitle
          v-if="notification.photo"
          class="text-subtitle-2 font-weight-regular pb-4 pt-0 my-2"
          ><img
            width="364px"
            :src="notification.photo"
            referrerpolicy="no-referrer"
        /></v-card-subtitle>
        <v-card-subtitle
          v-if="notification.message"
          class="text-subtitle-2 font-weight-regular pb-4 pt-0 my-2"
          >{{ notification.message }}</v-card-subtitle
        >
        <v-card-actions
          class="my-0 py-0 d-flex justify-end"
          v-if="notification.content_link"
        >
          <v-btn
            :color="color"
            text
            :href="notification.content_link"
            target="_blank"
            @click="handleNotificationView(notification, 'clicked_learn_more')"
          >
            Saiba mais
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-btn
        v-if="filterByUnread"
        color="secondary lighten-1"
        text
        class="text-none text-body-1 font-weight-medium"
        block
        @click="filterByUnread = false"
      >
        <v-icon v-text="'mdi-history'" size="24" left />
        Ver notificações antigas
      </v-btn>
    </v-card>
    <v-card
      v-else
      class="pa-4 overflow-y-auto transparent mt-12 green--text"
      flat
    >
      <v-card-text class="py-4">
        Suas notificações serão exibidas aqui.
      </v-card-text>
    </v-card>
    <v-card
      v-if="!hasNotifications && filterByUnread"
      class="pa-4 overflow-y-auto transparent mt-12 green--text"
      flat
    >
      <v-btn
        color="secondary lighten-1"
        text
        class="text-none text-body-1 font-weight-medium"
        block
        @click="filterByUnread = false"
      >
        <v-icon v-text="'mdi-history'" size="24" left />
        Ver notificações antigas
      </v-btn>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      filterByUnread: true,
    };
  },
  props: {
    color: { type: String, required: true },
  },
  async beforeMount() {
    await this.getNotifications();
  },

  computed: {
    ...mapGetters([
      "token",
      "currentUser",
      "showNotificationsSidebar",
      "notificationsBell",
    ]),
    hasNotifications() {
      return this.unreadNotifiactions.length > 0;
    },

    unreadNotifiactions() {
      return this.notificationsBell.filter(
        (notification) => !notification.read_at
      );
    },

    notifiactions() {
      if (this.filterByUnread) {
        return this.unreadNotifiactions;
      }
      return this.notificationsBell;
    },

    showNotifications: {
      get() {
        return this.showNotificationsSidebar;
      },
      set(value) {
        if (value !== this.showNotificationsSidebar) {
          this.showCloseNotificationsSidebar();
        }
      },
    },
  },

  watch: {
    showNotifications() {
      if (this.showNotifications) {
        this.filterByUnread = true;
      }
    },
  },

  methods: {
    ...mapMutations(["showCloseNotificationsSidebar", "setNotificationsBell"]),
    ...mapActions(["getNotifications"]),
    async readNotification(notificationKeys, click_action) {
      let url = process.env.VUE_APP_API_BASE_URL + "/notifications/read";
      let config = {
        headers: {
          Authorization: this.token,
          "Content-Type": "application/json",
        },
      };
      let params = {
        notification_keys: notificationKeys,
        click_action: click_action,
      };

      this.$axios
        .post(url, params, config)
        .then(() => {
          this.getNotifications();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleNotificationView(notification, click_action) {
      this.readNotification([notification.key], click_action);
    },
  },
};
</script>
<style scoped>
#sign {
  background-color: #ffbc00;
}
#suite {
  background-color: #311f50;
}
#track {
  background-color: #ff4131;
}
#control {
  background-color: #4d7cbf;
}
</style>
