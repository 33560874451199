const state = () => ({
  onboardingStep: 1,
});

const getters = {
  onboardingStepsNumber: () => 7,
  currentOnboardingStep: (state) => state.onboardingStep,
};

// actions
const actions = {};

// mutations
const mutations = {
  setOnboardingStep(state, step) {
    if (step) {
      state.onboardingStep = step;
      localStorage.setItem("onboardingStep", JSON.stringify(step));
    } else {
      state.onboardingStep = 1;
      localStorage.removeItem("onboardingStep");
    }
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
