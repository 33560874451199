<template>
  <v-app>
    <v-main :class="$vuetify.theme.dark ? '' : getBackgroundColor">
      <router-view v-if="company" />
    </v-main>
  </v-app>
</template>

<script>
import Default from "@/layouts/Default";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "External",

  extends: Default,

  computed: {
    ...mapGetters(["company"]),
  },

  methods: {
    ...mapActions([
      "getPlans",
      "getContractedPlans",
      "createUser",
      "getCompany",
    ]),

    ...mapMutations(["setLoading", "setToken"]),

    async handleExternalEnter() {
      const token = this.$route.query.token;
      this.setToken(token);
      await this.createUser();
      await this.getCompany();
    },

    handleExternalLeave() {
      this.setToken("");
      window.location.href = process.env.VUE_APP_CONECTA_NEW_CONTROL_BASE_URL;
    },
  },

  async created() {
    this.setLoading(true);
    await this.handleExternalEnter();
  },
  beforeDestroy() {
    this.handleExternalLeave();
  },
};
</script>
