import { emails } from "@/helpers/variables/contactConecta";

export const warnings = {
  help_min_price_client:
    "Para efetivar a compra com o plano anual, adicione licenças até atingir o valor mínimo de R$40,00 reais. ",
  continues_with_annual: "Para continuar, é necessário escolher o Plano Anual.",
  requires_min_price:
    "O valor mínimo de compra não foi atingido para uma ou mais opções de contratação.",
  help_min_price_reseller:
    "Para efetivar a compra, adicione mais licenças no Plano Anual ou continue com o Plano Trial.",
  can_not_edit_current_plan:
    "Este plano já esta ativo, escolha outro plano para realizar a contratação. Caso precise de alguma alteração no plano ativo, entre em contato com o nosso equipe comercial ",
  reversible_action: "A ação a seguir pode ser desfeita a qualquer momento.",
  irreversible_action: "A ação a seguir não poderá ser desfeita.",
  flex_company_plan_active:
    "Plano mensal ativo! Você pode contratar um novo plano anual que substituirá o plano mensal vigente.",
  annual_company_plan_active: `Plano anual ativo! Caso queira mudar o plano para mensal, fale com nossa equipe comercial através do e-mail: ${emails.experience}`,
};
