import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";

const state = () => ({});

const getters = {
  sendAsEmails: (state, getters, rootState, rootGetters) => {
    return rootGetters.users.reduce((accum, user) => {
      const { photo, name, email, id_google, applied_signature_key } = user;
      for (let i = 0; i < user.send_as.length; i++) {
        const sendAs = user.send_as[i];
        accum.push({
          id: `${name.replaceAll(" ", "_").toLowerCase()}-${sendAs.email}`,
          email: sendAs.email,
          name: sendAs.displayName,
          user: {
            photo: photo,
            name: name,
            email: email,
            id_google: id_google,
          },
          applied_signature_key:
            sendAs.email == email
              ? applied_signature_key
              : sendAs.applied_signature_key,
          is_primary_email: sendAs.email == email,
          is_editable: user.is_editable,
        });
      }
      return accum;
    }, []);
  },
  allEmailsInCompany(state, getters, rootState, rootGetters) {
    const createUserID = (name, email) => {
      const lowercaseName = name.toLowerCase().replace(/\s/g, "_");
      return `${lowercaseName}-${email}`;
    };

    const allEmails = rootGetters.users.reduce((acc, user) => {
      const userData = {
        applied_signature_key: user.applied_signature_key,
        email: user.email,
        id: createUserID(user.name, user.email),
        id_google: user.id_google,
        is_editable: user.is_editable,
        is_gmail_enabled: user.is_gmail_enabled,
        is_primary_email: true,
        name: user.name,
        user: {
          email: user.email,
          id_google: user.id_google,
          name: user.name,
          photo: user.photo,
        },
      };

      acc.push(userData);

      if (user.send_as && user.send_as.length > 0) {
        const sendAsData = user.send_as.reduce((acc, send_as) => {
          if (!send_as.isPrimary) {
            const userSendAsData = {
              applied_signature_key: send_as.applied_signature_key,
              email: send_as.email,
              id: createUserID(user.name, send_as.email),
              id_google: user.id_google,
              is_editable: user.is_editable,
              is_gmail_enabled: user.is_gmail_enabled,
              is_primary_email: send_as.isPrimary,
              name: send_as.displayName,
              user: {
                email: user.email,
                id_google: user.id_google,
                name: user.name,
                photo: user.photo,
              },
            };
            acc.push(userSendAsData);
          }
          return acc;
        }, []);
        acc = acc.concat(sendAsData);
      }

      return acc;
    }, []);

    return allEmails;
  },
};

// actions
const actions = {
  async applySendAs({ commit, dispatch }, info) {
    commit("setSignLoading", true, { root: true });

    this.sendAsLoading = true;

    const { users, sendAsName, sendAsEmail } = info;

    const hasManyUsers = users.length > 50;

    const url = `${process.env.VUE_APP_API_BASE_URL}/users/create-sendas/batch${
      hasManyUsers ? ":async" : ""
    }`;

    const emails = users.map(({ email }) => email);

    const payload = {
      name: sendAsName,
      emails: emails,
      send_as_email: sendAsEmail.trim(),
    };

    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    await this._vm.$axios
      .post(url, payload, auth)
      .then(() => {
        commit(
          "setSnackBar",
          {
            message: hasManyUsers
              ? successMessages.update_users_in_batch
              : successMessages.update_users,
            show: true,
          },
          { root: true }
        );
        dispatch("completeMission", "create_secondary_email");
      })
      .catch(({ response: { data } }) => {
        commit(
          "setSnackBar",
          {
            message:
              errorMessages[data.code] || errorMessages.unknown_sendas_error,
            color: "error",
            show: true,
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("setSignLoading", false, { root: true });
      });
  },

  getSendAsByTablePage(context, params) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/sendas/fetch`;

    let config = {
      headers: {
        Authorization: this._vm.token,
      },
      params: params,
    };

    return this._vm.$axios.get(url, config);
  },

  syncSendAs() {
    const url = process.env.VUE_APP_API_BASE_URL + "/users/sendas";

    let auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    return this._vm.$axios
      .get(url, auth)
      .then(() => {})
      .catch((err) => {
        console.error("Vuex: setSendAsEmails(): ", err);
      });
  },
};

// mutations
const mutations = {};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
