import { getDatabase, set, ref } from "firebase/database";
import { validateModifiedAt } from "@/helpers/services/utils";

const gapi = () => window.gapi;
const gapiClient = () => window.gapi?.client;
const setGAPIToken = (token) => gapiClient().setToken({ access_token: token });

const state = () => ({
  loadingClient: false,
  companyToken: { token: "", time: "" },
  loadingCompanyToken: false,
  admin: null,
});

const getters = {
  loadingClient: (state) => state.loadingClient,

  companyToken: (state) => state.companyToken.token,
  companyTokenTime: (state) => state.companyToken.time,

  loadingCompanyToken: (state) => state.loadingCompanyToken,
};

const actions = {
  async initializeGapiClient({ commit, dispatch }, { scopes, discoveryDocs }) {
    // const apiKey = "AIzaSyCEmFFQogXZhTCkpxH60Md8qAIzUmW0q8Q";
    await gapiClient().init({
      // apiKey,
      discoveryDocs,
    });
    dispatch("getCompanyGAPIToken", scopes);
    commit("setLoadingClient", false);
  },
  async loadGAPIScript({ commit, dispatch }, { scopes, discoveryDocs }) {
    commit("setLoadingClient", true);
    if (!gapi()) {
      await import(
        /* webpackIgnore: true */ "https://apis.google.com/js/api.js"
      );
    }
    gapi().load(
      "client",
      async () =>
        await dispatch("initializeGapiClient", { scopes, discoveryDocs })
    );
  },

  async getCompanyGAPIToken({ getters, commit }, scopes) {
    if (getters.companyToken) {
      const hourInMilliseconds = 1000 * 60 * 60;
      if (Date.now() - getters.companyTokenTime < hourInMilliseconds) {
        setGAPIToken(getters.companyToken);
        return;
      }
    }

    commit("setLoadingCompanyToken", true);

    const url = `${process.env.VUE_APP_API_BASE_URL}/company/token`;
    const payload = { scopes };
    const auth = {
      headers: {
        Authorization: getters.token,
      },
    };

    try {
      const response = await this._vm.$axios.post(url, payload, auth);
      commit("setCompanyToken", response.data.token);
      commit("setAdmin", response.data.admin);
      setGAPIToken(getters.companyToken);
    } catch (err) {
      console.error(err);
    }

    commit("setLoadingCompanyToken", false);
  },

  checkOfflineWorkersToRun({ getters }, { workers, processPath }) {
    const now = new Date().getTime();
    const db = getDatabase();
    const reference = ref(db, `${processPath}/workers`);
    set(
      reference,
      Object.fromEntries(
        Object.values(workers || {})
          .filter((worker) => worker.status !== "offline")
          .map((worker) => [
            worker.id_google,
            {
              ...worker,
              status: validateModifiedAt(now, worker.modifiedAt)
                ? "offline"
                : worker.status,
              modifiedAt: new Date().getTime(),
              modifiedBy: getters.currentUser.email,
            },
          ])
      )
    );
  },
};

const mutations = {
  setLoadingClient(state, value) {
    state.loadingClient = value;
  },

  setCompanyToken(state, token) {
    state.companyToken = { token, time: Date.now() };
  },

  setAdmin(state, value) {
    state.admin = value;
  },

  setLoadingCompanyToken(state, value) {
    state.loadingCompanyToken = value;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
