<template>
  <v-dialog
    scrollable
    v-model="showDialog"
    width="877px"
    @keydown.esc="$emit(`close`)"
    @click:outside="$emit(`close`)"
    content-class="round"
  >
    <v-card min-height="512px" flat tile>
      <div class="d-flex font-poppins">
        <div class="info-box">
          <div style="width: 350px">
            <v-card-subtitle
              style="color: #3498db; font-size: 16px"
              class="font-weight-medium text-uppercase"
            >
              {{ $t("guide.notification.updates") }}
            </v-card-subtitle>
            <v-card-title style="font-size: 28px" class="pt-0">{{
              $t("guide.notification.title")
            }}</v-card-title>
            <v-card-subtitle style="color: #545454" class="py-5">{{
              $t("guide.notification.description")
            }}</v-card-subtitle>
            <v-card-actions class="px-4">
              <v-btn
                class="py-5 px-2 text-none white--text elevation-0"
                color="#3498db"
                style="border-radius: 5px"
                @click="$emit('more')"
              >
                {{ $t("action.knowMore") }}
              </v-btn>
            </v-card-actions>
          </div>
        </div>
        <div class="image-box">
          <img :src="homeScreenshot" height="355px" />
        </div>
      </div>
    </v-card>
    <v-btn
      class="close-button"
      color="#545454"
      icon
      @click="$emit('close')"
      tile
      x-small
    >
      <v-icon>ph-x</v-icon>
    </v-btn>
  </v-dialog>
</template>
<script>
export default {
  name: "UpdateNotificationDialog",
  props: {
    show: { type: Boolean, required: true },
  },
  data: () => ({
    homeScreenshot: require("@/assets/images/home_screenshot.png"),
  }),

  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>
<style>
.round {
  border-radius: 12px !important;
  overflow: hidden;
}

.info-box {
  width: 500px;
  height: 512px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;
}

.image-box {
  width: 500px;
  height: 512px;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.close-button {
  position: absolute;
  top: 13px;
  z-index: 10;
  color: #333;
  right: 35px;
  border-radius: 100%;
}

.close-button:hover {
  color: #555;
}
</style>
