import { newUsersTableHeigth } from "@/helpers/variables/tablesDefault";
const state = () => ({
  newUsersTableHeigth: newUsersTableHeigth,
  loading: false,
  displayOnlyPrimaryEmails: true,
  showEditorSidebar: true,
  allItensSelected: false,
  allEntitiesFiltered: [],
  allEntitiesFilteredToApply: [],
});

const getters = {
  getNewUsersTableHeigth: (state) => state.newUsersTableHeigth,
  userPreviewImage: () => require("@/assets/ImagemUsuario.png"),
  companyPreviewImage: () => require("@/assets/LogotipoEmpresa.png"),
  companyBannerPreviewImage: () => require("@/assets/banner.png"),
  loadingSign: (state) => state.loading,
  displayOnlyPrimaryEmails: (state) => state.displayOnlyPrimaryEmails,
  showEditorSidebar: (state) => state.showEditorSidebar,
  allItensSelected: (state) => state.allItensSelected,
  allEntitiesFiltered: (state) => state.allEntitiesFiltered,
  allEntitiesFilteredToApply: (state) => state.allEntitiesFilteredToApply,
};

// actions
const actions = {};

// mutations
const mutations = {
  setNewUsersTableHeigth(state, value) {
    state.newUsersTableHeigth = value;
  },
  setSignLoading(state, status) {
    state.loading = status;
  },

  setDisplayPrimaryEmails(state, status = null) {
    state.displayOnlyPrimaryEmails = status;
  },

  setSignEditorSidebar(state, value = null) {
    if (typeof value !== "boolean") {
      state.showEditorSidebar = !state.showEditorSidebar || false;
    } else {
      state.showEditorSidebar = value;
    }
  },

  setAllItensSelected(state, value) {
    state.allItensSelected = value;
  },

  setAllEntitiesFiltered(state, value) {
    state.allEntitiesFiltered = value;
  },

  setAllEntitiesFilteredToApply(state, value) {
    state.allEntitiesFilteredToApply = value;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
