<template>
  <v-navigation-drawer
    v-model="showOnlineUsers"
    :class="`notifications ${$vuetify.theme.dark ? '' : 'grey lighten-3'}`"
    width="500"
    app
    right
    temporary
  >
    <v-toolbar flat fixed dark class="mx-0" :color="primaryColor">
      <v-toolbar-title class="px-4">Usuários Online</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.stop="showOnlineUsers = !showOnlineUsers"
        ><v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- LOGICA DO ONLINE USERS -->
    <v-row align="center" class="ma-0">
      <v-col>
        <v-list>
          <v-list-item v-for="(data, index) in onlineUsers" :key="index">
            <v-badge
              class="mr-3"
              color="green"
              bottom
              overlap
              bordered
              offset-x="30"
              offset-y="20"
            >
              <v-list-item-avatar>
                <v-avatar
                  color="primary"
                  size="48"
                  style="display: inline-block"
                >
                  <img
                    :src="data.user.photo"
                    :alt="data.user.name"
                    referrerpolicy="strict-origin-when-cross-origin"
                    @error="
                      $event.target.src = defaultUserPhoto;
                      $event.onerror = null;
                    "
                  />
                </v-avatar>
              </v-list-item-avatar>
            </v-badge>
            <v-list-item-content>
              <v-list-item-title
                class="text-wrap break-word"
                v-text="data.user.name"
              />
              <v-list-item-subtitle
                class="text-wrap break-word"
                v-for="(text, key) in {
                  Domínio: data.user.domain,
                  Departamento: data.user.departament,
                  Cargo: data.user.position,
                }"
                v-text="text"
                :key="key + text"
                v-show="text"
              />
              <v-list-item-subtitle v-if="data.isTrial">
                <v-chip outlined color="accent" x-small>TRIAL</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="accent"
                    icon
                    x-large
                    v-on="on"
                    v-bind="attrs"
                    :class="`text-capitalize`"
                    @click.stop="openClient(data)"
                  >
                    <v-icon> mdi-account</v-icon>
                  </v-btn>
                </template>
                <span>Visitar Perfil</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="accent"
                    icon
                    x-large
                    v-on="on"
                    v-bind="attrs"
                    :disabled="getInviteResponse === 'pending'"
                    :class="`text-capitalize`"
                    @click.stop="setInviteMeetingSettings(true, data.user)"
                  >
                    <v-icon> mdi-phone </v-icon>
                  </v-btn>
                </template>
                <span>Chamar cliente</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import defaultUserPhoto from "@/assets/user-default.png";
import { openInNewTab } from "@/helpers/services/utils";

export default {
  name: "OnlineUsersSidebar",

  data() {
    return {
      defaultUserPhoto,
    };
  },

  computed: {
    ...mapGetters([
      "showOnlineUsersSidebar",
      "primaryColor",
      "onlineUsers",
      "getInviteResponse",
    ]),

    showOnlineUsers: {
      get() {
        return this.showOnlineUsersSidebar;
      },
      set(value) {
        if (value !== this.showOnlineUsersSidebar) {
          this.showCloseOnlineUsersSidebar();
        }
      },
    },
  },

  methods: {
    ...mapMutations([
      "showCloseOnlineUsersSidebar",
      "setGenerateMeetDialog",
      "setUserToCall",
    ]),

    setInviteMeetingSettings(show, user) {
      this.setGenerateMeetDialog(show);
      this.setUserToCall(user);
    },

    openClient(data) {
      const key = data.company.key;
      const domain = data.company.main_domain;
      const url = `${location.origin}/partner/customer?domain=${domain}&key=${key}`;
      openInNewTab(url);
    },
  },
};
</script>

<style></style>
