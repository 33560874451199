<template>
  <v-dialog
    v-model="show"
    max-width="350px"
    @keydown.esc="$emit('cancelLogout')"
    @click:outside="$emit('cancelLogout')"
  >
    <v-card>
      <v-card-title primary-title>Conecta Suite</v-card-title>
      <v-card-text class="pt-2">Você tem certeza que deseja sair?</v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('cancelLogout')" color="gray">Cancelar</v-btn>
        <v-btn @click="logout()" color="primary" text>Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    show: { type: Boolean, default: false },
  },
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>
