<template>
  <v-dialog
    v-model="showDialog"
    width="880"
    @keydown.esc="showDialog = false"
    @click:outside="showDialog = false"
  >
    <v-progress-linear v-if="loadingProduct" indeterminate color="primary" />
    <v-card :disabled="loadingProduct" class="d-flex" tile min-height="400">
      <v-img
        v-if="!$vuetify.breakpoint.xs"
        :src="src"
        max-height="800"
        max-width="430"
      />
      <v-card
        tile
        flat
        class="px-4 d-flex flex-column align-center"
        width="100%"
      >
        <v-card-text class="d-flex flex-column justify-center pt-8 pb-5" tile>
          <Logo
            noMargins
            full
            class="mx-auto"
            width="300"
            product="conecta_suite"
          />
        </v-card-text>

        <v-card-text class="text-center pt-0">
          <p
            class="text-body-2 mt-0 mb-2"
            v-if="modulesDetails[selectedProduct].description"
          >
            {{ $t(modulesDetails[selectedProduct].description) }}
          </p>
          <div class="mt-6 text-body-1">
            <div v-if="productDoesNotExist">
              <Alert
                v-if="!!productPlanDetails[selectedProduct]"
                color="warning"
                dense
              >
                {{
                  $t("dialogHasProduct.yourCompanyPlan", {
                    plan: productPlanDetails[selectedProduct].title,
                  })
                }}
              </Alert>
              <Alert v-else color="warning" dense>
                {{ $t("dialogHasProduct.yourCompany", { product }) }}
              </Alert>
            </div>
            <h3
              v-else-if="productIsTrialExpired"
              :class="`error--text font-weight-bold`"
            >
              {{ $t("dialogHasProduct.trialExpired") }}
            </h3>
            <div
              v-else-if="userIsNotAdmin"
              data-testid="has-no-permission-access"
              class="text-body-2"
            >
              <Alert
                dense
                color="warning"
                class="font-weight-bold text-body-1 mr-0"
              >
                {{ $t("dialogHasProduct.notAdminOf") }}
                {{ $t(modulesDetails[selectedProduct].title) }}.
              </Alert>
              {{ $t("dialogHasProduct.askAdmin") }}
            </div>
            <p v-else>
              <strong>{{ $t("common.somethingWentWrong") }}</strong>
            </p>
          </div>
        </v-card-text>

        <v-card-actions
          class="d-flex flex-column justify-center pt-auto pb-10"
          v-if="!isUnauthorized && !hasIntermediaryPartner"
        >
          <v-btn
            v-if="
              (productIsTrialExpired && hasBillingPermission) ||
              (productDoesNotExist && hasBillingPermission)
            "
            depressed
            large
            color="primary"
            :class="`white--text text-none font-weight-bold text-body-1 px-8`"
            @click="goToPlans()"
          >
            {{ $t("common.checkPlans") }}
          </v-btn>
          <v-btn
            v-if="doNotHaveThisProduct || !isUnauthorized"
            text
            :href="productDetailLink"
            target="_blank"
            color="secondary lighten-2"
            :class="`text-none font-weight-bold rounded-lg text-body-1 pa-0 mt-2`"
          >
            {{ $t("action.knowMore") }}
          </v-btn>
        </v-card-actions>
        <v-card-actions
          class="pa-0 ma-0 grey lighten-5 align-self-start"
          style="width: 100%"
        >
          <v-treeview
            v-if="(isConecta || isDevConecta) && productStatus"
            :items="errorDetails"
            class="text-body-2 secondary--text text--lighten-2 font-weight-medium pa-0 break-word"
          />
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { logBtnEvent } from "@/helpers/services/registerAnalytics";
import Logo from "@/components/base/Logo";
import { responseStatus } from "@/helpers/variables/backendConstants";
import { openInNewTab } from "@/helpers/services/utils";
import {
  modulesDetails,
  productPlanDetails,
} from "@/helpers/variables/modules";
import { mapActions, mapGetters } from "vuex";
import { isSuiteProduct } from "@/helpers/services/products";

export default {
  name: "DialogHasProductAccess",

  props: {
    show: { type: Boolean, required: true },
    selectedProduct: { type: String, required: true },
    status: { type: String, required: true },
  },

  components: { Logo },

  data() {
    return {
      loadingProduct: false,
      modulesDetails,
      productPlanDetails,
      responseStatus,
      imgProducts: {
        conecta_control: require("@/assets/images/imagem_conecta_control.jpg"),
        conecta_sign: require("@/assets/images/imagem_conecta_suite.jpg"),
        conecta_suite: require("@/assets/images/imagem_conecta_suite.jpg"),
        conecta_track: require("@/assets/images/imagem_conecta_track.jpg"),
        company_profile: require("@/assets/images/imagem_conecta_suite.jpg"),
        reseller: require("@/assets/images/imagem_conecta_suite.jpg"),
        admin: require("@/assets/images/imagem_conecta_suite.jpg"),
        billing: require("@/assets/images/imagem_conecta_suite.jpg"),
        reports: require("@/assets/images/imagem_conecta_suite.jpg"),
      },
    };
  },

  computed: {
    ...mapGetters([
      "hasIntermediaryPartner",
      "currentUser",
      "isConecta",
      "isDevConecta",
      "mainDomain",
      "hasBillingPermission",
    ]),

    errorDetails() {
      return [
        {
          name: "Detalhes do erro",
          children: [
            {
              name: `Status: 403`,
            },
            {
              name: `Código: ${this.productStatus}`,
            },
          ],
        },
      ];
    },

    doNotHaveThisProduct() {
      return [
        responseStatus.PRODUCT_IN_DISABLED_PLAN,
        responseStatus.PRODUCT_DOES_NOT_EXIST_IN_PLANS,
        responseStatus.TRIAL_ENDED,
      ].includes(this.productStatus);
    },

    productDoesNotExist() {
      return [
        responseStatus.PRODUCT_IN_DISABLED_PLAN,
        responseStatus.PRODUCT_DOES_NOT_EXIST_IN_PLANS,
        responseStatus.RESOURCE_NOT_FOUND,
      ].includes(this.productStatus);
    },

    productIsTrialExpired() {
      return this.productStatus === responseStatus.TRIAL_ENDED;
    },

    userIsNotAdmin() {
      return [
        responseStatus.USER_IS_NOT_ADMIN,
        responseStatus.USER_IS_NOT_ALLOWED_TO_ACCESS_THIS_RESOURCE,
      ].includes(this.productStatus);
    },

    src() {
      return (
        this.imgProducts[this.selectedProduct] ||
        this.imgProducts["conecta_suite"]
      );
    },

    productDetailLink() {
      return (
        this.productPlanDetails[this.selectedProduct]?.link ||
        "https://conectasuite.com/precos/"
      );
    },

    product() {
      if (isSuiteProduct(this.selectedProduct)) {
        return this.$t("common.conectaSuite");
      }
      return this.$t(modulesDetails[this.selectedProduct].title);
    },

    productStatus: {
      get() {
        return this.status;
      },
      set(new_value) {
        this.$emit("changeProductStatus", new_value);
      },
    },

    showDialog: {
      get() {
        return this.show;
      },
      set(new_value) {
        this.$emit("close", new_value);
      },
    },

    isUnauthorized() {
      return [
        this.responseStatus.UNAUTHORIZED,
        this.responseStatus.USER_IS_NOT_ADMIN,
        this.responseStatus.RESOURCE_NOT_FOUND,
      ].includes(this.productStatus);
    },
  },

  methods: {
    ...mapActions(["functionalityInterest"]),

    openInNewTab,

    async goToPlans() {
      if (
        this.productStatus &&
        this.productStatus !== this.responseStatus.USER_IS_NOT_ADMIN
      ) {
        this.loadingProduct = true;
        this.functionalityInterest({
          product: this.selectedProduct,
          functionality: this.product,
        })
          .then(() => {
            this.$router.push({ name: "BillingUpgradePlan" });
            logBtnEvent({
              label: "Conheça os planos",
              action: `add_${this.selectedProduct}new_plan_by_expired_trial_modal`,
            });

            const analytics = getAnalytics();

            logEvent(analytics, "go_to_self_checkout", {
              click: `dialog_${this.productStatus.toLowerCase()}`,
              main_domain: this.mainDomain,
              email: this.currentUser.email,
              description: "Clicou no botão de 'Conheça os planos'",
            });
          })
          .catch((error) => {
            console.error("goToPlans()", error);
          })
          .finally(() => {
            this.showDialog = false;
            this.loadingProduct = false;
          });
      } else {
        this.showDialog = false;
        openInNewTab("https://conectasuite.com/precos/");
        this.loadingProduct = false;
      }
    },
  },
};
</script>
