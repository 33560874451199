<template>
  <v-list-item
    v-if="showUpgrade || trialPlan"
    :three-line="!showUpgrade"
    class="outlined rounded-lg mx-2 mt-0 mb-4 justify-start"
    active-class="outlined"
    @click="goToSelfCheckout"
  >
    <v-list-item-action class="px-0 mx-0">
      <v-icon :color="buttonColor" v-text="buttonIcon" size="30" />
    </v-list-item-action>
    <v-list-item-content class="ml-5">
      <v-list-item-title :class="`${buttonColor}--text text-subtitle-2`">
        {{ showUpgrade ? "Upgrade disponível para sua empresa" : trialTitle }}
      </v-list-item-title>
      <v-list-item-subtitle class="caption">
        {{ showUpgrade ? "Conheça as opções disponíveis" : trialSubtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import { mapGetters } from "vuex";
import { isTrial } from "@/helpers/services/companyPlan";
import { checkIfIsStandard } from "@/helpers/services/plans";
import { STATUS } from "@/helpers/variables/backendConstants.js";
import { getAnalytics, logEvent } from "firebase/analytics";

export default {
  name: "UpgradeButton",

  props: {
    text: { type: String, default: "" },
    icon: { type: String, default: "" },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      "hasSecurity",
      "hasEnterprise",
      "hasBasic",
      "hasStandard",
      "secondaryColor",
      "clientActivePlans",
      "mainDomain",
      "currentUser",
    ]),

    showUpgrade() {
      return this.hasSecurity || this.hasStandard || this.hasBasic;
    },

    trialPlan() {
      const trial = this.clientActivePlans.filter(
        (plan) => isTrial(plan.status) && checkIfIsStandard(plan.plan)
      );
      return trial[0] || false;
    },

    isExpiredTrial() {
      return (
        this.trialPlan && this.trialPlan.valid_status === STATUS.TRIAL_EXPIRED
      );
    },

    trialSubtitle() {
      return this.isExpiredTrial
        ? "Clique aqui para contratar um plano para continuar aproveitando os produtos Conecta"
        : "Clique aqui para garantir já seu plano sem perder seus dias restantes de teste gratuito";
    },

    trialTitle() {
      return this.isExpiredTrial
        ? "Ops, seu período de teste acabou!"
        : "Contrate seu plano agora mesmo";
    },

    buttonColor() {
      if (this.trialPlan) {
        return this.isExpiredTrial ? "error" : "accentSuite";
      }
      return "accentSuite";
    },

    buttonIcon() {
      if (this.trialPlan) {
        return this.isExpiredTrial
          ? "mdi-emoticon-dead-outline"
          : "mdi-store-plus";
      }
      return "mdi-crown";
    },
  },

  methods: {
    goToSelfCheckout() {
      const analytics = getAnalytics();

      logEvent(analytics, "go_to_self_checkout", {
        click: "user_menu",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description:
          "Clicou no botão de 'Upgrade disponível para sua empresa' no menu do usuário",
      });

      this.$router.push({ name: "BillingUpgradePlan" });
      this.$emit("closeUserMenu");
    },
  },
};
</script>
