export const productsInPlan = {
  conecta_sign: "conecta_sign",
  conecta_control: "conecta_control",
  conecta_suite: "conecta_suite",
};

const products = [
  "conecta_track",
  "conecta_sign",
  "conecta_control",
  "conecta_suite",
];

const suiteProducts = [
  "admin",
  "users",
  "company_profile",
  "billing",
  "reports",
];

const isSignProducts = [""];

export function isProduct(item) {
  return products.includes(item);
}

export function isSuiteProduct(item) {
  return suiteProducts.includes(item);
}

export function isSignProduct(item) {
  return isSignProducts.includes(item);
}
