export const companyTags = [
  "name",
  "address_info",
  "address_city",
  "logo_url",
  "banner_url",
  "banner_link",
  "full_address",
  "full_address",
  "phone",
  "phone2",
  "phone3",
  "cellphone",
  "site",
  "social_media_facebook",
  "social_media_linkedin",
  "social_media_twitter",
  "social_media_youTube",
  "social_media_instagram",
  "social_media_whatsapp",
  "slogan",
  "trading_name",
  "ddi",
  "ddi_phone2",
  "ddi_phone3",
  "ddi_cellphone",
  "ddi_whatsapp",
  "company_custom_1",
  "company_custom_2",
];

export const disabledCompanyTags = {
  name: {
    label: "[*COMPANY_NAME*]",
    keyName: "Nome da empresa",
    value: "",
    info: "Este campo só pode ser alterado em 'Configurações da conta' no Google Admin Console",
    goToProfile: false,
    type: "text",
  },
  address_info: {
    label: "[*COMPANY_ADDRESS*]",
    keyName: "Endereço",
    value: "",
    info: "Este campo é obtido junto a Receita Federal de acordo com o CNPJ registrado",
    goToProfile: false,
    type: "text",
  },
  address_city: {
    label: "[*COMPANY_CITY*]",
    keyName: "Cidade",
    value: "",
    info: "Este campo é obtido junto a Receita Federal de acordo com o CNPJ registrado",
    goToProfile: false,
    type: "text",
  },
};

export const ddiTags = {
  ddi_phone2: {
    keyName: "DDI Telefone 2",
  },
  ddi_phone3: {
    keyName: "DDI Telefone 3",
  },
  ddi_cellphone: {
    keyName: "DDI Celular",
  },
  ddi_whatsapp: {
    keyName: "DDI WhatsApp",
  },
};

export const companyContactTags = {
  phone2: {
    label: "[*COMPANY_PHONE2*]",
    keyName: "Telefone 2",
    hasPhoneMask: true,
    goToProfile: false,
    type: "text",
    placeholder: "+55 49 99999-9999",
  },
  phone3: {
    label: "[*COMPANY_PHONE3*]",
    keyName: "Telefone 3",
    hasPhoneMask: true,
    goToProfile: false,
    type: "text",
    placeholder: "+55 49 99999-9999",
  },
  cellphone: {
    label: "[*COMPANY_CELLPHONE*]",
    keyName: "Celular",
    hasPhoneMask: true,
    goToProfile: false,
    type: "text",
    placeholder: "+55 49 99999-9999",
  },
  social_media_whatsapp: {
    label: "[*COMPANY_WHATSAPP*]",
    keyName: "WhatsApp",
    goToProfile: false,
    hasPhoneMask: true,
    type: "url",
    placeholder: "+55 (99) 99999-9999",
  },
  social_media_facebook: {
    header: "Redes sociais",
    label: "[*COMPANY_FACEBOOK*]",
    keyName: "Facebook",
    goToProfile: false,
    type: "url",
    placeholder: "https://www.facebook.com/suaempresa",
  },
  social_media_linkedin: {
    label: "[*COMPANY_LINKEDIN*]",
    keyName: "LinkedIn",
    goToProfile: false,
    type: "url",
    placeholder: "https://www.linkedin.com/company/suaempresa",
  },
  social_media_twitter: {
    label: "[*COMPANY_TWITTER*]",
    keyName: "Twitter",
    goToProfile: false,
    type: "url",
    placeholder: "https://www.twitter.com/suaempresa",
  },
  social_media_youTube: {
    label: "[*COMPANY_YOUTUBE*]",
    keyName: "YouTube",
    goToProfile: false,
    type: "url",
    placeholder: "https://www.youtube.com/suaempresa",
  },
  social_media_instagram: {
    label: "[*COMPANY_INSTAGRAM*]",
    keyName: "Instagram",
    goToProfile: false,
    type: "url",
    placeholder: "https://www.instagram.com/suaempresa",
  },
};

export const companyFixedCustomTags = {
  company_custom_1: {
    label: "[*COMPANY_CUSTOM_1*]",
    keyName: "Tag personalizada 1",
    placeholder: "Tag personalizada 1",
    info: "Adicione uma informação personalizada para utilizar em seus modelos de assinatura",
    type: "any",
  },
  company_custom_2: {
    label: "[*COMPANY_CUSTOM_2*]",
    keyName: "Tag personalizada 2",
    placeholder: "Tag personalizada 2",
    info: "Adicione uma informação personalizada para utilizar em seus modelos de assinatura",
    type: "any",
  },
};

export const companyMarketingTags = {
  banner_url: {
    label: "[*COMPANY_BANNER_URL*]",
    keyName: "Banner da empresa",
    goToProfile: false,
    disabled: false,
    type: "image",
  },
  banner_link: {
    label: "[*COMPANY_BANNER_LINK*]",
    keyName: "Link do banner",
    goToProfile: false,
    disabled: false,
    type: "url",
    hint: "Adicione um link para onde o banner deve redirecionar ao ser clicado.",
  },
  slogan: {
    label: "[*COMPANY_SLOGAN*]",
    keyName: "Slogan",
    goToProfile: false,
    type: "text",
  },
  trading_name: {
    label: "[*COMPANY_TRADING_NAME*]",
    keyName: "Nome fantasia",
    goToProfile: false,
    type: "text",
  },
};

export const editableCompanyTags = {
  full_address: {
    label: "[*COMPANY_FULL_ADDRESS*]",
    keyName: "Endereço completo",
    placeholder: "Av. Nome de exemplo, 1234",
    hint: "Dica: Insira o logradouro por extenso e o número separado por vírgula.",
    goToProfile: false,
    disabled: false,
    type: "text",
  },
  phone: {
    header: "Contato",
    label: "[*COMPANY_PHONE1*]",
    keyName: "Telefone 1",
    hasPhoneMask: true,
    disabled: true,
    info: "Campo disponível para edição no 'Perfil da empresa', clique aqui para editar",
    goToProfile: true,
    type: "text",
  },
  phone2: {
    label: "[*COMPANY_PHONE2*]",
    keyName: "Telefone 2",
    hasPhoneMask: true,
    goToProfile: false,
    type: "text",
  },
  phone3: {
    label: "[*COMPANY_PHONE3*]",
    keyName: "Telefone 3",
    hasPhoneMask: true,
    goToProfile: false,
    type: "text",
  },
  cellphone: {
    label: "[*COMPANY_CELLPHONE*]",
    keyName: "Celular",
    hasPhoneMask: true,
    goToProfile: false,
    type: "text",
  },
  site: {
    label: "[*COMPANY_SITE*]",
    keyName: "Site",
    disabled: true,
    info: "Campo disponível para edição no 'Perfil da empresa', clique aqui para editar",
    goToProfile: true,
    type: "url",
  },
  social_media_facebook: {
    header: "Redes sociais",
    label: "[*COMPANY_FACEBOOK*]",
    keyName: "Facebook",
    goToProfile: false,
    type: "url",
    placeholder: "https://www.facebook.com/suaempresa",
  },
  social_media_linkedin: {
    label: "[*COMPANY_LINKEDIN*]",
    keyName: "LinkedIn",
    goToProfile: false,
    type: "url",
    placeholder: "https://www.linkedin.com/company/suaempresa",
  },
  social_media_twitter: {
    label: "[*COMPANY_TWITTER*]",
    keyName: "Twitter",
    goToProfile: false,
    type: "url",
    placeholder: "https://www.twitter.com/suaempresa",
  },
  social_media_youTube: {
    label: "[*COMPANY_YOUTUBE*]",
    keyName: "YouTube",
    goToProfile: false,
    type: "url",
    placeholder: "https://www.youtube.com/suaempresa",
  },
  social_media_instagram: {
    label: "[*COMPANY_INSTAGRAM*]",
    keyName: "Instagram",
    goToProfile: false,
    type: "url",
    placeholder: "https://www.instagram.com/suaempresa",
  },
  social_media_whatsapp: {
    label: "[*COMPANY_WHATSAPP*]",
    keyName: "WhatsApp",
    goToProfile: false,
    hasPhoneMask: true,
    type: "url",
  },
  slogan: {
    header: "Mais informações",
    label: "[*COMPANY_SLOGAN*]",
    keyName: "Slogan",
    goToProfile: false,
    type: "text",
  },
  trading_name: {
    label: "[*COMPANY_TRADING_NAME*]",
    keyName: "Nome fantasia",
    goToProfile: false,
    type: "text",
  },
  logo_url: {
    label: "[*COMPANY_LOGO_URL*]",
    keyName: "Logo da empresa",
    goToProfile: false,
    disabled: false,
    type: "image",
  },
  banner_url: {
    label: "[*COMPANY_BANNER_URL*]",
    keyName: "Banner da empresa",
    goToProfile: false,
    disabled: false,
    type: "image",
  },
  banner_link: {
    label: "[*COMPANY_BANNER_LINK*]",
    keyName: "Link do banner",
    goToProfile: false,
    disabled: false,
    type: "url",
    hint: "Adicione um link para onde o banner deve redirecionar ao ser clicado.",
  },
  company_custom_1: {
    label: "[*COMPANY_CUSTOM_1*]",
    keyName: "Tag personalizada 1",
    placeholder: "Tag personalizada 1",
    info: "Adicione uma informação personalizada para utilizar em seus modelos de assinatura",
    type: "any",
  },
  company_custom_2: {
    label: "[*COMPANY_CUSTOM_2*]",
    keyName: "Tag personalizada 2",
    placeholder: "Tag personalizada 2",
    info: "Adicione uma informação personalizada para utilizar em seus modelos de assinatura",
    type: "any",
  },
};

export const userTags = [
  "name",
  "email",
  "photo",
  "phone",
  "mobile",
  "departament",
  "position",
  "ramal",
  "sign_photo",
  "calendar_schedules_link",
  "user_custom_1",
  "user_custom_2",
  "whatsapp_number",
];

export const userDisabledTags = {
  name: {
    label: "[*USER_FULL_NAME*]",
    keyName: "Nome + Sobrenome",
    translatePath: "userInformations.nameAndLastName",
    labelGoogle: "First + Last name",
    type: "text",
  },
  email: {
    label: "[*USER_EMAIL*]",
    keyName: "E-mail",
    labelGoogle: "Email Address",
    type: "text",
    translatePath: "common.email",
  },
  photo: {
    label: "[*USER_PHOTO*]",
    keyName: "Foto do usuário",
    type: "image",
    translatePath: "userInformations.userPhoto",
  },
};

export const userEditableTags = {
  phone: {
    label: "[*USER_PHONE*]",
    keyName: "Telefone trabalho",
    labelGoogle: "Work Phone",
    type: "text",
    translatePath: "userInformations.workPhone",
  },
  mobile: {
    label: "[*USER_MOBILE*]",
    keyName: "Telefone móvel",
    labelGoogle: "Mobile Phone",
    type: "text",
    translatePath: "userInformations.mobilePhone",
  },
  whatsapp_number: {
    label: "[*USER_WHATSAPP*]",
    keyName: "WhatsApp",
    labelGoogle: "WhatsApp",
    hasPhoneMask: true,
    type: "social",
    translatePath: "userInformations.whatsapp",
  },
  departament: {
    label: "[*USER_DEPARTAMENT*]",
    keyName: "Departamento",
    labelGoogle: "Department",
    type: "text",
    translatePath: "userInformations.departament",
  },
  position: {
    label: "[*USER_POSITION*]",
    keyName: "Cargo",
    labelGoogle: "Employee Title",
    type: "text",
    translatePath: "userInformations.office",
  },
  ramal: {
    label: "[*USER_RAMAL*]",
    keyName: "Ramal",
    type: "text",
    translatePath: "userInformations.extension",
  },
  sign_photo: {
    label: "[*USER_SIGN_PHOTO*]",
    keyName: "URL da foto de assinatura",
    type: "image",
    translatePath: "userInformations.signaturePhotoUrl",
  },
  calendar_schedules_link: {
    label: "[*CALENDAR_SCHEDULES_LINK*]",
    keyName: "URL para agendamento",
    hint: "Aprenda como criar um link de agendamento no Google Agenda <a target='_blank' style='text-decoration: none' href='https://youtu.be/lmJ5clI-bwo'><b>clicando aqui</b></a>.",
    type: "url",
    translateHintPath: "userInformations.shedulingHelpText",
    translatePath: "userInformations.urlForScheduling",
  },
  user_custom_1: {
    label: "[*USER_CUSTOM_1*]",
    keyName: "Tag personalizada 1",
    placeholder: "Conteúdo da tag personalizada 1",
    info: "Adicione uma informação personalizada para utilizar em seus modelos de assinatura",
    infoTranslatePath: "userInformations.customTagTooltipText",
    type: "any",
    translatePlaceholderPath: "userInformations.customTagPlaceholder1",
    translatePath: "userInformations.customTag1",
  },
  user_custom_2: {
    label: "[*USER_CUSTOM_2*]",
    keyName: "Tag personalizada 2",
    placeholder: "Conteúdo da tag personalizada 2",
    info: "Adicione uma informação personalizada para utilizar em seus modelos de assinatura",
    type: "any",
    infoTranslatePath: "userInformations.customTagTooltipText",
    translatePlaceholderPath: "userInformations.customTagPlaceholder2",
    translatePath: "userInformations.customTag2",
  },
};

export const vacationTags = [
  { name: "Data de inicio", tag: "[*VACATION_INIT_DATE*]" },
  { name: "Data de fim", tag: "[*VACATION_END_DATE*]" },
];

export const userTagsList = [
  { name: "Primeiro nome", tag: "[*USER_FIRST_NAME*]" },
  { name: "Sobrenome", tag: "[*USER_LAST_NAME*]" },
  { name: "Nome completo", tag: "[*USER_FULL_NAME*]" },
  { name: "E-mail", tag: "[*USER_EMAIL*]" },
  { name: "Foto", tag: "[*USER_PHOTO*]" },
  { name: "Telefone", tag: "[*USER_PHONE*]" },
  { name: "WhatsApp (link)", tag: "[*USER_WHATSAPP*]" },
  { name: "WhatsApp logo preto e branco", tag: "[*USER_WHATSAPP_LOGO_PB*]" },
  { name: "WhatsApp logo colorido", tag: "[*USER_WHATSAPP_LOGO_C*]" },
  { name: "Ramal", tag: "[*USER_RAMAL*]" },
  {
    name: "Foto do usuário para assinatura ",
    tag: "[*USER_SIGN_PHOTO*]",
  },
  {
    name: "URL para agendamento",
    tag: "[*CALENDAR_SCHEDULES_LINK*]",
  },
  { name: "Celular", tag: "[*USER_MOBILE*]" },
  { name: "Departamento", tag: "[*USER_DEPARTAMENT*]" },
  { name: "Cargo", tag: "[*USER_POSITION*]" },
  { name: "Tag personalizável 1", tag: "[*USER_CUSTOM_1*]" },
  { name: "Tag personalizável 2", tag: "[*USER_CUSTOM_2*]" },
];

export const orgTagsList = [
  { name: "Nome da empresa", tag: "[*COMPANY_NAME*]" },
  { name: "Logo da empresa", tag: "[*COMPANY_LOGO_URL*]" },
  { name: "Banner da empresa", tag: "[*COMPANY_BANNER_URL*]" },
  { name: "Link do banner", tag: "[*COMPANY_BANNER_LINK*]" },
  { name: "Endereço", tag: "[*COMPANY_ADDRESS*]" },
  { name: "Endereço Completo", tag: "[*COMPANY_FULL_ADDRESS*]" },
  { name: "Cidade", tag: "[*COMPANY_CITY*]" },
  { name: "Telefone 1", tag: "[*COMPANY_PHONE1*]" },
  { name: "Telefone 2", tag: "[*COMPANY_PHONE2*]" },
  { name: "Telefone 3", tag: "[*COMPANY_PHONE3*]" },
  { name: "Celular", tag: "[*COMPANY_CELLPHONE*]" },
  { name: "Site", tag: "[*COMPANY_SITE*]" },
  { name: "Facebook (link)", tag: "[*COMPANY_FACEBOOK*]" },
  {
    name: "Facebook logo preto e branco",
    tag: "[*COMPANY_FACEBOOK_LOGO_PB*]",
  },
  { name: "Facebook logo colorido", tag: "[*COMPANY_FACEBOOK_LOGO_C*]" },
  { name: "Linkedin (link)", tag: "[*COMPANY_LINKEDIN*]" },
  {
    name: "Linkedin logo preto e branco",
    tag: "[*COMPANY_LINKEDIN_LOGO_PB*]",
  },
  { name: "Linkedin logo colorido", tag: "[*COMPANY_LINKEDIN_LOGO_C*]" },
  { name: "Twitter (link)", tag: "[*COMPANY_TWITTER*]" },
  {
    name: "Twitter logo preto e branco",
    tag: "[*COMPANY_TWITTER_LOGO_PB*]",
  },
  { name: "Twitter logo colorido", tag: "[*COMPANY_TWITTER_LOGO_C*]" },
  { name: "Youtube (link)", tag: "[*COMPANY_YOUTUBE*]" },
  {
    name: "Youtube logo preto e branco",
    tag: "[*COMPANY_YOUTUBE_LOGO_PB*]",
  },
  { name: "Youtube logo colorido", tag: "[*COMPANY_YOUTUBE_LOGO_C*]" },
  { name: "WhatsApp (link)", tag: "[*COMPANY_WHATSAPP*]" },
  { name: "WhatsApp logo preto e branco", tag: "[*COMPANY_WHATSAPP_LOGO_PB*]" },
  { name: "WhatsApp logo colorido", tag: "[*COMPANY_WHATSAPP_LOGO_C*]" },
  { name: "Instagram (link)", tag: "[*COMPANY_INSTAGRAM*]" },
  {
    name: "Instagram logo preto e branco",
    tag: "[*COMPANY_INSTAGRAM_LOGO_PB*]",
  },
  {
    name: "Instagram logo colorido",
    tag: "[*COMPANY_INSTAGRAM_LOGO_C*]",
  },
  { name: "Slogan", tag: "[*COMPANY_SLOGAN*]" },
  { name: "Nome fantasia", tag: "[*COMPANY_TRADING_NAME*]" },
  { name: "Tag personalizável 1", tag: "[*COMPANY_CUSTOM_1*]" },
  { name: "Tag personalizável 2", tag: "[*COMPANY_CUSTOM_2*]" },
];

export const socialTags = [
  "[*COMPANY_FACEBOOK*]",
  "[*COMPANY_INSTAGRAM*]",
  "[*COMPANY_LINKEDIN*]",
  "[*COMPANY_TWITTER*]",
  "[*COMPANY_YOUTUBE*]",
  "[*COMPANY_WHATSAPP*]",
  "[*COMPANY_BANNER_LINK*]",
];

const socialImageTypes = {
  "[*COMPANY_INSTAGRAM_LOGO_PB*]": "[*COMPANY_INSTAGRAM*]",
  "[*COMPANY_INSTAGRAM_LOGO_C*]": "[*COMPANY_INSTAGRAM*]",
  "[*COMPANY_FACEBOOK_LOGO_PB*]": "[*COMPANY_FACEBOOK*]",
  "[*COMPANY_FACEBOOK_LOGO_C*]": "[*COMPANY_FACEBOOK*]",
  "[*COMPANY_LINKEDIN_LOGO_PB*]": "[*COMPANY_LINKEDIN*]",
  "[*COMPANY_LINKEDIN_LOGO_C*]": "[*COMPANY_LINKEDIN*]",
  "[*COMPANY_TWITTER_LOGO_PB*]": "[*COMPANY_TWITTER*]",
  "[*COMPANY_TWITTER_LOGO_C*]": "[*COMPANY_TWITTER*]",
  "[*COMPANY_YOUTUBE_LOGO_PB*]": "[*COMPANY_YOUTUBE*]",
  "[*COMPANY_YOUTUBE_LOGO_C*]": "[*COMPANY_YOUTUBE*]",
  "[*COMPANY_WHATSAPP_LOGO_PB*]": "[*COMPANY_WHATSAPP*]",
  "[*COMPANY_WHATSAPP_LOGO_C*]": "[*COMPANY_WHATSAPP*]",
  "[*USER_WHATSAPP_LOGO_C*]": "[*USER_WHATSAPP*]",
  "[*USER_WHATSAPP_LOGO_PB*]": "[*USER_WHATSAPP*]",
};

function createSocialTypeEntries(tags, types) {
  return tags.reduce(
    (acc, next) =>
      acc.concat(
        types.map((type) => [`[*${next}_LOGO_${type}*]`, `[*${next}*]`])
      ),
    []
  );
}

const iconTypes = ["LIGHT", "DARK"];
const iconTags = [
  "COMPANY_INSTAGRAM",
  "COMPANY_FACEBOOK",
  "COMPANY_LINKEDIN",
  "COMPANY_TWITTER",
  "COMPANY_YOUTUBE",
  "COMPANY_WHATSAPP",
  "CALENDAR_SCHEDULES_LINK",
];
const socialIconTypes = createSocialTypeEntries(iconTags, iconTypes);

export const socialTypes = Object.fromEntries(
  Object.entries(socialImageTypes).concat(socialIconTypes)
);

export const allTags = [...orgTagsList, ...userTagsList].map((item) => {
  return {
    type: "cardmenuitem",
    value: item.tag,
    text: item.name.toLowerCase(),
  };
});

export const USER_TAGS = {
  USER_FIRST_NAME: "first_name",
  USER_LAST_NAME: "last_name",
  USER_FULL_NAME: "name",
  USER_EMAIL: "email",
  USER_PHOTO: "photo",
  USER_POSITION: "position",
  USER_DEPARTAMENT: "departament",
  USER_RAMAL: "ramal",
  USER_SIGN_PHOTO: "sign_photo",
  USER_PHONE: "phone",
  USER_MOBILE: "mobile",
  CALENDAR_SCHEDULES_LINK: "calendar_schedules_link",
  USER_CUSTOM_1: "user_custom_1",
  USER_CUSTOM_2: "user_custom_2",
  USER_WHATSAPP: "whatsapp_number",
  VACATION_INIT_DATE: "vacation_init_date",
  VACATION_END_DATE: "vacation_end_date",
};

export const COMPANY_TAGS = {
  COMPANY_NAME: "name",
  COMPANY_CITY: "address_city",
  COMPANY_ADDRESS: "address_info",
  COMPANY_FULL_ADDRESS: "full_address",
  COMPANY_FACEBOOK: "social_media_facebook",
  COMPANY_LINKEDIN: "social_media_linkedin",
  COMPANY_TWITTER: "social_media_twitter",
  COMPANY_YOUTUBE: "social_media_youTube",
  COMPANY_INSTAGRAM: "social_media_instagram",
  COMPANY_WHATSAPP: "social_media_whatsapp",
  COMPANY_PHONE1: "phone",
  COMPANY_PHONE2: "phone2",
  COMPANY_PHONE3: "phone3",
  COMPANY_CELLPHONE: "cellphone",
  COMPANY_SITE: "site",
  COMPANY_SLOGAN: "slogan",
  COMPANY_TRADING_NAME: "trading_name",
  COMPANY_LOGO_URL: "logo_url",
  COMPANY_BANNER_URL: "banner_url",
  COMPANY_BANNER_LINK: "banner_link",
  COMPANY_CUSTOM_1: "company_custom_1",
  COMPANY_CUSTOM_2: "company_custom_2",
};
