import Vue from "vue";
import store from "../store";
import VueRouter, { START_LOCATION } from "vue-router";
import { modules } from "../helpers/variables/modules";
import { generalRoutes } from "@/router/routes/general";
import { suiteRoutes } from "@/router/routes/suite";
import { signRoutes } from "@/router/routes/sign";
import { trackRoutes } from "@/router/routes/track";
import { verifyPagePermission } from "@/helpers/services/routes.js";
import {
  permissionsActions,
  permissionsFunctionActions,
} from "@/helpers/variables/general.js";
import { responseStatus } from "@/helpers/variables/backendConstants";
import { getPageTitleTranslate } from "@/helpers/services/routes.js";

Vue.use(VueRouter);

const routes = [
  ...generalRoutes,
  ...suiteRoutes,
  ...signRoutes,
  ...trackRoutes,
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const breadCrumb = to.meta.breadCrumb;
  store.commit("closeControlCard");

  if (breadCrumb && breadCrumb[breadCrumb.length - 1]) {
    const lastBreadCrumbText = breadCrumb[breadCrumb.length - 1].text;
    store.commit("setFunctionalityClicked", lastBreadCrumbText);
  }
  store.commit("setLoading", true);

  if (to.name === "TemplateEdit") {
    store.commit("setSidebarMini", true);
  }

  if (
    from.name === "VacationMessage" &&
    ["Auth", "AuthCallback"].includes(to.name)
  ) {
    next({ name: "Home" });
  } else {
    next();
  }
});

router.afterEach(() => {
  if (store.getters.permissionDialogWithDelay) {
    store.commit(
      "setPermissionDialog",
      store.getters.permissionDialogWithDelay
    );
  }

  store.commit("setLoading", false);
});

// authentication routes
router.beforeEach(async (to, from, next) => {
  store.commit("setCurrentRoute", to.name); // Adiciona nome da rota atual no store
  let title = getPageTitleTranslate(to.name);
  if (title) document.title = `${title}` + " - Conecta Suite";

  const token = store.getters.token;

  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    if (to.params.plan_sku) {
      localStorage.setItem("skuPlan", to.params.plan_sku);
    }
    if (token) {
      if (await store.dispatch("authentication")) {
        loadRoute(to, next, from);
      } else {
        localStorage.setItem("nextPage", to.name);
        await store.dispatch("logoutWithDialog");
      }
    } else {
      localStorage.setItem("nextPage", to.name);
      next({ name: "Login" });
    }
  } else if (["Login", "AuthCallback"].includes(to.name)) {
    if (token) {
      if (await store.dispatch("authentication")) {
        commitSuite();
        next({ name: "Home" });
      } else {
        await store.dispatch("logoutWithDialog");
      }
    } else {
      next();
    }
  } else {
    loadRoute(to, next, from);
  }
  window.scrollTo({ top: 0, behavior: "smooth" });
});

function verifyIfUserHasAccess(from, to, next, slug, code) {
  const permission = verifyPagePermission(
    to.meta.permission,
    to.meta.app,
    to.meta.admin
  );

  if (store.getters.isAdmin) {
    to.meta.admin ? next({ name: "Home" }) : next();
  } else {
    if (store.getters.isAuthenticated && from.name) {
      if (permission === responseStatus.ACCESS_ALLOWED) {
        next();
      } else if (permission) {
        store.commit("setPermissionDialog", {
          show: true,
          status: code ? code : permission,
          app: slug,
        });
      } else if (!permission) {
        next({ name: "Home" });
      } else {
        next();
      }
    } else if (store.getters.isAuthenticated && store.getters.isAdmin) {
      next();
    } else if (!from.name) {
      store.commit("saveAccessDialogInfo", {
        show: true,
        status: code ? code : permission,
        app: slug,
      });
      next({ name: "Home" });
    }
  }

  store.commit("setLoading", false);
}

async function loadRoute(to, next, from) {
  const isSimulation = store.getters.isSimulation;
  const slug = getPageSlug(to, next) || modules.suite;

  if (slug === "admin") {
    commitSuite();
    if (store.getters.isConecta) {
      next();
    } else {
      next({ name: "Home" });
    }
  } else if (
    [
      "Home",
      "AuthCallback",
      "NotFound",
      "Error",
      "Disabled",
      "UserConfirmation",
      "UserTagsConfirmation",
      "ExtBillingUpgradePlan",
      "Auth",
      "Login",
      "NewLogin",
      "Workspace Checklist",
      "ContactUs",
    ].includes(to.name)
  ) {
    next();
  } else if (slug === "company_profile") {
    const hasBillingPermission = store.getters.hasBillingPermission;
    const hasProfilePermission = store.getters.hasProfilePermission;
    if (hasBillingPermission || hasProfilePermission) {
      next();
    } else {
      await store
        .dispatch(permissionsFunctionActions[slug], to.meta.app)
        .then((response) => {
          if (response.hasPermission === false && response.code) {
            commitErrorReport(response.code, slug);
          } else {
            commitToMetaApp(to.meta.app);
            next();
          }
        })
        .finally(() => store.commit("setLoading", false));
    }
  } else if (
    slug === "users" ||
    slug === "user_profile" ||
    slug === "onboarding"
  ) {
    await store
      .dispatch(permissionsFunctionActions[slug], to.meta.app)
      .then((response) => {
        if (response.hasPermission === false && response.code) {
          commitErrorReport(response.code, "conecta_suite");
        } else {
          commitToMetaApp(to.meta.app);
          next();
        }
      })
      .finally(() => store.commit("setLoading", false));
  } else if (isSimulation) {
    next();
  } else if (from === START_LOCATION) {
    await store
      .dispatch(permissionsActions[slug], to.meta.app)
      .then(() => {
        commitToMetaApp(to.meta.app);
        next();
      })
      .catch(({ response }) => {
        if (response.status === 403 && response.data.code) {
          commitErrorReport(response.data.code, slug);
        } else {
          verifyIfUserHasAccess(from, to, next, slug, response.data.code);
        }
      })
      .finally(() => store.commit("setLoading", false));
  } else {
    await store
      .dispatch(permissionsFunctionActions[slug], to.meta.app)
      .then((response) => {
        if (response.hasPermission === false && response.code) {
          commitErrorReport(response.code, slug);
        } else {
          commitToMetaApp(to.meta.app);
          next();
        }
      })
      .finally(() => store.commit("setLoading", false));
  }
}

function commitSuite() {
  store.commit("setPrimaryColor", "primarySuite");
  store.commit("setSecondaryColor", "accentSuite");
  store.commit("removeProductName");
}

function commitTrack() {
  store.commit("setPrimaryColor", "primaryTrack");
  store.commit("setSecondaryColor", "accentTrack");
  store.commit("setProductName", modules.track);
}

function commitToMetaApp(toMetaApp) {
  if (toMetaApp === "conecta_track") {
    commitTrack();
  } else {
    commitSuite();
  }
}

function commitErrorReport(code, slug) {
  const errorReport = {
    show: true,
    status: code,
    app: slug,
  };
  store.commit("setPermissionDialog", errorReport);
}

function checkPage(path, page) {
  return path.includes(`/${page}`);
}

function getPageSlug(to, next) {
  const page = (to.meta.page || to.meta.app).replace("conecta_", "");
  if (to.name && to.name.includes("Reseller")) {
    return "reseller";
  } else if (to.name && to.name === "ResellerBilling") {
    return "reseller";
  } else if (adminRoutes.includes(to.name)) {
    return "admin";
  } else if (to.path.includes("billing")) {
    return "billing";
  } else if (
    to.name &&
    ["Reports", "ReportsSignatures", "AccountsReports"].includes(to.name)
  ) {
    return "reports";
  } else if (to.name && to.name === "AdminFunctions") {
    return "manage_admins";
  } else if (to.name && to.name === "Onboarding") {
    return "onboarding";
  } else if (to.name && to.name === "Users") {
    return "users";
  } else if (to.name && to.name === "User") {
    return "user_profile";
  } else if (to.name && to.name === "Inventory") {
    return "inventory";
  } else if (to.path && page && checkPage(to.path, page)) {
    return checkPage(to.path, page) ? to.meta.app : modules.suite;
  } else if (to.path && to.name === "Company") {
    return "company_profile";
  } else {
    commitSuite();
    next();
  }
}

const adminRoutes = [
  "PlansManager",
  "ServicesManager",
  "CentralNotifications",
  "BanClients",
];

export default router;
