import {
  today,
  addDays,
  removeDays,
  formatDateForDatabase,
  checkIfIsGoingToExpire,
  getTheEndOfTrial,
  getFlexEndTransaction,
  getAnnualEndTransaction,
} from "@/helpers/services/utils";

import { STATUS, SUBSCRIPTIONS } from "@/helpers/variables/backendConstants";

export function isAnActiveCompanyPlan(company_plan) {
  return company_plan.status === STATUS.ACTIVE;
}

export function isCompanyPlanInTrail(company_plan) {
  return company_plan.status === STATUS.TRIAL;
}

export function getCompanyPlanProducts(company_plan) {
  const { products } = company_plan.plan;
  return products.map((product) => product.name);
}

export function isActivePlan(status) {
  return status === STATUS.ACTIVE;
}

export function isTrial(subscription_type) {
  return subscription_type === SUBSCRIPTIONS.TRIAL;
}

export function isFlex(subscription_type) {
  return subscription_type === SUBSCRIPTIONS.FLEX;
}

export function isAnnual(subscription_type) {
  return subscription_type === SUBSCRIPTIONS.ANNUAL;
}

/**
 * Function to get a valid payload to create or edit a CompanyPlan.
 * @param {String} client_key The string that represents that client
 * @param {Object} plan_information A object that has the companyPlan details
 * @param {Number} total The total value of the plan
 * @param {Array} services_cart An array of custom add-ons for the plan
 * @param {Boolean} is_new_plan If it's a new plan
 * @param {Boolean} is_conecta If it's a Conecta user
 * @return {Object} The company plan payload
 */
export function getHirePlanPayload(
  client_key,
  plan_information,
  total,
  services_cart,
  is_new_plan = true,
  is_conecta = false
) {
  const {
    status,
    subscription_type,
    discount,
    init_billing,
    init_transaction,
    end_transaction,
    end_trial,
    notes,
    max_licenses,
    create_transaction,
    plan_key,
    renewal_date,
  } = plan_information;

  let payload = {
    status,
    total_price: Number(total.toFixed(2)),
  };

  const isActive = isActivePlan(status);
  const isTrialPlan = isTrial(status) || isTrial(subscription_type);
  const isAnnualPlan = isAnnual(subscription_type);

  const hasServices = services_cart.length > 0;

  // If it is a Conecta User, there ase some special settings like notes, edited end_trial and services
  if (is_conecta) {
    payload.end_trial = formatDateForDatabase(end_trial);
    payload.notes = notes;

    if (hasServices) {
      payload.services = services_cart;
    }
  }

  // If it is ACTIVE plan, there ase some special settings
  if (isActive) {
    payload.subscription_type = subscription_type;
    payload.init_billing = init_billing;
    payload.init_transaction = init_transaction;
    payload.end_transaction = end_transaction;
    payload.renewal_date = renewal_date;

    if (isAnnualPlan) {
      payload.max_licenses = Number(max_licenses);
      payload.create_transaction = create_transaction;
    }
  } else if (isTrialPlan && !is_new_plan) {
    payload = {
      notes: notes || "",
      end_trial: formatDateForDatabase(end_trial),
    };

    if (hasServices && is_conecta) {
      payload.services = services_cart;
    }
  } else if (isTrialPlan && is_new_plan) {
    payload.subscription_type = SUBSCRIPTIONS.TRIAL;
  }

  // Params only for new plans
  if (is_new_plan) {
    payload.plan_key = plan_key;
    payload.company_key = client_key;

    // Send the discount only if it is a new plan
    if (isActive) {
      payload.discount = discount;
    }
  }

  return payload;
}
/**
 * Function calculate a valid end_trial for a CompanyPlan.
 * @param {Object} orderDetails A object that has the companyPlan details
 * @param {String} nearestCurrentPlansDate A string that represents the nearest end_trial date according with trial or active plans
 * @param {Boolean} hasTrialPlans If the company currently has trial plans
 * @param {Boolean} hasActivePlans If the company currently has active plans
 * @param {(Boolean | Object)} clientHasThisPlan If the company already has this plan active (it's an edition)
 * @return {Object} The company plan payload
 */
export function setEndTrialDate(
  orderDetails,
  nearestEndTrialDate,
  hasTrialPlans = false,
  hasActivePlans = false,
  hasThisPlan = false
) {
  let endTrial = null;

  if (hasThisPlan) {
    const { end_trial } = hasThisPlan;
    endTrial = formatDateForDatabase(end_trial);
  } else if (hasActivePlans) {
    endTrial = formatDateForDatabase(nearestEndTrialDate);
  } else if (isTrial(orderDetails.subscription_type)) {
    endTrial = hasTrialPlans
      ? formatDateForDatabase(nearestEndTrialDate)
      : formatDateForDatabase(getTheEndOfTrial());
  } else {
    endTrial = hasTrialPlans
      ? formatDateForDatabase(nearestEndTrialDate)
      : formatDateForDatabase(removeDays(today, 1));
  }

  return endTrial;
}

export function setInitDates(orderDetails) {
  const isTrialPlan = isTrial(orderDetails.subscription_type);

  if (isTrialPlan) {
    orderDetails.init_billing = null;
    orderDetails.init_transaction = null;
    return orderDetails;
  } else {
    const { end_trial } = orderDetails;

    const initDates =
      checkIfIsGoingToExpire(end_trial) && end_trial
        ? formatDateForDatabase(addDays(end_trial, 1))
        : formatDateForDatabase(today);

    orderDetails.init_billing = initDates;
    orderDetails.init_transaction = initDates;

    return orderDetails;
  }
}

export function setEndTransactionByPeriod(
  subscription_type,
  initTransaction = null
) {
  if (isTrial(subscription_type)) {
    return null;
  }

  initTransaction = !initTransaction
    ? today
    : formatDateForDatabase(initTransaction);

  const endTransaction = isFlex(subscription_type)
    ? getFlexEndTransaction(initTransaction)
    : getAnnualEndTransaction(initTransaction);

  return formatDateForDatabase(endTransaction);
}
