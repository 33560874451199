var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`content-container ${
    _vm.hasOpenedHubChat
      ? _vm.isChatWidgetOpened
        ? 'container-with-chat-opened'
        : 'container-with-chat-closed'
      : 'float-content-container'
  } elevation-6`},[_c('v-row',{staticClass:"mt-0 mr-0 mb-2",staticStyle:{"min-width":"100%"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('back')}}},[_c('v-icon',{attrs:{"size":"30px"}},[_vm._v("ph-caret-left")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"size":"33px"}},[_vm._v("ph-arrows-in-simple")])],1)],1),_c('main',{staticClass:"main-content"},[_c('h2',{staticClass:"content-title"},[_vm._v(_vm._s(_vm.item.title))]),_c('p',{staticClass:"content-subtitle mt-2"},[_vm._v(" "+_vm._s(_vm.item.description)+" ")]),_c('v-card-text',{staticClass:"pa-0",staticStyle:{"font-size":"14px"}},[(_vm.item.stepsOptions && _vm.item.stepsOptions.length)?_c('div',{staticClass:"mt-5"},_vm._l((_vm.item.stepsOptions),function(option,index){return _c('div',{key:index},[_c('b',[_vm._v(_vm._s(`${_vm.$t("common.option")} ${index + 1}`))]),_c('ol',{staticClass:"ordered-list"},_vm._l((option.steps),function(step,stepIndex){return _c('li',{key:stepIndex,staticClass:"mt-4"},[_vm._v(" "+_vm._s(step)+" ")])}),0),(option.image)?_c('v-img',{staticClass:"mt-4 mb-6",attrs:{"src":option.image,"max-height":"261px","max-width":"270px"}}):_vm._e()],1)}),0):_vm._e(),(_vm.item.steps && _vm.item.steps.length)?_c('ol',{staticClass:"ordered-list"},_vm._l((_vm.item.steps),function(step,index){return _c('li',{key:index,staticClass:"mt-4"},[_vm._v(" "+_vm._s(step)+" ")])}),0):_vm._e(),(_vm.item.image)?_c('v-img',{staticClass:"mt-6",attrs:{"src":_vm.item.image,"max-height":"261px","max-width":"270px"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }