const state = () => ({
  /* LISTA POR PRODUTO DAS PÁGINAS PERTENCENTES A ELES */
  conectaSuiteRoutes: [
    "Home",
    "Users",
    "AdminFunctions",
    "Company",
    "User",
    "API",
    "ResellerClients",
    "ResellerClientInfo",
    "ResellerNewPlan",
    "ResellerBilling",
    "ResellerNotifications",
    "ResellerTransactions",
    "PlansManager",
    "BillingTransactions",
    "BillingAddPlan",
    "BillingPlans",
  ],
  conectaTrackRoutes: ["TrackUsers", "TrackUser"],
  conectaSignRoutes: [
    "SignMain",
    "SignTemplates",
    "SignGroups",
    "SignOrganizationUnits",
    "SignTags",
    "SignUsers",
    "ApplySignTemplates",
    "VacationMessageTemplates",
    "TemplateEdit",
    "MessageTemplates",
    "NewVacationTemplate",
    "VacationTemplateEdit",
  ],
  currentRoute: "Home",
  selfCheckoutRoute: ["BillingUpgradePlan"],
});

const getters = {
  isUpgradePlanPage: (state) => state.selfCheckoutRoute == state.currentRoute,
  isSuitePage: (state) => state.conectaSuiteRoutes.includes(state.currentRoute),
  isSignPage: (state) => state.conectaSignRoutes.includes(state.currentRoute),
  isTrackPage: (state) => state.conectaTrackRoutes.includes(state.currentRoute),
  currentRoute: (state) => state.currentRoute,
};

// actions
const actions = {};

// mutations
const mutations = {
  setCurrentRoute(state, new_route) {
    state.currentRoute = new_route;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
