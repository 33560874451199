<template>
  <v-app v-resize="onResize" class="overflow-hidden fill-height main-container">
    <!-- NAVBAR -->
    <SignEditorNavbar />

    <!-- BANNER PARA AVISAR SE A ASSINATURA FOI APLICADA -->
    <v-banner
      v-model="showAppliedBanner"
      sticky
      :single-line="$vuetify.breakpoint.mdAndUp"
      :two-line="$vuetify.breakpoint.smAndDown"
      app
      transition="slide-y-transition"
      color="success"
      dark
      elevation="1"
      style="z-index: 999; border-bottom: 0px !important"
      class="py-2 text-wrap break-word"
    >
      <v-avatar color="white" class="mr-4" size="30">
        <v-icon v-text="'mdi-check'" color="success" />
      </v-avatar>
      Sucesso! Em breve o modelo será aplicado para os usuários. Essa alteração
      pode levar até 15 minutos.
      <template v-slot:actions="{ dismiss }">
        <v-btn text dark @click="dismiss"> Dispensar </v-btn>
      </template>
    </v-banner>

    <v-banner
      v-if="isSimulation"
      sticky
      app
      :single-line="!$vuetify.breakpoint.smAndDown"
      :class="`${!$vuetify.breakpoint.smAndDown ? 'py-1' : ''} background`"
      icon="mdi-alert-circle-outline"
      icon-color="warning"
      style="height: auto; top: 0px"
    >
      Modo Parceiro - Você está gerenciando
      <span class="font-weight-bold primary--text">{{ mainDomain }}</span>
      <template v-slot:actions>
        <v-btn
          small
          width="100%"
          outlined
          :href="toReseller"
          class="font-weight-bold"
          rounded
        >
          <v-icon left v-text="'mdi-arrow-left'" dense />Voltar
        </v-btn>
      </template>
    </v-banner>

    <v-main
      class="background overflow-x-hidden pb-0 mb-0 fill-height scrollable-area"
    >
      <v-card
        :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-3'"
        flat
        tile
        class="overflow-y-auto ma-0 pa-0 rounded-0 fill-height"
      >
        <router-view />
      </v-card>

      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        color="accent"
        fab
        large
        dark
        bottom
        right
        fixed
        @click="setSignEditorSidebar"
      >
        <v-icon v-text="'mdi-palette'" />
      </v-btn>
    </v-main>

    <!-- LOCAL SNACKBAR -->
    <Snackbar
      :show="showSnackbar"
      :message="snackbarText"
      :color="snackbarType"
      @close="showSnackbar = false"
    />

    <!-- GLOBAL SNACKBAR -->
    <Snackbar
      data-testid="snackbar-global"
      :show="isShowingSnackbar"
      :hasKey="snackbarHasMessageKey"
      :message_key="snackbarMessageKey"
      :message="snackbarMessage"
      :timeout="snackbarTimeout"
      :notShowCloseButton="snackbarShowCloseButton"
      :color="snackbarColor"
      @close="resetSnackbarInfo"
    />
  </v-app>
</template>

<script>
import SignEditorNavbar from "@/components/main/dashboard/SignEditorNavbar.vue";
import { links } from "@/helpers/variables/links";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "SignEditorLayout",

  components: {
    SignEditorNavbar,
  },

  data: () => ({
    value: null,
    helpMenu: false,
    loadResellerTerm: false,
    showSnackbar: false,
    snackbarType: "success",
    snackbarText: "",
    bodyWidth: 0,
    links,
  }),

  computed: {
    ...mapGetters([
      "company",
      "currentUser",
      "isSimulation",
      "hasConectaSupport",
      "snackbar",
      "isShowingSnackbar",
      "snackbarTimeout",
      "snackbarHasMessageKey",
      "snackbarMessage",
      "snackbarColor",
      "snackbarShowCloseButton",
      "snackbarMessageKey",
      "loading",
      "plans",
      "appliedSignatureTemplateStatus",
    ]),

    showAppliedBanner: {
      get() {
        return this.appliedSignatureTemplateStatus;
      },
      set(new_value) {
        this.setAppliedSignatureTemplateStatus(new_value);
      },
    },
  },

  methods: {
    ...mapMutations([
      "setSidebarView",
      "setLoading",
      "setSeen",
      "resetSnackbarInfo",
      "setSignEditorSidebar",
      "setAppliedSignatureTemplateStatus",
    ]),

    ...mapActions([
      "getUsers",
      "getPlans",
      "getCustomTemplates",
      "getDefaultTemplates",
      "getContractedPlans",
    ]),

    onResize() {
      this.bodyWidth = this.sidebarMini
        ? window.innerWidth - 230
        : window.innerWidth - 300;
    },
  },

  async beforeMount() {
    this.setLoading(true);

    if (this.$vuetify.breakpoint.smAndDown) {
      this.setSignEditorSidebar(false);
    }

    if (this.plans.length < 1) {
      await Promise.all([this.getPlans(), this.getContractedPlans()]);
    }

    this.setLoading(false);
  },
};
</script>
<style>
.main-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.scrollable-area {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
</style>
