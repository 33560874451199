import { modules } from "@/helpers/variables/modules";
import { findUserById } from "@/helpers/services/getUsers";

export const trackRoutes = [
  {
    path: "/track/users",
    name: "TrackUsers",
    component: () => import("@/views/track/users/Users.vue"),
    meta: {
      requiresAuth: true,
      app: modules.track,
      permission: [modules.track],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Conecta Track",
        },
      ],
    },
  },
  {
    path: "/track/user",
    name: "TrackUser",
    component: () => import("@/views/track/users/User.vue"),
    meta: {
      requiresAuth: true,
      app: modules.track,
      permission: [modules.track],
      breadCrumb(route) {
        const id = route.query.id;
        let user = findUserById(id);
        return [
          {
            text: "Início",
            to: { name: "Home" },
          },
          {
            text: "Conecta Track",
            to: { name: "TrackUsers" },
          },
          {
            text: user.name,
          },
        ];
      },
    },
  },
];
