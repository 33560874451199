<template>
  <v-menu offset-y open-on-hover close-delay="100">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" :class="customClass">
        <v-progress-circular
          :rotate="360"
          :size="40"
          :width="3"
          :value="value"
          color="success"
          class="pulse-mission"
        >
          <v-icon size="23"> ph-trophy </v-icon>
        </v-progress-circular>
      </div>
    </template>
    <v-card
      class="d-flex flex-column justify-center elevation-0 pb-7"
      width="340"
    >
      <v-card-title class="d-flex justify-center flex-column">
        <v-img
          width="150"
          :src="require('@/assets/images/missions_image.png')"
        />
        <div class="d-flex justify-center">
          <span>Suas missões</span>
          <v-divider></v-divider>
        </div>
      </v-card-title>
      <v-card-actions>
        <v-progress-linear
          color="primarySuite lighten-3"
          height="20"
          :value="value"
          rounded
          dark
          class="ml-3 mr-3 mb-4"
        >
          <template v-slot:default="{ value }">
            <strong>{{ value.toFixed(1) }}%</strong>
          </template>
        </v-progress-linear>
      </v-card-actions>
      <v-card-text
        v-for="(mission, index) in userMissions"
        :key="index"
        class="d-flex justify-space-between px-5 pb-5"
      >
        <span>{{ mission.description }}</span>
        <v-chip :color="mission.completed_at ? 'success' : ''" small>{{
          mission.completed_at ? "concluído" : "pendente"
        }}</v-chip>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Missions",
  props: {
    customClass: { type: String, default: "" },
  },
  computed: {
    ...mapGetters(["currentUser"]),

    value() {
      if (!this.userMissions.length) return 0;

      const itemPercentageInArray = 100 / this.userMissions.length;

      const completedMissions = this.userMissions.filter(
        ({ completed_at }) => completed_at
      );

      return itemPercentageInArray * completedMissions.length;
    },
    userMissions() {
      if (!this.currentUser.missions) return [];
      return Object.values(this.currentUser.missions);
    },
  },
};
</script>
<style>
.pulse-mission {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: (--v-error-base);
  cursor: pointer;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.6);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
</style>
