const state = () => ({
  onlineUsersSidebar: false,
  onlineUsers: [],
  userToCall: "",
  attendentCalling: {},
  meetLink: "",
  generateMeetDialog: false,
  meetInviteDialog: false,
  inviteResponse: "",
});

const getters = {
  getInviteResponse: (state) => state.inviteResponse,
  showOnlineUsersSidebar: (state) => state.onlineUsersSidebar,
  showGenerateMeetDialog: (state) => state.generateMeetDialog,
  showMeetInviteDialog: (state) => state.meetInviteDialog,
  onlineUsers: (state) => state.onlineUsers,
  totalOnlineUsers: (state) => state.onlineUsers.length,
  getUserToCall: (state) => state.userToCall,
  getMeetLink: (state) => state.meetLink,
  getAttendentCalling: (state) => state.attendentCalling,
};

// actions
const actions = {};

// mutations
const mutations = {
  setInviteResponse(state, status) {
    state.inviteResponse = status;
  },
  showCloseOnlineUsersSidebar(state) {
    state.onlineUsersSidebar = !state.onlineUsersSidebar;
  },
  setGenerateMeetDialog(state, status) {
    state.generateMeetDialog = status;
  },
  setUserToCall(state, user) {
    state.userToCall = user;
  },
  setMeetInviteDialog(state, show) {
    state.meetInviteDialog = show;
  },
  setConfigMeetInvite(state, config) {
    const { attendentCalling, meetLink, show } = config;

    state.attendentCalling = attendentCalling;
    state.meetLink = meetLink;
    state.meetInviteDialog = show;
  },
  setOnlineUsers(state, payload) {
    state.onlineUsers = payload;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
