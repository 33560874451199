import axios from "axios";

export async function getUsers() {
  let storage = getUsersByLocalStorage();
  if (storage == false) {
    return await getUsersByRequest();
  }
  return storage;
}

export function getUsersByLocalStorage() {
  let usersLocalStorage = localStorage.getItem("users");

  if (usersLocalStorage) {
    let items = JSON.parse(usersLocalStorage);
    return items;
  }

  return false;
}

export async function getUsersByRequest() {
  const token = localStorage.getItem("token");
  let resp = null;
  try {
    const response = await axios.get(
      process.env.VUE_APP_API_BASE_URL + "/users",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    let items = response.data;
    localStorage.setItem("users", JSON.stringify(items));
    resp = items;
  } catch (e) {
    console.log("getUsersByRequest()", e);
    const { response } = e;

    if (
      response.status === 403 &&
      response.data.code === "COMPANY_TRIAL_ENDED"
    ) {
      window.location.href = "/company";
    }
    resp = "error";
  }

  return resp;
}

export function syncUser() {
  const token = localStorage.getItem("token");
  axios
    .get(process.env.VUE_APP_API_BASE_URL + "/users/sync", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let items = response.data;
      localStorage.setItem("users", JSON.stringify(items));
      return items;
    })
    .catch((error) => {
      console.error(error);
      return "error";
    });
}

export async function getUsersStatus() {
  const token = localStorage.getItem("token");
  let resp = null;
  try {
    const response = await axios.get(
      process.env.VUE_APP_API_BASE_URL + "/track/online",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    resp = response.data.data;
  } catch (e) {
    console.log("getUsersStatus()", e);
    const { response } = e;

    if (
      response.status === 403 &&
      response.data.code === "COMPANY_TRIAL_ENDED"
    ) {
      window.location.href = "/company";
    }
    resp = "error";
  }

  return resp;
}

export function findUserById(user_id) {
  const users = getUsersByLocalStorage();
  const user = users.find((element) => element.id_google === user_id);
  return user;
}

export function editUserTagsInLocalStorage(user, newTags) {
  let users = getUsersByLocalStorage();
  users = users.map((element) => {
    if (element.key === user.key) {
      element = { ...element, ...newTags };
    }

    return element;
  });

  localStorage.setItem("users", JSON.stringify(users));
}
