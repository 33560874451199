import { links } from "./links";

export const template = {
  id: "",
  type: "",
  name: "",
  description: "",
  html: "",
};

export const defaultVacationTemplate = {
  name: "",
  description: "",
  response_subject: "",
  content: "",
  is_default: false,
};

// TO_DO: Padronizar no futuro códigos de resposta no backend
export const messages = {
  // Mensagens de sucesso
  success_add_template:
    "Modelo criado com sucesso, verifique-o em seus 'Modelos personalizados'",
  success_edit_template: "As alterações do modelo foram salvas",
  success_delete_template: "O modelo foi excluído com sucesso",
  success_duplicate_template:
    "O modelo foi duplicado, verifique-o em seus 'Modelos personalizados'",
  success_making_default: "O modelo selecionado agora é um modelo padrão",
  success_removing_default:
    "O modelo selecionado foi removido dos Modelos Padrão e agora é um Modelo Personalizado",

  // Mensagens de erro
  error_add_template:
    "Ops! Não foi possível criar este modelo, tente novamente.",
  error_edit_template:
    "Não foi possível salvar as alterações deste template, tente novamente",
  error_delete_template:
    "Não foi possível excluir este modelo, verifique suas permissões de uso dele e tente novamente",
  error_duplicate_template:
    "Não foi possível duplicar este modelo, tente novamente'",
  error_change_default:
    "Não foi possível alterar o tipo de modelo, verifique suas permissões de uso dele e tente novamente",
};

export const footersButtons = {
  meeting: `
    <button style="background-color:#1967D2!important;border:none!important;">
      <a
        href="url_do_meet"
        target="_blank"
        style="
          text-decoration:none!important;
          color:#fff!important;
          display:inline-flex!important;
          align-items: center!important;
          font-weight:600!important;
          padding: 10px 20px!important;
      ">
      <img src="${links.computerScreenIcon}" width="25px" />
      <span style="font-size:16px;padding-left: 10px;">Participe da nossa video-conferência</span>
      </a>
    </button>
    &nbsp;`,

  visit_website: `
    <button style="background-color:#1967D2!important;border:none!important;">
      <a
        href="[*COMPANY_SITE*]"
        target="_blank"
        style="
          text-decoration:none!important;
          color:#fff!important;
          display:inline-flex!important;
          align-items: center!important;
          font-weight:600!important;
          padding: 10px 20px!important;
      ">
      <img src="${links.webWhiteIcon}" width="25px" />
      <span style="font-size:16px;padding-left: 10px;">Visite nosso site</span>
      </a>
    </button>
    &nbsp;`,
  calendar: `
    <button style="background-color:#1967D2!important;border:none!important;">
      <a
        href="scheduling_link"
        target="_blank"
        style="
          text-decoration:none!important;
          color:#fff!important;
          display:inline-flex!important;
          align-items: center!important;
          font-weight:600!important;
          padding: 10px 20px!important;
      ">
      <img src="${links.calendarIcon}" width="25px" />
      <span style="font-size:16px;padding-left: 10px;">Realize um agendamento</span>
      </a>
    </button>
    &nbsp;`,
};

export const rules = {
  templateName: (v) =>
    (!!v && v.length > 5 && v.length <= 30) ||
    "Mínimo de 5 caracteres e máximo de 30 caracteres",
  templateDescription: (v) =>
    (typeof v === "string" && v.length <= 60) || "Máximo de 60 caracteres",
};

export const preTemplates = {
  left: `
    <table style="border-collapse: collapse; max-width: 800px;" border="0" id="signature-body">
      <tbody>
        <tr>
          <td style="width: 200px; padding:0px 35px 0px 0px">
            <img class=""
              style="border-radius: 100%; display: block; margin-left: auto; margin-right: auto;"
              src="${links.profileImage}"
              alt="[*USER_PHOTO*]"
              width="161" 
              height="161"
            />
          </td>
          <td style="width: 546.391px;">
            <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px;">
              <strong>[*USER_FULL_NAME*]</strong>
              <br /><span style="color: #999999;font-size: 14px;font-weight:600;">[*USER_POSITION*]</span>
              <br /><span style="color: #999999;font-size: 14px;">[*COMPANY_PHONE1*] | [*USER_PHONE*]</span>
            </p>

            <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 14px; line-height: 30px;">
              <a href="[*COMPANY_SITE*]" target="_blank">[*COMPANY_SITE*]</a>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
  `,
  right: `
    <table style="border-collapse: collapse; max-width: 800px;" border="0" id="signature-body">
      <tbody>
        <tr>
          <td style="width: 546.391px;">
            <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px;">
              <strong>[*USER_FULL_NAME*]</strong>
              <br /><span style="color: #999999;font-size: 14px;font-weight: 600;">[*USER_POSITION*]</span>
              <br /><span style="color: #999999;font-size: 14px;">[*COMPANY_PHONE1*] | [*USER_PHONE*]</span>
            </p>

            <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 14px; line-height: 30px;">
            <a href="[*COMPANY_SITE*]" target="_blank">[*COMPANY_SITE*]</a>
            </p>
          </td>
          <td style="width: 200px; padding:0px 0px 0px 35px">
            <img
              class=""
              style="border-radius: 100%; display: block; margin-left: auto; margin-right: auto;"
              src="${links.profileImage}"
              alt="[*USER_PHOTO*]"
              width="161" 
              height="161"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
  `,
  center: `
    <table style="border-collapse: collapse; max-width: 800px;" border="0" id="signature-body">
      <tbody>
        <tr>
          <td style="width: 546.391px; text-align:center;">
            <img
              class=""
              style="border-radius: 100%; display: block; margin-left: auto; margin-right: auto;"
              src="${links.profileImage}"
              alt="[*USER_PHOTO*]"
              width="161" 
              height="161"
            />
            
            <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px;">
              <strong>[*USER_FULL_NAME*]</strong>
              <br /><span style="color: #999999;font-size: 14px;font-weight:600;">[*USER_POSITION*]</span>
              <br /><span style="color: #999999;font-size: 14px;">[*COMPANY_PHONE1*] | [*USER_PHONE*]</span>
            </p>
            
            <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 14px; line-height: 30px;">
              <a href="[*COMPANY_SITE*]" target="_blank">[*COMPANY_SITE*]</a>
            </p>
          </td>
        </tr>
        <tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
  `,
  logoLeft: `
    <table id="signature-body" style="border-collapse: collapse; max-width: 800px; width: 70.9567%;" border="0">
    <tbody>
    <tr>
    <td style="width: 31.7721%; padding: 0px 35px 0px 0px;">
    <div style="display: unset;"><img class="" src="${links.logoImage}" alt="[*COMPANY_LOGO_URL*]" width="218" height="64" /></div>
    &nbsp;</td>
    <td style="width: 1.62613%;">
    <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px;"><strong>&nbsp;</strong></p>
    </td>
    <td style="width: 66.5462%;">
    <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px;"><strong>[*USER_FULL_NAME*]</strong> <br /><span style="color: #999999; font-size: 14px; font-weight: 600;">[*USER_POSITION*]</span> <br /><span style="color: #999999; font-size: 14px;">[*COMPANY_PHONE1*]</span></p>
    <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 14px; line-height: 30px;"><a href="[*COMPANY_SITE*]" target="_blank" rel="noopener">[*COMPANY_SITE*]</a></p>
    </td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
  `,
  logoBottom: `
    <table id="signature-body" style="max-width: 800px; width: 70.9567%;" border="0">
    <tbody>
    <tr>
    <td style="width: 99.9444%;">
    <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px; text-align: left;"><strong>[*USER_FULL_NAME*]</strong> <br /><span style="color: #999999; font-size: 14px; font-weight: 600;">[*USER_POSITION*]</span> <br /><span style="color: #999999; font-size: 14px;">[*COMPANY_PHONE1*]</span></p>
    <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; line-height: 30px; text-align: left;"><a href="[*COMPANY_SITE*]" target="_blank" rel="noopener">[*COMPANY_SITE*]</a></p>
    </td>
    </tr>
    <tr>
    <td style="width: 99.9444%;">
    <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px; text-align: left;"><img class="" style="float: left;" src="${links.logoImage}" alt="[*COMPANY_LOGO_URL*]" width="218" height="64" /></p>
    </td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
  `,
  profileLogoBottom: `
    <table id="signature-body" style="max-width: 800px; width: 70.9567%;" border="0">
    <tbody>
    <tr>
    <td style="width: 31.7724%; padding: 0px 35px 0px 0px;">
    <div style="display: unset;"><img class="" style="border-radius: 100%; display: block; margin-left: auto; margin-right: auto;" src="${links.profileImage}" alt="[*USER_PHOTO*]" width="161" height="161" /></div>
    </td>
    <td style="width: 2.37668%;">
    <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px; text-align: left;"><strong>&nbsp;</strong></p>
    </td>
    <td style="width: 65.9215%;">
    <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px; text-align: left;"><strong>[*USER_FULL_NAME*]</strong> <br /><span style="color: #999999; font-size: 14px; font-weight: 600;">[*USER_POSITION*]</span> <br /><span style="color: #999999; font-size: 14px;">[*COMPANY_PHONE1*]</span></p>
    <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; line-height: 30px; text-align: left;"><a href="[*COMPANY_SITE*]" target="_blank" rel="noopener">[*COMPANY_SITE*]</a></p>
    </td>
    </tr>
    <tr>
    <td style="width: 31.7724%; padding: 0px 35px 0px 0px;">
    <div style="display: unset;">&nbsp;</div>
    <div style="display: unset;"><img class="" style="display: block; margin-left: auto; margin-right: auto;" src="${links.logoImage}" alt="[*COMPANY_LOGO_URL*]" width="218" height="64" /></div>
    </td>
    <td style="width: 2.37668%;">
    <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px; text-align: left;"><strong>&nbsp;</strong></p>
    </td>
    <td style="width: 65.9215%;">
    <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px; text-align: left;"><strong>&nbsp;</strong></p>
    </td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
  `,
};

export const preTemplatesDragAndDrop = {
  left: `
    <table style="border-collapse:collapse;max-width:800px;width:100%" border="0" id="signature-body">
      <tbody>
        <tr>
          <td style="width: 200px; padding:0px 35px 0px 0px" id="image">
            <img class=""
              style="border-radius: 100%; display: block; margin-left: auto; margin-right: auto;"
              src="${links.profileImage}"
              alt="[*USER_PHOTO*]"
              width="161" 
              height="161"
            />

            <slot name="image" />
          </td>
          <td style="width: 546.391px;" id="content">
            <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px;">
              <strong>[*USER_FULL_NAME*]</strong>
              <br /><span style="color: #999999;font-size: 14px;font-weight:600;">[*USER_POSITION*]</span>
              <br /><span style="color: #999999;font-size: 14px;">[*COMPANY_PHONE1*] | [*USER_PHONE*]</span>
            </p>

            <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 14px; line-height: 30px;">
              <a href="[*COMPANY_SITE*]" target="_blank">[*COMPANY_SITE*]</a>
            </p>

            <slot name="content" />
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
  `,
  right: `
    <table style="border-collapse: collapse; max-width: 800px;" border="0" id="signature-body">
      <tbody>
        <tr>
          <td style="width: 546.391px;" id="content">
            <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px;">
              <strong>[*USER_FULL_NAME*]</strong>
              <br /><span style="color: #999999;font-size: 14px;font-weight: 600;">[*USER_POSITION*]</span>
              <br /><span style="color: #999999;font-size: 14px;">[*COMPANY_PHONE1*] | [*USER_PHONE*]</span>
            </p>

            <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 14px; line-height: 30px;">
            <a href="[*COMPANY_SITE*]" target="_blank">[*COMPANY_SITE*]</a>
            </p>

            <slot name="content" />
          </td>
          <td style="width: 200px; padding:0px 0px 0px 35px" id="image">
            <img
              class=""
              style="border-radius: 100%; display: block; margin-left: auto; margin-right: auto;"
              src="${links.profileImage}"
              alt="[*USER_PHOTO*]"
              width="161" 
              height="161"
            />

            <slot name="image" />
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
  `,
  center: `
    <table style="border-collapse: collapse; max-width: 800px;" border="0" id="signature-body">
      <tbody>
        <tr>
            <td style="width: 546.391px; text-align:center;" id="image">
              <img
                class=""
                style="border-radius: 100%; display: block; margin-left: auto; margin-right: auto;"
                src="${links.profileImage}"
                alt="[*USER_PHOTO*]"
                width="161" 
                height="161"
              />

              <slot name="image" />
            </td>
        </tr>
        <tr>
            <td style="width: 546.391px; text-align:center;" id="content">
              
              <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 20px; line-height: 30px;">
                <strong>[*USER_FULL_NAME*]</strong>
                <br /><span style="color: #999999;font-size: 14px;font-weight:600;">[*USER_POSITION*]</span>
                <br /><span style="color: #999999;font-size: 14px;">[*COMPANY_PHONE1*] | [*USER_PHONE*]</span>
              </p>
              
              <p style="color: #222222; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 14px; line-height: 30px;">
                <a href="[*COMPANY_SITE*]" target="_blank">[*COMPANY_SITE*]</a>
              </p>

              <slot name="content" />
            </td>
          </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
  `,
};

export const defaultTemplates = [
  {
    name: "Fundo",
    html: `
      <p style="font-family:Arial;">Abraços,</p>
      <table style="border-collapse: collapse; position: relative; font-family: Arial; box-sizing: border-box;" border="0" width="800">
        <tbody style="background: linear-gradient(180deg, #ddd 70%, #334963 30%);">
          <tr style="padding: 0px;">
            <td style="padding: 10px 20px;" colspan="2" width="20%">
              <img class="" src="[*USER_PHOTO*]" alt="[*USER_PHOTO*]" width="200" />
            </td>
            <td style="padding: 0px 20px; color: #1c1c1c; font-family: Arial;" width="60%">
              <h1 style="color: #45668e;">[*USER_FULL_NAME*]</h1>
              <h3>[*USER_POSITION*]</h3>
              [*USER_EMAIL*]
            </td>
            </tr>
            <tr>
            <td style="padding: 15px 20px;" colspan="2">
              <a><img class="" style="background-color: #45668e; padding: 4px; margin-right: 19px;" src="${links.facebookIcon}" width="28" /></a>
              <a><img class="" style="background-color: #45668e; padding: 4px; margin-right: 19px;" src="${links.instagramIcon}" width="28" /></a>
              <a><img class="" style="background-color: #45668e; padding: 4px; margin-right: 19px;" src="${links.twitterIcon}" width="28" /></a>
              <a><img class="" style="background-color: #45668e; padding: 4px;" src="${links.linkedInIcon}" width="28" /></a>
              </td>
            <td style="padding-right: 20px;" width="20%">
              <img class="" style="float: right;" src="[*COMPANY_LOGO_URL*]" alt="[*COMPANY_LOGO_URL*]" width="200" />
            </td>
          </tr>
        </tbody>
      </table>
    `,
    html_preview: `
      <p style="font-family:Arial;">Abraços,</p>
      <table style="border-collapse:collapse;position:relative;font-family:Arial;box-sizing:border-box;margin-top:7px" border="0" width="200">
        <tbody style="background: linear-gradient(180deg, #ddd 70%, #334963 30%);">
          <tr style="padding:0px;">
            <td style="padding: 10px;" colspan="2" width="20%">
              <img class="" src="${links.profileImage}" alt="[*USER_PHOTO*]" width="100" />
            </td>
            <td style="padding: 0px 10px; color: #1c1c1c; font-family: Arial;" width="60%">
              <h2 style="color: #45668e;">Nome do usuário</h2>
              <h4>Cargo do usuário</h4>
              <h5 style="font-weight:400;margin-top:5px">usuario@empresa.com</h5>
            </td>
            </tr>
            <tr>
            <td style="padding:5px 10px;" colspan="2">
              <a><img style="background-color: #45668e; padding: 4px; margin-right: 2px;" src="${links.facebookIcon}" width="22" /></a>
              <a><img style="background-color: #45668e; padding: 4px; margin-right: 2px;" src="${links.instagramIcon}" width="22" /></a>
              <a><img style="background-color: #45668e; padding: 4px; margin-right: 2px;" src="${links.twitterIcon}" width="22" /></a>
              <a><img style="background-color: #45668e; padding: 4px;" src="${links.linkedInIcon}" width="22" /></a>
              </td>
            <td style="padding-right:20px;" width="20%">
              <img class="" style="float: right;" src="${links.logoImage}" alt="[*COMPANY_LOGO_URL*]" width="100" />
            </td>
          </tr>
        </tbody>
      </table>
    `,
  },
];

export const wartermarkerTemplate = `
<table style="width: 800px; border: 0;" id="watermark">
  <tbody>
    <tr>
      <td style="width: 24px; vertical-align: middle;">
        <a style="text-decoration: none;" href="https://conectasuite.com/" target="_blank" rel="noopener">
          <img src="https://bit.ly/4cdD6hG" alt="" width="24" height="21" />
        </a>
      </td>
      <td style="width: 747.438px; vertical-align: middle;">
        <a style="text-decoration: none;" href="https://conectasuite.com/" target="_blank" rel="noopener">
          <span style="font-family: verdana, geneva, sans-serif; font-size: 16px; color: #808181;">Crie sua própria</span>
          <span style="color: #311f50;">assinatura</span>
        </a>
      </td>
    </tr>
  </tbody>
</table>
`;
