<template>
  <v-menu
    open-on-hover
    :bordered="false"
    bottom
    offset-y
    content-class="elevation-0"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot name="activator" />
      </span>
    </template>

    <div
      :class="`d-flex flex-column ${
        center ? 'align-center' : 'align-start'
      } elevation-0`"
    >
      <div :class="`${center ? 'arrow-up-center' : 'arrow-up ml-3'}`" />
      <v-card class="" width="300" outlined>
        <v-app-bar dense flat class="border-bottom-0" :color="primaryColor">
          <v-app-bar-title class="text-subtitle-1 font-weight-medium">
            {{ title }}
          </v-app-bar-title>
          <v-spacer />

          <v-tooltip v-if="icon" bottom max-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon v-text="icon" />
              </v-btn>
            </template>
            <span v-if="iconTooltip"> {{ iconTooltip }} </span>
          </v-tooltip>
        </v-app-bar>
        <v-card-text>
          <slot />
        </v-card-text>
      </v-card>
    </div>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Popover",

  props: {
    title: { type: String, default: "" },
    icon: { type: String, default: "" },
    iconTooltip: { type: String, default: "" },
    center: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters(["primaryColor"]),
  },
};
</script>
<style>
.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--v-primary-base);
}
.arrow-up-center {
  width: 0;
  height: 0;
  right: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--v-primary-base);
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--v-primary-base);
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--v-primary-base);
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--v-primary-base);
}
</style>
