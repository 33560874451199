<template>
  <v-snackbar
    v-model="showSnackbar"
    :timeout="notTimeout ? -1 : timeout"
    :color="color"
    class="mb-10"
    :top="top"
    :centered="center"
    :right="right"
    :bottom="bottom"
    multi-line
  >
    <v-row align="start" class="pa-0 ma-0">
      <v-col v-if="iconType" cols="1" class="">
        <v-icon v-text="iconType" dense size="24" />
      </v-col>
      <v-col
        :class="`${
          iconType ? 'pl-4' : ''
        } text-body-2 text-sm-body-1 font-weight-medium`"
        style="padding-top: 10px"
      >
        <span v-if="hasKey" v-html="snackbarkMessage" :class="textStyle"></span>
        <span v-else :class="textStyle" v-html="message"></span>
      </v-col>
    </v-row>

    <template v-if="!notShowCloseButton" v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="close()"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { snackbarMessages } from "@/helpers/variables/snackbarMessages";
export default {
  props: {
    show: { type: Boolean, required: true },
    message: { type: String, default: "" },
    color: { type: String, default: "success" },
    timeout: { type: [String, Number], default: "8000" },
    top: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    bottom: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    notShowCloseButton: { type: Boolean, default: false },
    notTimeout: { type: Boolean, default: false },
    textStyle: { type: String, default: "font-weight-regular" },
    hasKey: { type: Boolean, default: false },
    message_key: { type: String, default: "saves_changes" },
  },
  name: "Snackbar",

  data: () => ({
    snackbarMessages,
    icons: {
      success: "mdi-check-circle",
      error: "mdi-close-circle",
      info: "mdi-information",
      warning: "mdi-alert-circle",
    },
  }),

  computed: {
    showSnackbar: {
      get() {
        return this.show;
      },
      set(new_value) {
        this.$emit("show", !!new_value);
      },
    },

    snackbarkMessage() {
      return (
        this.snackbarMessages[this.message_key] ||
        this.snackbarMessages["unknown"]
      );
    },

    iconType() {
      return this.icons[this.color] || false;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  watch: {
    showSnackbar() {
      !this.notTimeout && setTimeout(this.close, this.timeout);
    },
  },
};
</script>
