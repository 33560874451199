// Import all variables from en, ptBR and es

import en from "./en";
import es from "./es";
import ptBR from "./ptBR";

export default {
  en,
  es,
  "pt-br": ptBR,
};
