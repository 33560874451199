const state = () => ({
  domain_group: {},
  shifts: [],
  usersNotInShift: [],
  usersInShiftPercentage: 0,
  controlUsers: [],
  usersInActiveVacation: [],
});

const getters = {
  domainGroup: (state) => state.domain_group,
  controlShifts: (state) => state.shifts,
  usersNotInShift: (state) => state.usersNotInShift,
  usersInShiftPercentage: (state) => state.usersInShiftPercentage,
  controlUsers: (state) => state.controlUsers,
  usersInActiveVacation: (state) => state.usersInActiveVacation,
};

// actions
const actions = {
  async getControlCompany({ commit }) {
    const url = `${process.env.VUE_APP_CONECTA_CONTROL_API_BASE_URL}/integrations/company`;

    const config = {
      headers: { Authorization: this._vm.token },
    };

    await this._vm.$axios
      .get(url, config)
      .then(({ data }) => {
        commit("setControlCompany", data);
      })
      .catch((error) => {
        commit("setSnackBar", config);
        console.error("getControlCompany(): ", error);
      });
  },

  async getControlShifts() {
    const url = `${process.env.VUE_APP_CONECTA_CONTROL_API_BASE_URL}/integrations/shifts`;

    const config = {
      headers: { Authorization: this._vm.token },
    };

    return await this._vm.$axios.get(url, config);
  },

  async addUserToShifts(_, payload) {
    const url = `${process.env.VUE_APP_CONECTA_CONTROL_API_BASE_URL}/integrations/shifts/add-users-in-batch`;
    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    return await this._vm.$axios.put(url, payload, auth);
  },

  async getControlUsersMetrics({ commit }) {
    const url = `${process.env.VUE_APP_CONECTA_CONTROL_API_BASE_URL}/integrations/suite/users-metrics`;

    const config = {
      headers: { Authorization: this._vm.token },
    };

    await this._vm.$axios
      .get(url, config)
      .then(({ data }) => {
        commit("setUsersMetrics", data);
      })
      .catch((error) => {
        console.log("getControlUsersMetrics", error);
      });
  },
};

// mutations
const mutations = {
  setControlCompany(state, data) {
    state.domain_group = data.company;
  },
  setControlShifts(state, data) {
    const handle_obeys_ponto_mais_rules = (data) => {
      data.pontomais_login_rule = !!(
        data.pontomais_login_rule ||
        (data.obeys_ponto_mais_rules && data.advanced_auto_logout)
      );
      data.pontomais_auto_logout_rules =
        data.obeys_ponto_mais_rules && data.advanced_auto_logout
          ? [0]
          : data.pontomais_auto_logout_rules;
      return data;
    };

    data = data.map(handle_obeys_ponto_mais_rules);
    state.shifts = data;
  },
  setUsersMetrics(state, data) {
    state.controlUsers = data.control_users;
    state.usersInShiftPercentage = data.users_in_shift_percentage;
    state.usersNotInShift = data.users_not_in_shifts;
    state.usersInActiveVacation = data.users_in_active_vacation;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
