<template>
  <v-app>
    <v-main class="grey lighten-4">
      <div class="d-flex justify-center">
        <v-card elevation="0" class="pa-4 justify-center" max-width="900">
          <v-card-title class="h5 pb-1">
            <v-icon left large color="warning">mdi-alert</v-icon>
            Conta suspensa: {{ mainDomain }}
          </v-card-title>

          <p class="pl-4">
            Prezado cliente, <br />
            Seu acesso está temporariamente suspenso devido a um atraso no
            processamento do pagamento do seu plano.
          </p>

          <v-divider></v-divider>

          <v-card-title class="h6 font-weight-medium mt-2 pb-2"
            >Detalhes do Plano</v-card-title
          >
          <p class="mb-1">
            <span class="font-weight-medium pl-4">Plano:</span>
            {{ planName }}
          </p>

          <p>
            <span class="font-weight-medium pl-4">Plano de pagamento:</span>
            {{ subscriptionType }}
          </p>

          <v-card-title class="h6 font-weight-medium pb-1"
            >Entre em contato</v-card-title
          >
          <p class="font-weight-regular pl-4">
            Entre em contato conosco para resolver problemas de pagamento:
          </p>

          <p class="mb-1">
            <span class="font-weight-medium pl-4">Email:</span>
            {{ emails.financial }}
          </p>
          <p>
            <span class="font-weight-medium pl-4">Telefone:</span>

            {{ telephone }}
          </p>

          <p class="pl-4 pt-4">
            Para conferir as suas cobranças, acesse a sua
            <strong>
              <a
                class="accent--text font-weight-bold"
                :href="links.superlogicaClientPage"
                target="_blank"
              >
                Área do cliente</a
              ></strong
            >
            no Superlogica.
          </p>

          <span class="pl-4 mt-6 text-caption">
            * Se você já realizou o pagamento e acredita que tenha ocorrido
            algum engano, entre em contato com financeiro@conectasuite.com
          </span>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { links } from "@/helpers/variables/links";
import { emails, telephone } from "@/helpers/variables/contactConecta";
import { mapGetters } from "vuex";
import { planPeriodic } from "@/helpers/variables/translateString";
export default {
  name: "Suspended",
  data() {
    return {
      planPeriodic,
      links,
      emails,
      telephone,
    };
  },

  computed: {
    ...mapGetters(["company", "mainDomain"]),

    planName() {
      return this.company.current_plan.plan.name;
    },

    subscriptionType() {
      return this.planPeriodic[this.company.current_plan.subscription_type];
    },
  },
};
</script>
