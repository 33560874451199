<template>
  <v-card
    :loading="loading"
    :disabled="loading"
    width="380"
    max-height="100%"
    class="py-1"
    :height="loading ? '350px' : ''"
  >
    <v-card-text
      class="d-flex flex-column justify-center align-center"
      style="text-align: center"
      v-if="!hasTasksOrProcesses && !loading"
    >
      <v-card-title> Você não tem tarefas </v-card-title>
      <v-card-subtitle>
        Acompanhe o progresso das suas tarefas dos ultimos 15 dias.
        Certifique-se de que as atividades importantes que você iniciou estão em
        andamento, concluídas ou canceladas.
      </v-card-subtitle>
    </v-card-text>
    <v-card-text v-else-if="!loading">
      <v-list>
        <v-list-item v-for="(process, index) in recentProcesses" :key="index">
          <v-list-item-icon class="mt-8">
            <v-progress-circular
              v-if="isProcessRunning(process)"
              color="primary"
              indeterminate
              :size="25"
              :width="2"
            ></v-progress-circular>
            <v-icon
              v-else-if="process.status === 'toDo'"
              color="warning"
              :size="25"
            >
              mdi-pause-circle-outline
            </v-icon>
            <v-icon
              v-else-if="process.status === 'done'"
              color="success"
              :size="25"
            >
              mdi-check-circle-outline
            </v-icon>
            <v-icon v-else color="error" :size="25">
              mdi-alert-circle-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mb-2 font-weight-bold">
              {{ $t(processStatus(process)) }}
            </v-list-item-title>
            <v-list-item-subtitle
              style="font-weight: normal; color: black; white-space: normal"
            >
              {{ getProcessTitle(process) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              style="font-size: 13px; white-space: normal; margin-bottom: 0px"
            >
              {{ getProgressDescription(process) }}
              <v-hover v-slot="{ hover }">
                <v-list-item-action class="ml-0 mt-1 mb-0">
                  <a @click="toggleDetails(process)">
                    <v-list-item-action-text>
                      <span :class="hover && 'text-decoration-underline'">{{
                        tasksShowDetails[process.key]
                          ? "Ocultar detalhes"
                          : "Mostrar detalhes"
                      }}</span>
                      <v-icon v-if="hover" size="19" style="height: 10px">{{
                        tasksShowDetails[process.key]
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      }}</v-icon>
                    </v-list-item-action-text>
                  </a>
                </v-list-item-action>
              </v-hover>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="tasksShowDetails[process.key]"
              style="font-size: 13px; white-space: normal"
              class="mt-1 d-flex flex-column"
            >
              <span class="mb-1">
                <b>Início: </b>{{ formatDateAndHour(process.createdAt, true) }}
              </span>
              <span class="mb-1" v-if="process.modifiedAt">
                <b>Última modificação: </b
                >{{ formatDateAndHour(process.modifiedAt, true) }}
              </span>
              <span
                v-for="(item, index) in getDriveMigrationStatistics(process)"
                :key="`process_statistics_${index}`"
                class="mb-1"
              >
                <b>{{ $t(item.tipKey) }}: </b>
                {{ item.content }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="isProcessRunning(process)" icon>
              <v-icon color="accent" @click="handleLeaveProcess(process)">
                mdi-pause-circle-outline
              </v-icon>
            </v-btn>
            <v-btn v-else-if="process.status === 'toDo'" icon>
              <v-icon
                color="accent"
                :disabled="loadingDriveMigration"
                @click="handleStartProcess(process)"
              >
                mdi-play-circle-outline
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-for="task in tasks" :key="task.process_id">
          <v-list-item-icon class="mt-8">
            <v-progress-circular
              v-if="task.status === 'RUNNING'"
              color="primary"
              indeterminate
              :size="25"
              :width="2"
            ></v-progress-circular>
            <v-icon
              v-else-if="task.status === 'FINISHED'"
              color="success"
              :size="25"
            >
              mdi-check-circle-outline
            </v-icon>
            <v-icon v-else color="error" :size="25">
              mdi-alert-circle-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mb-2 font-weight-bold">
              {{ $t(taskStatus[task.status]) }}
            </v-list-item-title>
            <v-list-item-subtitle
              style="font-weight: normal; color: black; white-space: normal"
            >
              {{ task.event_name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              style="font-size: 13px; white-space: normal; margin-bottom: 0px"
            >
              {{ getProgressDescription(task) }}
              <v-hover v-slot="{ hover }">
                <v-list-item-action class="ml-0 mt-1 mb-0">
                  <a @click="toggleDetails(task)">
                    <v-list-item-action-text>
                      <span :class="hover && 'text-decoration-underline'">{{
                        tasksShowDetails[task.process_id]
                          ? "Ocultar detalhes"
                          : "Mostrar detalhes"
                      }}</span>
                      <v-icon v-if="hover" size="19" style="height: 10px">{{
                        tasksShowDetails[task.process_id]
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      }}</v-icon>
                    </v-list-item-action-text>
                  </a>
                </v-list-item-action>
              </v-hover>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="tasksShowDetails[task.process_id]"
              style="font-size: 13px; white-space: normal"
              class="mt-1 d-flex flex-column"
            >
              <span class="mb-1">
                <b>Início: </b>{{ formatDateAndHour(task.started_at, true) }}
              </span>
              <span class="mb-1" v-if="task.finished_at">
                <b>Fim: </b>{{ formatDateAndHour(task.finished_at, true) }}
              </span>
              <span class="mb-1">
                <b>Descrição:</b>
                {{ task.description }}
              </span>
              <span class="mb-1" v-if="task.total_messages_size">
                <b>Tamanho migrado:</b>
                {{ convertBytesInMegas(task.total_messages_size) }} Mb
              </span>
              <span v-if="!!task.total_failures">
                <b>Falhas:</b> {{ task.total_failures }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  formatDateAndHour,
  convertBytesInMegas,
} from "@/helpers/services/utils";
import {
  getDriveMigrationStatistics,
  containsText,
} from "@/helpers/services/utils";
import { DISCOVERY_DOCS } from "@/helpers/variables/discoveryDocs";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";
import { getDatabase, ref, onValue, off } from "firebase/database";

export default {
  name: "Tasks",
  data: () => ({
    loading: false,
    taskStatus: {
      RUNNING: "common.running",
      FINISHED: "common.finished",
      ERROR: "common.closed",
    },
    interval: null,
    tasksShowDetails: {},
    intervalSeconds: 10000,
    maxRequests: 30,
    countRequest: 0,
    allTasks: [],
  }),
  computed: {
    ...mapGetters([
      "token",
      "allDriveProcesses",
      "runningDriveProcessPath",
      "loadingDriveMigration",
      // "companyEmailTransfers",
      "loadingEmailMigration",
      "driveProcessesPath",
      "emailTransferInProgressPath",
      "currentUser",
      "company",
      "hasProcessRunning",
    ]),

    hasTasks() {
      return !!this.tasks.length;
    },

    hasProcesses() {
      return !!Object.keys({
        ...this.allDriveProcesses,
        // ...this.companyEmailTransfers,
      }).length;
    },

    hasTasksOrProcesses() {
      return this.hasTasks || this.hasProcesses;
    },

    recentProcesses() {
      return Object.values({
        ...this.allDriveProcesses,
        // ...this.companyEmailTransfers,
      }).filter(
        (process) =>
          new Date().getTime() - process.createdAt < 1000 * 60 * 60 * 24 * 7
      );
    },

    isProcessRunning() {
      return (process) =>
        [this.emailTransferInProgressPath].includes(process.key)
          ? true
          : process?.status === "running";
    },

    processStatus() {
      const statuses = {
        toDo: "common.unfinished",
        finished: "common.finished",
        failed: "common.failed",
        done: "common.finished",
      };

      return (process) =>
        [this.emailTransferInProgressPath].includes(process.key)
          ? "common.running"
          : statuses[process.status];
    },

    tasks: {
      get() {
        return this.allTasks;
      },
      set(tasks) {
        this.allTasks = tasks.sort((a, b) => {
          if (a.status === "RUNNING" && b.status !== "RUNNING") return -1;
          if (a.status !== "RUNNING" && b.status === "RUNNING") return 1;
          if (a.started_at > b.started_at) return -1;
          if (a.started_at < b.started_at) return 1;
          return 0;
        });
      },
    },
  },

  methods: {
    getDriveMigrationStatistics,
    ...mapMutations({
      setShowTasksProgress: "setShowTasksProgress",
      // startEmailProcess: "startEmailProcess",
      setMigrateEmailUser: "set.migrateEmailUser",
      setMigrateEmailGroup: "set.migrateEmailGroup",
    }),
    ...mapActions([
      "loadGAPIScript",
      "getDriveTransferences",
      "startDriveTransference",
      "leaveDriveProcessRoom",
      // "getCompanyEmailTransfers",
      // "startMailTransferToGroup",
    ]),
    formatDateAndHour,
    convertBytesInMegas,

    getProcessTitle(process) {
      if (containsText(process.key, this.driveProcessesPath)) {
        return "Transferência de arquivos";
      } else {
        return "Migração de e-mails";
      }
    },

    handleLeaveProcess(process) {
      if (process.key === this.runningDriveProcessPath) {
        this.leaveDriveProcessRoom({ status: "toDo" });
      } else if (process.key === this.emailTransferInProgressPath) {
        this.setMigrateEmailUser(null);
        this.setMigrateEmailGroup(null);
      }
    },

    async getTasks(loading = true) {
      this.loading = loading;

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/tasks`;

      const auth = {
        headers: { Authorization: this.token },
      };

      await this.$axios
        .get(url, auth)
        .then(({ data }) => {
          this.tasks = data;
        })
        .catch((error) => {
          const { data } = error.response;
          console.error("updateError()", data);
          this.setSnackBar({
            message: data.message,
            show: true,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },

    getProgressDescription(item) {
      switch (item.event_code) {
        case "migrate_emails":
          return item.total_items_length
            ? `${item.completed_items || 0} de ${
                item.total_items_length
              } e-mails migrados`
            : "Preparando para iniciar a migração";
      }
      switch (item.status) {
        case "toDo":
          return `${item.transfered + item.failed} de ${
            item.totalFiles
          } arquivos transferidos`;
      }
    },

    toggleDetails(task) {
      this.tasksShowDetails[task.process_id || task.key] =
        !this.tasksShowDetails[task.process_id || task.key];

      this.$forceUpdate();
    },

    async handleInterval() {
      this.countRequest += 1;

      if (this.countRequest >= this.maxRequests) {
        this.setShowTasksProgress(false);
      }
    },

    startDriveProcess(process) {
      const payload = { group: process.group, user: process.user };
      this.startDriveTransference(payload);
    },

    handleStartProcess(process) {
      if (containsText(process.key, this.driveProcessesPath)) {
        this.startDriveProcess(process);
      } else {
        // this.startEmailProcess(process);
        // this.startMailTransferToGroup();
      }
    },

    enterProcessesRoom() {
      const db = getDatabase();
      const processesRef = ref(
        db,
        `task_processes/${this.company.key}/${this.currentUser.id_google}`
      );

      onValue(processesRef, (args) => {
        this.tasks = Object.values(args.val() || {});
      });
    },

    exitProcessesRoom() {
      const db = getDatabase();
      const processesRef = ref(
        db,
        `task_processes/${this.company.key}/${this.currentUser.id_google}`
      );

      off(processesRef);
    },
  },
  async beforeMount() {
    await this.loadGAPIScript({
      scopes: [
        INCREMENTAL_SCOPES.ADMIN_GROUP_MIGRATION,
        INCREMENTAL_SCOPES.GMAIL_READONLY,
        INCREMENTAL_SCOPES.ADMIN_DRIVE,
      ],
      discoveryDocs: [
        DISCOVERY_DOCS.GMAIL,
        DISCOVERY_DOCS.GROUPS_MIGRATION,
        DISCOVERY_DOCS.DRIVE,
      ],
    });

    this.loading = true;

    await this.getDriveTransferences();
    // await this.getCompanyEmailTransfers();

    this.loading = false;

    this.enterProcessesRoom();
  },

  destroyed() {
    this.exitProcessesRoom();

    clearInterval(this.interval);
  },
};
</script>
