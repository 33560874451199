import { modules } from "@/helpers/variables/modules";

export const generalRoutes = [
  /*  PÁGINAS DE AUTENTICAÇÃO */
  {
    path: "/auth",
    name: "Auth",
    component: () => import("@/views/auth/Auth.vue"),
    meta: { app: modules.suite },
  },
  {
    path: "/auth/callback",
    name: "AuthCallback",
    component: () => import("@/views/auth/Callback.vue"),
    meta: { app: modules.suite },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login.vue"),
    meta: { app: modules.suite },
  },
  {
    path: "/new-login",
    name: "NewLogin",
    component: () => import("@/views/auth/NewLogin.vue"),
    meta: { app: modules.suite },
  },

  /* PÁGINAS DE ERRO */
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/errors/PageNotFound.vue"),
    meta: { app: modules.suite },
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("@/views/errors/PageError.vue"),
    meta: { app: modules.suite },
  },
  {
    path: "/disabled",
    name: "Disabled",
    component: () => import("@/views/errors/PageDisabled.vue"),
    meta: { app: modules.suite },
  },

  /* PÁGINA DE CONFIRMAÇÃO DE APLICAÇÃO DE ASSINATURA */
  {
    path: "/user-confirmation/:id?",
    name: "UserConfirmation",
    component: () =>
      import("@/views/user_confirmation/ConfirmApliedTemplate.vue"),
    meta: {
      app: modules.sign,
    },
  },
  /* PÁGINA DE ATUALIZAÇÃO DE TAGS DO USUÁRIO */
  {
    path: "/user-tags-confirmation/:id?",
    name: "UserTagsConfirmation",
    component: () => import("@/views/user_confirmation/ConfirmUpdateTags.vue"),
    meta: {
      app: modules.sign,
    },
  },
  {
    path: "/user-update-and-apply/:id?",
    name: "ConfirmUpdateAndApply",
    component: () =>
      import("@/views/user_confirmation/ConfirmUpdateAndApply.vue"),
    meta: {
      app: modules.sign,
    },
  },
  {
    path: "/external/billing/add-plan/:plan_sku?",
    redirect: "/billing/upgrade/:plan_sku?",
    name: "ExtBillingAddPlan",
  },
  {
    path: "/external/billing/upgrade/:plan_sku?",
    name: "ExtBillingUpgradePlan",
    component: () => import("@/views/billing/UpgradePlan.vue"),
    meta: {
      app: modules.suite,
      permission: [modules.suite, "billing"],
    },
  },
];
