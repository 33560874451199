import Hotjar from "@hotjar/browser";

const siteId = 5079378;
const hotjarVersion = 6;

export function initializeHotjar() {
  if (process.env.NODE_ENV === "production") {
    Hotjar.init(siteId, hotjarVersion);
  }
}
