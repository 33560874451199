import { STATUS } from "@/helpers/variables/backendConstants.js";

export function checkIfIsEnterprise(plan) {
  const hasEnterpriseInName = plan.name.includes("Enterprise");
  const hasEnterpriseRank = plan.rank === 40;
  return hasEnterpriseInName && hasEnterpriseRank;
}

export function checkIfIsSecurity(plan) {
  const hasSecurityInName = plan.name.includes("Security");
  const hasSecurityRank = plan.rank === 30;
  return hasSecurityInName && hasSecurityRank;
}

export function checkIfIsStandard(plan) {
  const hasStandardInName = plan.name.includes("Standard");
  const hasStandardRank = plan.rank === 20;
  return hasStandardInName && hasStandardRank;
}

export function checkIfIsBasic(plan) {
  const isBasic = plan.products.length === 1;
  const hasBasicRank = plan.rank === 10;
  return isBasic && hasBasicRank;
}

export function checkIfIsPartner(plan) {
  const hasPartnerInName = plan.name.includes("Conecta Partner");
  const hasPartnerRank = plan.rank === 0;
  return hasPartnerInName && hasPartnerRank;
}

export function isCurrentlyPlan(client_plans, plan_key) {
  return client_plans.find(
    (plan) => plan.plan.key === plan_key && plan.status !== STATUS.DISABLED
  )
    ? true
    : false;
}
