import { getDatabase, ref, get } from "firebase/database";

const state = () => ({});

const getters = {};

// actions
const actions = {
  async getTasksInRealtimeDB({ getters }) {
    const db = getDatabase();
    const reference = ref(
      db,
      `task_processes/${getters.company.key}/${getters.currentUser.id_google}`
    );

    try {
      const snapshot = await get(reference);
      return snapshot.exists() ? Object.values(snapshot.val()) : [];
    } catch (err) {
      console.error(err);
    }
  },
};

// mutations
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
