const BASE_URL = "https://www.googleapis.com/auth";

export const INCREMENTAL_SCOPES = {
  ADMIN_DIRECTORY_USER: `${BASE_URL}/admin.directory.user`,
  ADMIN_DIRECTORY_DOMAIN_READONLY: `${BASE_URL}/admin.directory.domain.readonly`,
  CALENDAR: `${BASE_URL}/calendar`,
  ADMIN_GROUP_MIGRATION: `${BASE_URL}/apps.groups.migration`,
  DRIVE: `${BASE_URL}/drive.file`,
  ADMIN_DIRECTORY_GROUP: `${BASE_URL}/admin.directory.group`,
  ADMIN_DRIVE: `${BASE_URL}/drive`,
  GMAIL_READONLY: `${BASE_URL}/gmail.readonly`,
};

export const MARKETPLACE_APP_URL = `https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}`;
