<template>
  <v-btn
    v-if="button"
    :small="small"
    :x-small="xs"
    icon
    :color="color"
    @click="$emit('action')"
    :disabled="disabled"
  >
    <v-tooltip
      :top="top"
      :bottom="bottom"
      :left="left"
      :right="right"
      :max-width="maxWidth"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          :size="size"
          v-text="icon"
          :disabled="disabled"
        />
      </template>
      <span v-html="label" />
    </v-tooltip>
  </v-btn>
  <v-tooltip
    v-else
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
    :max-width="maxWidth"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :dark="dark"
        :color="color"
        :small="small"
        v-bind="attrs"
        v-on="on"
        :size="size"
        v-text="icon"
        :disabled="disabled"
      />
    </template>
    <span v-html="label" />
  </v-tooltip>
</template>
<script>
export default {
  props: {
    color: { type: String, default: "secondary" },
    icon: { type: String, required: true },
    size: { type: [String, Number], default: 20 },
    dark: { type: Boolean, default: false },
    label: { type: String, required: true },
    maxWidth: { type: [String, Number], default: 230 },
    bottom: { type: Boolean, default: true },
    top: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    button: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    xs: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    userImage() {
      return this.user.photo || this.user.image || false;
    },
  },
};
</script>
