export const DISCOVERY_DOCS = {
  // More discoveries: https://discovery.googleapis.com/discovery/v1/apis
  // TODO: Este valor é próprio da migração de drive
  // https://google-api-client-libraries.appspot.com/documentation/drive/v3/python/latest/index.html
  DRIVE: "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  // TODO: Este valor é próprio da migração de e-mails
  // https://google-api-client-libraries.appspot.com/documentation/gmail/v1/python/latest/gmail_v1.users.messages.html#list
  GMAIL: "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
  // https://googleapis.github.io/google-api-python-client/docs/dyn/groupsmigration_v1.archive.html#insert
  GROUPS_MIGRATION:
    "https://groupsmigration.googleapis.com/$discovery/rest?version=v1",
};
