<template>
  <v-row
    :justify="justify"
    align="center"
    class="justify-center mt-2 px-2 mx-0"
  >
    <v-col
      v-if="!hideCancel"
      cols="12"
      sm="4"
      xs="12"
      :order="$vuetify.breakpoint.xs ? 1 : 0"
      :class="`d-flex ${
        $vuetify.breakpoint.xs || !block ? 'justify-start' : ''
      } py-0`"
    >
      <v-btn
        :block="$vuetify.breakpoint.xs || block"
        :outlined="outlined"
        text
        color="secondary lighten-2"
        class="px-4 font-weight-medium"
        :disabled="disableCancel"
        @click="$emit('cancel')"
        large
        elevation="0"
      >
        <v-icon v-if="cancelIcon" v-text="cancelIcon" dense left />
        {{ cancelText }}
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      sm="4"
      xs="12"
      :order="$vuetify.breakpoint.xs ? 0 : 1"
      :class="`d-flex ${
        $vuetify.breakpoint.xs || !block ? 'justify-end' : ''
      } py-0`"
    >
      <v-btn
        :block="$vuetify.breakpoint.xs || block"
        color="accent"
        :outlined="outlined"
        :text="text && !$vuetify.breakpoint.xs"
        :disabled="disableAction"
        class="px-4 font-weight-medium"
        :loading="loading"
        large
        @click="$emit('action')"
        elevation="0"
      >
        <v-icon v-if="actionIcon" v-text="actionIcon" dense left />
        {{ actionText }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "ActionButtons",

  props: {
    loading: { type: Boolean, default: false },
    actionText: { type: String, default: "Salvar" },
    actionIcon: { type: String, default: "" },
    disableAction: { type: Boolean, default: false },
    cancelText: { type: String, default: "Cancelar" },
    cancelIcon: { type: String, default: "" },
    disableCancel: { type: Boolean, default: false },
    hideCancel: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    justify: { type: String, default: "space-between" },
  },
};
</script>
