<template>
  <v-card-actions class="px-0">
    <v-btn
      icon
      large
      class="mr-2 px-0"
      color="secondary lighten-2"
      @click.stop="redirectToTemplates"
    >
      <v-icon v-text="'mdi-arrow-left'" />
    </v-btn>

    <v-menu
      v-if="$vuetify.breakpoint.smAndUp"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="230"
      offset-y
      disabled
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          large
          height="50"
          class="rounded-pill text-none text-body-1 font-weight-medium secondary--text text--lighten-2 mx-0 px-0"
          v-bind="attrs"
          v-on="on"
          @click="goToindexPath()"
        >
          <div style="width: 40px">
            <Logo :product="'conecta_suite'" height="45" no-margins />
          </div>
          <v-icon v-if="false" v-text="'mdi-chevron-down'" right class="ml-2" />
        </v-btn>
      </template>

      <v-card widt="230">
        <v-list class="py-0" widt="230"> </v-list>

        <v-divider></v-divider>

        <v-subheader> Opções </v-subheader>

        <v-list class="py-0" disabled>
          <v-list-item
            link
            v-for="(option, index) in templateOptions"
            :key="index"
            disabled
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap break-word">
                {{ option.title }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-wrap break-word mt-1 normal-line-height"
              >
                {{ option.subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-subheader> Configurações do editor </v-subheader>

        <v-list>
          <v-list-item disabled>
            <v-list-item-action>
              <v-switch inset color="purple" disabled></v-switch>
            </v-list-item-action>
            <v-list-item-title>
              Pré-visualizar com minhas informações
            </v-list-item-title>
          </v-list-item>

          <v-list-item> </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-card-actions>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SignSidebarMenu",

  data() {
    return {
      menu: false,
      templateOptions: [
        {
          icon: "mdi-account-multiple",
          title: "Aplicar modelo para...",
          subtitle: "Escolher usuários para utilizar este modelo",
          value: "select_users_to_apply",
        },
        {
          icon: "mdi-content-copy",
          title: "Duplicar modelo",
          subtitle: "Fazer uma cópia deste modelo",
          value: "",
        },
        {
          icon: "mdi-content-copy",
          title: "Modelo para novos usuários",
          subtitle:
            "Defina este modelo para novos usuários que forem adicionados ao seu Workspace",
          value: "",
        },
      ],
      indexPath: "/sign",
    };
  },

  computed: {
    ...mapGetters(["currentUser", "selectedTemplateId"]),
  },

  methods: {
    redirectToTemplates() {
      if (this.selectedTemplateId) {
        this.$router.push(
          `/sign/email-signature/templates?id=${this.selectedTemplateId}`
        );
      } else {
        this.$router.push(`/sign/email-signature/templates`);
      }
    },

    goToindexPath() {
      if (this.$route.path !== this.indexPath) {
        this.$router.push(this.indexPath).catch();
      }
    },
  },
};
</script>
