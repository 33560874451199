import store from "@/store";

import { hasTagInText } from "@/helpers/services/utils";
import { convertDomToHtmlString } from "@/helpers/services/tagPreview";

/**
 * Function to format drive images urls
 * @param {String} url The url source of image
 * @return {String} The url source formatted
 */
export function changeDriveURL(url, company = null) {
  if (!url || typeof url !== "string") {
    return "";
  }

  if (url.startsWith("https://drive.google.com/file/d/")) {
    const id = url.split("/")[5]; // A quinta posição do array será o ID da imagem
    return `https://lh3.googleusercontent.com/d/${id}?authuser=0`;
  } else if (url.startsWith("https://drive.google.com/uc?id=")) {
    const id = /id=(.*?)&/.exec(url)[1];
    return `https://lh3.googleusercontent.com/d/${id}?authuser=0`;
  } else if (company) {
    url = replaceTagsWithHttps(url);
    return url;
  }

  return url;
}

/**
 * Function to insert https in urls
 * @param {String} url The url to insert the https
 * @return {String} The url formatted with https
 */
function replaceTagsWithHttps(url) {
  if (hasTagInText(url)) {
    return url;
  }
  url =
    url.startsWith("http") || url.startsWith("https") ? url : `https://${url}`;

  return url;
}

/**
 * Function to verify if the URL is from Google Drive
 * @param {String} url The url to verify
 * @return {Boolean} True or false value
 */
export function isGoogleDriveImage(url) {
  return url.startsWith("https://drive.google.com/");
}

/**
 * Function to verify if the URL is from company domain
 * @param {String} url The url to verify
 * @return {Boolean} True or false value
 */
export function isInCompanyDomainImage(url) {
  return url.includes(store.getters.mainDomain);
}

export function hasExternalImage(html) {
  return getExternalImages(html).length;
}

export function getExternalImages(html) {
  var element = new DOMParser().parseFromString(html, "text/html");

  let externalImages = [];

  element.getElementsByTagName("img").forEach((image) => {
    const isNotGoogleDriveImage = !isGoogleDriveImage(image.src);
    const isNotCompanyDomainImage = !isInCompanyDomainImage(image.src);
    const isNotTagImage = image.alt ? !hasTagInText(image.alt) : false;

    const isExternalImage = isNotGoogleDriveImage || isNotCompanyDomainImage;

    if (isExternalImage && isNotTagImage) {
      externalImages.push(image.src);
    }
  });

  return externalImages;
}

export function replaceImageUrl(html, oldUrl, newUrl) {
  var element = new DOMParser().parseFromString(html, "text/html");

  element.getElementsByTagName("img").forEach((image) => {
    if (image.src === oldUrl) {
      image.src = newUrl;
    }

    html = element.innerHTML;
  });

  return convertDomToHtmlString(element);
}

export async function convertImageIntoBase64(file) {
  const url = URL.createObjectURL(file);
  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  let imgBase64 = await toDataURL(url);
  return imgBase64.split(",")[1];
}
