<template>
  <v-list-item class="pa-0 ma-0" :dark="dark">
    <v-list-item-content class="pa-0 ma-0">
      <v-list-item-title
        :class="`text-h6 ${subtitle ? 'mb-2' : 'mb-0'} ${titleClass}`"
        v-html="title"
      />
      <v-list-item-subtitle
        v-if="subtitle"
        :class="`${subtitleClass}`"
        v-html="subtitle"
      />
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: "HeaderTitle",
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, default: "" },
    titleClass: { type: String, default: "" },
    subtitleClass: { type: String, default: "" },
    dark: { type: Boolean, default: false },
  },
};
</script>
