export const statusColor = {
  ACTIVE: "success",
  EXPIRED: "error",
  TRIAL_EXPIRED: "error",
  TRIAL: "warning",
  DEFAULT: "secondary",
  SUSPENDED: "warning",
};

export const lightColors = {
  conecta_suite: "lightSuite",
  conecta_sign: "lightSign",
  conecta_track: "lightTack",
};
