<template>
  <div>
    <PageLoading v-if="isReauthenticating" />
    <Suspended v-if="hasBeenSuspended" />
    <AccessForm v-else-if="isAccessFormLayout" />
    <SignEditor v-else-if="isSignEditorMode" />
    <NewDashboard v-else-if="isNewDashboard" />
    <Dashboard v-else-if="isDashboardLayout" />
    <External v-else-if="isExternal" />
    <Default v-else />

    <EndOfSessionDialog
      :show="showLogoutDialog"
      @closeLogoutDialog="closeLogoutDialog"
    />

    <DialogHasProductAccess
      :show="showPermissionDialog"
      :selectedProduct="pageToCheckPermission"
      :status="permissionStatus"
      @close="closeAccessDialog"
    />
  </div>
</template>

<script>
import Dashboard from "@/layouts/Dashboard.vue";
import External from "@/layouts/External.vue";
import Default from "@/layouts/Default";
import SignEditor from "@/layouts/SignEditor";
import Suspended from "@/layouts/Suspended.vue";
import AccessForm from "@/components/AccessForm.vue";
import PageLoading from "@/components/PageLoading.vue";
import EndOfSessionDialog from "@/components/main/dashboard/EndOfSessionDialog";
import { modules } from "@/helpers/variables/modules";
import styles from "@/styles/variables.scss";
import { mapGetters, mapMutations, mapActions } from "vuex";
import NewDashboard from "@/layouts/NewDashboard.vue";

export default {
  name: "App",

  components: {
    AccessForm,
    Dashboard,
    Default,
    External,
    SignEditor,
    PageLoading,
    EndOfSessionDialog,
    Suspended,
    NewDashboard,
  },

  data() {
    return {
      modules,
      styles,
    };
  },

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "isAuthenticating",
      "hasFilledForm",
      "showLogoutDialog",
      "showPermissionDialog",
      "pageToCheckPermission",
      "permissionStatus",
      "isSimulation",
      "isSuspended",
      "isDashTester",
    ]),

    isSignEditorMode() {
      return this.$route.meta.editorMode && this.isAuthenticated;
    },

    isAccessFormLayout() {
      return (
        this.$route.meta.requiresAuth &&
        this.isAuthenticated &&
        !this.hasFilledForm &&
        !this.isSimulation
      );
    },

    hasBeenSuspended() {
      return this.isAuthenticated && this.isSuspended;
    },

    isNewDashboard() {
      return (
        this.$route.meta.requiresAuth &&
        this.isAuthenticated &&
        (this.hasFilledForm || this.isSimulation) &&
        this.isDashTester
      );
    },

    isDashboardLayout() {
      return (
        this.$route.meta.requiresAuth &&
        this.isAuthenticated &&
        (this.hasFilledForm || this.isSimulation) &&
        !this.isDashTester
      );
    },

    isExternal() {
      return (
        !this.isDashboardLayout && this.$route.name == "ExtBillingUpgradePlan"
      );
    },
    isReauthenticating() {
      return this.$route.name !== "Auth" && this.isAuthenticating;
    },
    isConectaControl() {
      return this.curentAppSlug === modules.control;
    },
    isConectaSign() {
      return this.curentAppSlug === modules.sign;
    },
    curentAppSlug() {
      return this.$route.meta.app;
    },
  },

  watch: {
    $route() {
      // Definição das cores primárias de acordo com o produto selecionado
      // if (this.isConectaSign) {
      //   // Tema claro
      //   this.$vuetify.theme.themes.light.primary = styles.primarySign;
      //   this.$vuetify.theme.themes.light.accent = styles.accentSign;
      //   // Tema escuro
      //   this.$vuetify.theme.themes.dark.primary = styles.primarySignDark;
      // } else if (this.isConectaControl) {
      //   // Tema claro
      //   this.$vuetify.theme.themes.light.primary = styles.primaryControl;
      //   this.$vuetify.theme.themes.light.accent = styles.accentControl;
      //   // Tema escuro
      //   this.$vuetify.theme.themes.dark.primary = styles.primaryControlDark;
      // } else {
      // Tema claro
      this.$vuetify.theme.themes.light.primary = styles.primarySuite;
      this.$vuetify.theme.themes.light.accent = styles.accentSuite;
      // Tema escuro
      this.$vuetify.theme.themes.dark.primary = styles.primarySuiteDark;
      // }
    },
  },

  methods: {
    ...mapMutations([
      "setShowLogoutDialog",
      "clearDelayedAccessDialog",
      "resetPermissionDialog",
      "setPermissionDialog",
    ]),

    ...mapActions(["logoutWithDialog"]),

    closeLogoutDialog() {
      this.setShowLogoutDialog(false);
    },

    closeAccessDialog() {
      this.clearDelayedAccessDialog();
      this.resetPermissionDialog();
    },

    setPermissionDialogByUrl({ data }) {
      this.setPermissionDialog({
        show: true,
        status: data.code,
        app: data.product || data.resource,
      });
    },
  },

  created() {
    this.$axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        let logoutMessages = [
          "EXPIRED_TOKEN",
          "INVALID_TOKEN",
          "MISSING_TOKEN",
          "COMPANY_BANNED",
        ];
        let noProductPermissionMessages = [
          "USER_IS_NOT_ADMIN",
          "PLAN_TRIAL_ENDED",
          "PRODUCT_IN_DISABLED_PLAN",
          "PRODUCT_DOES_NOT_EXIST_IN_PLANS",
          "USER_IS_NOT_ALLOWED_TO_ACCESS_THIS_RESOURCE",
        ];
        if (
          error.response?.status === 401 &&
          logoutMessages.includes(error.response.data?.code)
        ) {
          await this.logoutWithDialog();
        }
        if (
          error.response?.status === 403 &&
          noProductPermissionMessages.includes(error.response.data?.code)
        ) {
          this.setPermissionDialogByUrl(error.response);
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style>
html {
  overflow-y: auto !important;
}

.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
</style>
