import {
  STATUS,
  getAssociatedServiceSKUByPlan,
} from "@/helpers/variables/backendConstants";
import {
  checkIfIsEnterprise,
  checkIfIsSecurity,
  checkIfIsStandard,
  checkIfIsBasic,
  checkIfIsPartner,
} from "@/helpers/services/plans";

const state = {
  selectedPlanKey: "",
  plans: [],
  services: [],
  selfCheckoutService: null,
  isSelfCheckout: false,
};

const getters = {
  selfCheckoutService: (state) => state.selfCheckoutService,
  // Armazena o objeto do plano selecionado
  currentSelectedPlan: (state) =>
    state.plans.find((plan) => plan.key === state.selectedPlanKey) || {},
  selectedPlanKey: (state) => state.selectedPlanKey,
  // Lista de todos os planos da Conecta
  plans: (state) => state.plans,
  services: (state) => state.services,
  hasPlans: (state) => state.plans.length > 0,
  selectedPlanProducts: (state) => {
    if (state.selectedPlanKey) {
      const plan = state.plans.find(
        (plan) => plan.key === state.selectedPlanKey
      );

      return plan ? plan.products.map((item) => item.name) : [];
    }
    return [];
  },
  selectedPlanName: (state) => {
    const plan = state.selectedPlanKey
      ? state.plans.find((plan) => plan.key === state.selectedPlanKey)
      : false;
    return plan && state.selectedPlanKey ? plan.name : "";
  },
  clientHasThisPlan: (state, getters, rootState, rootGetters) => {
    if (state.selectedPlanKey) {
      const companyPlans = getters.isSelfCheckout
        ? rootGetters.contractedPlans
        : rootGetters.selectedClientPlans;
      return companyPlans.find((companyPlan) => {
        return (
          companyPlan.plan_key === state.selectedPlanKey &&
          [STATUS.ACTIVE, STATUS.TRIAL].includes(companyPlan.status)
        );
      });
    }
    return false;
  },
  isSelfCheckout: (state) => state.isSelfCheckout,
  impactedCompanyPlans: (state, getters, rootState, rootGetters) => {
    const statusSet = ["ACTIVE", "TRIAL"];
    const productNames = getters.selectedPlanProducts; // Produtos do plano atual
    const currentPlanKey = getters.selectedPlanKey;
    // Verifica se a visualização é do ponto de vista do cliente logado (isSelfCheckout) ou do Reseller
    let clientPlans = getters.isSelfCheckout
      ? rootGetters.contractedPlans
      : rootGetters.selectedClientPlans;
    // Filtra os planos com base nos status ACTIVE ou TRIAL e a chave do plano atualmente selecionada
    if (clientPlans) {
      clientPlans = clientPlans.filter(
        (plan) =>
          statusSet.includes(plan.status) && plan.plan_key !== currentPlanKey
      );
    }

    if (currentPlanKey && clientPlans) {
      return clientPlans.filter((companyPlan) => {
        let clientProducts = companyPlan.plan.products;

        return clientProducts.find((product) => {
          if (productNames.includes(product.name)) {
            return companyPlan;
          }
        });
      });
    }
    return [];
  },
  defaultPlans: (state, getters, rootState, rootGetters) => {
    return rootGetters.plans.reduce((acc, plan) => {
      if (
        checkIfIsEnterprise(plan) ||
        checkIfIsSecurity(plan) ||
        checkIfIsStandard(plan) ||
        checkIfIsBasic(plan) ||
        checkIfIsPartner(plan)
      ) {
        if (plan.is_active) {
          acc.push(plan);
        }
      }
      return acc;
    }, []);
  },
};

// actions
const actions = {
  async getPlans({ getters, commit }) {
    if (!getters.plans || getters.plans.length == 0) {
      let url = `${process.env.VUE_APP_API_BASE_URL}/plan`;
      await this._vm.$axios
        .get(url, {
          headers: { Authorization: this._vm.token },
        })
        .then(({ data }) => {
          commit("setPlans", data);
        });
    }
  },

  hireNewPlan(context, payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/company-plan`;

    const auth = {
      headers: { Authorization: this._vm.token },
    };

    return this._vm.$axios.post(url, payload, auth);
  },

  updateHiredPlan(context, settings) {
    const { plan_key, payload } = settings;

    const url = `${process.env.VUE_APP_API_BASE_URL}/company-plan/${plan_key}`;

    const auth = {
      headers: { Authorization: this._vm.token },
    };

    return this._vm.$axios.patch(url, payload, auth);
  },
};

// mutations
const mutations = {
  setSelectedPlan(state, key) {
    state.selectedPlanKey = key;
  },
  setPlans(state, plans) {
    const services = plans.filter((plan) => plan.is_service);
    state.plans = plans.filter((plan) => !plan.is_service);
    state.services = services;
  },
  setClientView(state, status) {
    state.isSelfCheckout = status;
  },

  setSelfCheckoutServiceInCart(state, plan = null) {
    if (!plan) {
      state.selfCheckoutService = plan;
      return;
    }

    const serviceSKU = getAssociatedServiceSKUByPlan(plan.sku);

    state.selfCheckoutService = state.services.find(
      (service) => service.sku == serviceSKU
    );
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
