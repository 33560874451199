// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigEnv = {
  development: {
    apiKey: "AIzaSyCEmFFQogXZhTCkpxH60Md8qAIzUmW0q8Q",
    authDomain: "dev-conectasuite.firebaseapp.com",
    databaseURL: "https://dev-conectasuite.firebaseio.com",
    projectId: "dev-conectasuite",
    storageBucket: "dev-conectasuite.appspot.com",
    messagingSenderId: "950476866450",
    appId: "1:950476866450:web:87fcd530f7c4892781f74b",
    measurementId: "G-N5YXS7F90G",
  },
  production: {
    apiKey: "AIzaSyCVIMQ-HESCI8kFFkVHbvQpk2CG2BZRztI",
    authDomain: "conectasuite.firebaseapp.com",
    databaseURL: "https://conectasuite.firebaseio.com",
    projectId: "conectasuite",
    storageBucket: "conectasuite.appspot.com",
    messagingSenderId: "266221667666",
    appId: "1:266221667666:web:53be5ad5463493359f268b",
    measurementId: "G-GPVRMVKP8R",
  },
};

const environment = process.env.NODE_ENV;

const firebaseConfig = firebaseConfigEnv[environment];

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app); // eslint-disable-line
