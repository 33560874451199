import { formatDateAndHour } from "@/helpers/services/utils";

const state = () => ({
  loading: false,
  notificationsSidebar: false,
  notificationsBell: [],
  notificationsGroups: [],
  floatingGuide: false,
  guideTip: false,
});

const getters = {
  showFloatingGuide: (state) => state.floatingGuide,
  showGuideTip: (state) => state.guideTip,
  loadingNotifications: (state) => state.loading,
  showNotificationsSidebar: (state) => state.notificationsSidebar,
  notificationsBell: (state) => state.notificationsBell,
  notificationsGroups: (state) => state.notificationsGroups,
  totalNotificationsUnread: (state) =>
    state.notificationsBell.filter(({ read_at }) => !read_at).length,
};

// actions
const actions = {
  async getNotificationsGroups({ commit }) {
    commit("setLoadingNotifications", true);
    const url = process.env.VUE_APP_API_BASE_URL + "/notifications/groups";
    const config = {
      headers: { Authorization: this._vm.token },
    };
    try {
      const response = await this._vm.$axios.get(url, config);
      let notifications = response.data || [];
      notifications = notifications.map((noti) => {
        for (let dateField of ["created_at", "schedule_at"]) {
          if (noti[dateField]) {
            noti[dateField] = formatDateAndHour(new Date(noti[dateField]));
          }
        }
        return noti;
      });
      commit("setNotificationsGroups", notifications);
    } catch (error) {
      console.error(error);
    }
    commit("setLoadingNotifications", false);
  },
  async getNotifications({ commit }) {
    commit("setLoadingNotifications", true);
    const url = process.env.VUE_APP_API_BASE_URL + "/notifications";
    const config = {
      headers: { Authorization: this._vm.token },
    };

    await this._vm.$axios
      .get(url, config)
      .then(({ data }) => {
        const notifications = data.map((noti) => {
          for (let dateField of ["created_at", "schedule_at"]) {
            if (noti[dateField]) {
              noti[dateField] = formatDateAndHour(new Date(noti[dateField]));
            }
          }
          return noti;
        });
        commit("setNotificationsBell", notifications);
      })
      .catch((error) => {
        console.error("getNotifications():", error);
      });
    commit("setLoadingNotifications", false);
  },
};

// mutations
const mutations = {
  showCloseNotificationsSidebar(state) {
    state.notificationsSidebar = !state.notificationsSidebar;
  },
  setNotificationsBell(state, notifications) {
    const notificationsBell = notifications.filter(
      (noti) => noti.notification_type === "bell"
    );
    state.notificationsBell = notificationsBell;
  },
  setNotificationsGroups(state, notifications) {
    state.notificationsGroups = notifications;
  },
  setLoadingNotifications(state, status) {
    state.loading = status;
  },

  setFloatingGuide(state, appears) {
    localStorage.setItem("showFloatingGuide", appears);
    state.floatingGuide = appears;
  },

  setOpenGuideTip(state, showTip = true) {
    state.guideTip = showTip;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
