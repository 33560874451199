import { formatDate, dotsToComma, formatMoney } from "@/helpers/services/utils";
import {
  checkIfIsEnterprise,
  checkIfIsSecurity,
  checkIfIsStandard,
  checkIfIsBasic,
} from "@/helpers/services/plans";
import {
  planPeriodic,
  status,
  translateResellerTypes,
} from "@/helpers/variables/translateString";
import { STATUS, SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";

const state = () => ({
  clients: [],
  clientModules: [],
  externalControl: null,
  clientCompanyPlan: [],
  selectedResellerClient: null,
  clientsCSV: null,
  selectedResellerClientError: false,
  isHomePage: false,
  axiosController: null,
  axiosCanceled: false,
  clientsSize: 0,
  hiringType: "",
});

const getters = {
  hiringType: (state) => state.hiringType,
  isHomePage: (state) => state.isHomePage,
  /* Se o cliente possui o Control */
  hasExternalAccessControl: (state) => state.externalControl,

  /* Módulos do cliente selecionado */
  clientModules: (state) => state.clientModules,

  clientCompanyPlan: (state) => state.clientCompanyPlan,

  /* Listagem de clientes */
  clients: (state) => state.clients,

  /* Cliente selecionado */
  selectedClient: (state) => state.selectedResellerClient,

  /* Cliente selecionado. TODO: Descontinuar no futuro e adotar 'selectedClient' */
  selectedResellerClient: (state) => state.selectedResellerClient,

  /* Planos do cliente selecionado no momento */
  clientPlans: (state, getters, rootState, rootGetters) => {
    if (rootGetters.isSelfCheckout || getters.isHomePage) {
      return rootGetters.contractedPlans || [];
    }
    return getters.selectedClientPlans || [];
  },

  /* Serviços do cliente selecionado */
  clientServices: (state) =>
    state.selectedResellerClient
      ? state.selectedResellerClient.plans.filter(
          (item) => item.subscription_type === SUBSCRIPTIONS.ONCE
        )
      : [],

  /* Número de serviços contratados */
  hiredServicesNumber: (state, getters) => getters.clientServices.length,

  clientsSize: (state) => state.clientsSize,

  /* CNPJ do cliente selecionado. TODO: Descontinuar no futuro e usar clientDoNotHaveCnpj  */
  resellerClientDoNotHaveCnpj: (state) =>
    state.selectedResellerClient ? !state.selectedResellerClient.cnpj : false,

  /* Planos do cliente reseller selecionado */
  selectedClientPlans: (state) =>
    state.selectedResellerClient ? state.selectedResellerClient.plans : [],

  /* Informações do parceiro do cliente selecionado */
  selectedClientPartnerInfo: (state) =>
    state.selectedResellerClient
      ? state.selectedResellerClient.reseller_company
      : null,

  /* Token do cliente selecionado */
  selectedClientPartnerToken: (state) =>
    state.selectedResellerClient
      ? state.selectedResellerClient.reseller_token
      : "",

  /* Número de usuários do cliente selecionado */
  selectedUsersNumber: (state) =>
    state.selectedResellerClient
      ? state.selectedResellerClient.users_number
      : 0,

  /* Relação de clientes para download */
  clientsDownload: (state) => state.clientsCSV,

  /* Erro do cliente selecionado */
  getSelectedResellerClientError: (state) => state.selectedResellerClientError,

  /* Planos ativos do cliente selecionado */
  clientActivePlans: (state, getters) =>
    getters.clientPlans.filter((plan) =>
      [STATUS.ACTIVE, STATUS.TRIAL].includes(plan.status)
    ),

  /* Número atual de planos ativos */
  activePlansNumber: (state, getters) => getters.clientActivePlans.length,

  /* Se possui o Enterprise contratado */
  hasEnterprise: (state, getters) => {
    return getters.clientActivePlans.find(
      (plan) => checkIfIsEnterprise(plan.plan) && plan.status === STATUS.ACTIVE
    )
      ? true
      : false;
  },

  /* Se possui o Security contratado */
  hasSecurity: (state, getters) => {
    return getters.clientActivePlans.find(
      (plan) => checkIfIsSecurity(plan.plan) && plan.status === STATUS.ACTIVE
    )
      ? true
      : false;
  },

  /* Se possui o Standard contratado */
  hasStandard: (state, getters) => {
    return getters.clientActivePlans.find(
      (plan) => checkIfIsStandard(plan.plan) && plan.status === STATUS.ACTIVE
    )
      ? true
      : false;
  },

  /* Se possui o Basic contratado */
  hasBasic: (state, getters) => {
    return getters.clientActivePlans.find(
      (plan) => checkIfIsBasic(plan.plan) && plan.status === STATUS.ACTIVE
    )
      ? true
      : false;
  },

  /* Retornar o plano atual da empresa selecionada (podendo ser somente os
    tradicionais: Basic, Standard, Security, Enterprise e Partner)  */
  currentPlan: (state, getters, rootState, rootGetters) => {
    return getters.clientActivePlans.find((plan) => {
      const products = plan.plan ? plan.plan.products : [];
      const defaultPlansKeys = rootGetters.defaultPlans.map((item) => item.key);
      return products.length >= 1 && defaultPlansKeys.includes(plan.plan.key)
        ? plan
        : false;
    });
  },

  hasSign: (stete, getters) => {
    const currentPlan = getters.company.current_plan;
    const products = currentPlan.plan.products;
    const planAvailableForUse = ["TRIAL", "ACTIVE"].includes(
      currentPlan.valid_status
    );

    return products.find(
      ({ name }) => name === "conecta_sign" && planAvailableForUse
    );
  },

  impactedCompanyPlansInTrial: (state, getters, rootState, rootGetters) => {
    return rootGetters.impactedCompanyPlans.filter(
      (companyPlan) => companyPlan.subscription_type === SUBSCRIPTIONS.TRIAL
    );
  },

  impactedActiveCompanyPlans: (state, getters, rootState, rootGetters) => {
    return rootGetters.impactedCompanyPlans.filter(
      (companyPlan) => companyPlan.status === STATUS.ACTIVE
    );
  },

  /* Verificar se a requisição GET foi cancelada */
  clientsCanceledResquest: (state) => state.axiosCanceled,
};

// actions
const actions = {
  // Pega clientes para preencher table
  fetchClientsPage({ state }, params) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/clients/fetch`;

    if (state.axiosController) {
      state.axiosController.abort();
      state.axiosCanceled = true;
    }

    state.axiosController = new AbortController();

    let config = {
      headers: {
        Authorization: this._vm.token,
      },
      params: params,
      signal: state.axiosController.signal,
    };

    return this._vm.$axios.get(url, config).finally(() => {
      state.axiosController = null;
      state.axiosCanceled = false;
    });
  },
  // Pega as informações do Cliente em Client.vue
  getSelectedResellerClient({ commit, dispatch }, company_key) {
    commit("setSelectedResellerClientError", false);
    let url = process.env.VUE_APP_API_BASE_URL + `/clients/${company_key}`;

    const config = {
      headers: { Authorization: this._vm.token },
    };

    return Promise.all([
      this._vm.$axios.get(url, config),
      dispatch("getResellerClientPlan", company_key),
    ])
      .then(([res_client, res_plans]) => {
        const client = {
          ...res_client.data,
          plans: res_plans.data,
        };
        commit("setSelectedClient", client);
      })
      .catch((error) => {
        console.error("Vuex: getSelectedResellerClient(): ", error);
        commit("setSelectedResellerClientError", true);
      });
  },

  // Carrega os clientes que o cliente reseller tem
  async getClients({ getters, commit }) {
    if (getters.clientsDownload) {
      return getters.clientsDownload;
    }

    let url = process.env.VUE_APP_API_BASE_URL + "/clients";

    const config = {
      headers: { Authorization: this._vm.token },
    };

    try {
      let { data } = await this._vm.$axios.get(url, config);
      await commit("setClientsDownload", data);
      await commit("setClients", data);

      return getters.clientsDownload;
    } catch (error) {
      console.error("Vuex: getClients(): ", error);
    }
  },

  async getCompanyPlanByKey({ commit }, client_key) {
    let url = process.env.VUE_APP_API_BASE_URL + `/clients/${client_key}/plans`;

    await this._vm.$axios
      .get(url, {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        commit("setClientCompanyPlan", data);
      })
      .catch((e) => {
        console.error("Vuex: getCompanyPlanByKey()", e);
      });
  },

  getResellerClientPlan(context, client_key) {
    let url = process.env.VUE_APP_API_BASE_URL + `/clients/${client_key}/plans`;

    return this._vm.$axios.get(url, {
      headers: {
        Authorization: this._vm.token,
      },
    });
  },

  async getControlDomainStatus({ commit }, domain) {
    const url = process.env.VUE_APP_CLOUD_FUNCTION_DOMAIN_STATUS;

    await this._vm.$axios
      .get(url, {
        params: {
          company_domain: domain,
        },
      })
      .then(function ({ data }) {
        commit("setExternalControlAccess", data.message);
      })
      .catch((e) => {
        console.warn("Vuex: getControlDomainStatus()", e);
      });
  },
};

// mutations
const mutations = {
  setClientsSize(state, size) {
    if (size) {
      state.clientsSize = size;
    }
  },

  setClientsDownload(state, clients) {
    let allPlans = [];

    clients.flatMap((client) => {
      const { company, plans } = client;

      let resellerCompany = company.reseller_company;

      let currentlyClient = {
        id_google: company.id_google,
        name: company.name,
        corporate_name: company.corporate_name,
        main_domain: company.main_domain,
        users_number: company.users_number,
        is_reseller: company.is_reseller ? "Sim" : "Não",
        created_at: formatDate(company.created_at),
        responsible_name: company.responsible_name,
        responsible_email: company.responsible_email,
        financial_email: company.financial_email
          ? company.financial_email
          : "Não informado",
        phone: company.phone ? company.phone : "Não informado",
        verified_phone: company.verified_phone
          ? company.verified_phone
          : "Não informado",
        cnpj: company.cnpj ? company.cnpj : "Não informado",
        address_postal_code: company.address_postal_code
          ? company.address_postal_code
          : "Não informado",
        address_info: company.address_info
          ? company.address_info
          : "Não informado",
        contract: company.contract ? "Aceito" : "Pendente",
        tags: company.tag,
        reseller_name: resellerCompany ? resellerCompany.name : null,
        reseller_domain: resellerCompany ? resellerCompany.domain : null,
        reseller_type: resellerCompany
          ? translateResellerTypes[resellerCompany.type]
          : null,
        reseller_commission: resellerCompany
          ? dotsToComma(resellerCompany.commission * 100, 0)
          : null,
        reseller_discount: resellerCompany
          ? dotsToComma(resellerCompany.discount * 100, 0)
          : null,
      };

      for (let i = 0; i < plans.length; i++) {
        const plan = plans[i];
        const currentlyPlan = {
          plan_name: plan.plan.name,
          plan_sku: plan.plan.sku,
          plan_status: status[plan.status],
          plan_licenses: currentlyClient.users_number,
          plan_max_licenses: plan.max_licenses
            ? plan.max_licenses
            : currentlyClient.users_number,
          plan_subscription_type: planPeriodic[plan.subscription_type],
          plan_next_billing: plan.next_billing
            ? formatDate(plan.next_billing)
            : "Indisponível",
          plan_end_trial: formatDate(plan.end_trial),
          plan_end_date: formatDate(plan.end_date),
          plan_price_per_period:
            plan.subscription_type === "ANNUAL"
              ? formatMoney(plan.price * 12)
              : formatMoney(plan.price),
          plan_price_period_per_month: formatMoney(plan.price),
          plan_price: formatMoney(plan.plan.price),
          company_plan_discount: plan.discount,
        };

        allPlans.push(Object.assign(currentlyPlan, currentlyClient));
      }
      return allPlans;
    });
    state.clientsCSV = allPlans;
  },
  setSelectedClient(state, client) {
    state.selectedResellerClient = client;
  },
  setClients(state, clients) {
    state.clients = clients;
  },
  setClientModules(state, data) {
    state.clientModules = data;
  },
  setExternalControlAccess(state, status) {
    state.externalControl = status;
  },
  setClientCompanyPlan(state, data) {
    state.clientCompanyPlan = data;
  },
  setSelectedResellerClientError(state, status) {
    state.selectedResellerClientError = status;
  },

  setIsHomePage(state, status) {
    state.isHomePage = status;
  },

  setHiringType(state, value) {
    state.hiringType = value;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
