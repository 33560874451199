<template>
  <div>
    <!-- MODAL DE GERAR O LINK -->
    <HeaderDialog
      :title="userEmail || 'Usuário'"
      subtitle="Convidar para Meet"
      :show="showGenerateMeetDialog"
      action-text="Enviar"
      width="600"
      :disabled-action="!meetLink"
      @action="inviteUser(getUserToCall)"
      @close="showGenerateDialog = false"
      @keydown.esc="showGenerateDialog = false"
      @click:outside="showGenerateDialog = false"
    >
      <template v-slot:body>
        <v-row class="mx-0">
          <v-col class="d-flex flex-column justify-center">
            <p>
              Gere um link no Google Meet, copie e cole no campo abaixo para
              enviar ao cliente e convida-lo a uma call.
            </p>
            <v-btn
              color="primarySuite"
              class="white--text my-2"
              outlined
              elevation="0"
              @click="openGenerateMeetURL"
              >Gerar Link</v-btn
            >
            <v-text-field
              v-model="meetLink"
              label="Link do Meet"
              placeholder="https://meet.google.com/(sala)"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>

    <!-- MODAL DE ESPERA -->
    <v-card
      class="waiting-card elevation-5"
      v-if="waitingDialog"
      flat
      max-width="600"
    >
      <v-card class="text-center">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Convite enviado</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div class="text-h4 mt-5">{{ inviteStatusText }}</div>
        </v-card-text>
        <v-progress-circular
          v-if="getInviteResponse === 'pending'"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-icon v-else large :color="responseIcon.color">{{
          responseIcon.icon
        }}</v-icon>
        <v-card-actions class="justify-end">
          <v-btn text @click="cancelInvite(getUserToCall)">{{
            getInviteResponse === "pending" ? "Cancelar" : "Fechar"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import HeaderDialog from "@/components/base/HeaderDialog.vue";
import { mapGetters, mapMutations } from "vuex";
import { getDatabase, ref, update } from "firebase/database";

export default {
  name: "GenerateMeetInvite",

  data() {
    return {
      meetLink: "",
      waitingDialog: false,
    };
  },
  components: {
    HeaderDialog,
  },

  computed: {
    ...mapGetters([
      "showGenerateMeetDialog",
      "getUserToCall",
      "currentUser",
      "getInviteResponse",
    ]),

    responseIcon() {
      if (this.getInviteResponse === "accepted") {
        return {
          color: "green",
          icon: "mdi-checkbox-marked-circle",
        };
      } else if (
        this.getInviteResponse === "declined" ||
        this.getInviteResponse === "canceled"
      ) {
        return {
          color: "red",
          icon: "mdi-close-circle",
        };
      } else if (this.getInviteResponse === "expired") {
        return {
          color: "red",
          icon: "mdi-timer-alert",
        };
      } else {
        return {
          color: "grey",
          icon: "mdi-phone-outgoing",
        };
      }
    },

    userEmail() {
      return this.getUserToCall.email;
    },

    inviteStatusText() {
      if (this.getInviteResponse === "accepted") {
        return "O cliente aceitou o convite! Fique atento ao Google Meet.";
      } else if (this.getInviteResponse === "declined") {
        return "O cliente recusou o convite!";
      } else if (this.getInviteResponse === "expired") {
        return "O convite expirou.";
      } else if (this.getInviteResponse === "canceled") {
        return "Convite cancelado";
      } else {
        return "Ligando para o cliente";
      }
    },

    showGenerateDialog: {
      get() {
        return this.showGenerateMeetDialog;
      },
      set(newValue) {
        this.setGenerateMeetDialog(newValue);
      },
    },
  },

  methods: {
    ...mapMutations(["setGenerateMeetDialog", "setInviteResponse"]),

    openGenerateMeetURL() {
      window.open("https://meet.google.com/", "_blank");
    },

    inviteUser(user) {
      const db = getDatabase();
      const waitingRef = ref(db, `waiting/${user.key}/meetCall`);
      update(waitingRef, {
        status: "receving_call",
        attendent: this.currentUser,
        meetLink: this.meetLink,
      });

      const attendantRef = ref(
        db,
        `attendant/${this.currentUser.key}/meetCall`
      );
      update(attendantRef, {
        status: "pending",
      });

      this.setInviteResponse("pending");
      this.waitingDialog = true;
      this.showGenerateDialog = false;
    },

    cancelInvite(user) {
      if (this.getInviteResponse === "pending") {
        const db = getDatabase();
        const waitingRef = ref(db, `waiting/${user.key}/meetCall`);
        update(waitingRef, {
          status: "canceled",
        });

        const attendantRef = ref(
          db,
          `attendant/${this.currentUser.key}/meetCall`
        );
        update(attendantRef, {
          status: "canceled",
        });

        this.setInviteResponse("canceled");
      }
      this.waitingDialog = false;
    },
  },
};
</script>

<style scoped>
.waiting-card {
  position: fixed;
  bottom: 120px;
  right: 16px;
  z-index: 9999;
}
</style>
