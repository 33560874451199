<template>
  <v-dialog
    :id="id"
    v-model="showDialog"
    :width="width"
    scrollable
    :persistent="persistent"
    @keydown.esc="close()"
    @click:outside="close()"
  >
    <v-card class="pb-2 py-2">
      <v-card-title :class="`${titleStyle} d-flex align-start mb-6 pb-0 px-5`">
        <span class="ml-1 mt-1">{{ title }}</span>
        <v-spacer />
        <v-btn large icon @click="close()">
          <v-icon v-text="'mdi-close'" color="secondary lighten-2" />
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if="hasSubtitle" class="mt-1" v-html="description" />
      <v-card-text class="text-md-body-1">
        <slot name="body" />
      </v-card-text>

      <v-card-actions class="px-2" v-if="customFooter">
        <slot name="footer" />
      </v-card-actions>

      <v-card-actions class="px-6" v-else-if="!readOnly">
        <v-btn
          v-if="showCancel"
          data-testid="Cancelar"
          :class="buttonClass"
          color="grey"
          class="text-none text-subtitle-1 font-weight-medium"
          text
          @click="close()"
        >
          {{ closeText }}
        </v-btn>
        <v-btn
          v-else-if="customButtom"
          color="accent"
          :class="`text-none text-subtitle-1 font-weight-regular ${
            customButtonIcon ? 'pl-1 pr-4' : ''
          }`"
          text
          :data-testid="customButtonText"
          @click="$emit('left-button')"
        >
          <v-icon
            dense
            v-if="customButtonIcon"
            v-text="customButtonIcon"
            class="mr-1 pa-0"
          />
          {{ customButtonText }}
        </v-btn>
        <v-spacer />
        <v-btn
          :data-testid="actionText"
          :loading="showLoading"
          :class="buttonClass"
          :color="color"
          class="px-4 text-none elevation-0 text-subtitle-1 font-weight-medium"
          :disabled="disabledAction"
          @click="confirmAction()"
        >
          {{ actionText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "SimpleDialog",
  props: {
    id: { type: String, required: false },
    title: { type: String, required: true },
    description: { type: String, default: "" },
    width: { type: String, default: "500" },
    persistent: { type: Boolean, default: false },
    color: { type: String, default: "accent" },
    show: { type: Boolean, required: true },
    titleStyle: { type: String, default: "font-weight-regular" },
    customFooter: { type: Boolean, default: false },
    actionText: { type: String, default: "Confirmar" },
    buttonClass: { type: String, default: "" },
    disabledAction: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    showCancel: { type: Boolean, default: false },
    customButtom: { type: Boolean, default: false },
    customButtonText: { type: String, default: "Avançado" },
    customButtonIcon: { type: String, default: "" },
    showLoading: { type: Boolean, default: false },
    closeText: { type: String, default: "Cancelar" },
  },
  computed: {
    hasSubtitle() {
      return this.description;
    },
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  methods: {
    close() {
      this.$emit(`close`);
    },
    confirmAction() {
      this.$emit("action");
    },
  },
};
</script>
