const state = () => ({
  hasInitiatedMigration: false,
});

const getters = {
  hasInitiatedMigration: (state) => state.hasInitiatedMigration,
};

const actions = {
  async setMigrationStatusProgress(_, payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/migration-status`;

    const headers = {
      headers: { Authorization: this._vm.token },
    };

    await this._vm.$axios.post(url, payload, headers);
  },

  getMigrationStatusProgress(_, email) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/migration-status?email=${email}`;

    const headers = {
      headers: { Authorization: this._vm.token },
    };

    return this._vm.$axios.get(url, headers);
  },
};

const mutations = {
  setInitiatedMigration(state, initMigration = false) {
    state.hasInitiatedMigration = initMigration;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
