import { headersTitles } from "@/helpers/variables/tablesDefault";

export function setSearchByString(searchString, itens) {
  if (searchString) {
    return itens.reduce((acc, item) => {
      let searchFor = searchString;

      if (["email", "main_domain"].includes(item)) {
        searchFor = formatStringToSearchForEmail(searchFor);
      }

      if (
        ["name", "name"].includes(item) &&
        checkIfItsLowerCase(searchString[0])
      ) {
        searchFor = makeInitialLetterCapital(searchFor);
      }

      const filterObject = {
        search: searchFor,
        text: "",
        type: "text",
        value: item,
      };

      acc.push(filterObject);

      return acc;
    }, []);
  }
  return [];
}

export function updateAppiledFiltersValues(
  oldFilter,
  newFilter,
  searchInputKeys
) {
  const old = filterArrayToObject(oldFilter);
  const update = filterArrayToObject(newFilter);

  if (newFilter.length > 0) {
    const updatedFilters = { ...old, ...update };
    return filterObjectToFilterArray(updatedFilters);
  } else {
    const searchByString = oldFilter.filter((item) =>
      searchInputKeys.includes(item.value)
    );
    return searchByString;
  }
}

export function filterObjectToFilterArray(filters) {
  const itens = Object.entries(filters);

  return itens.reduce((acc, item) => {
    const key = item[0];
    const value = item[1];

    acc.push({
      search: value,
      text: headersTitles[key] || "",
      type: getFilterType(key),
      value: key,
    });

    return acc;
  }, []);
}

export function filterArrayToObject(filterArray) {
  return filterArray.reduce((acc, item) => {
    acc[item.value] = item.search;
    return acc;
  }, {});
}

export function getFilterType(key) {
  const filtersTypes = {
    applied_signature_key: "list",
    applied_vacation_key: "list",
    departament: "text",
    domain: "list",
    email: "text",
    is_google_admin: "list",
    is_primary_email: "text",
    name: "text",
    users: "text",
    position: "text",
    main_domain: "text",
    "current_plan.*.plan.name": "list",
    "current_plan.*.end_trial": "list",
    "current_plan.*.status": "list",
    "reseller_company.name": "list",
    "current_plan.*.subscription_type": "list",
    ou: "text",
    suspended: "bool",
    cancellation_date: "bool",
  };
  return filtersTypes[key] || "text";
}

export function hasSpecialCharacters(string) {
  var specialCharacters = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return specialCharacters.test(string);
}

export function formatStringToSearchForEmail(email) {
  const updatedEmail = email
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(" ", "");
  return updatedEmail.toLowerCase();
}

export function makeInitialLetterCapital(searchString) {
  const firstLetter = searchString.charAt(0).toUpperCase();
  return firstLetter + searchString.slice(1);
}

export function checkIfItsUpperCase(letter) {
  return letter.toUpperCase() === letter;
}

export function checkIfItsLowerCase(letter) {
  return letter.toLowerCase() === letter;
}

export function removeEmptyFilters(itens) {
  const filters = Object.entries(itens);

  return filters.reduce((acc, item) => {
    const key = item[0];
    const value = item[1];

    if (value != "") {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export function mergeChildren(array) {
  let allOrgUnits = [
    {
      name: "Principal",
      path: "/",
      description: "Unidade Organizacional mãe",
    },
  ];
  array.forEach((obj) => {
    allOrgUnits.push(obj);

    if (obj.children && obj.children.length > 0) {
      allOrgUnits = allOrgUnits.concat(mergeChildren(obj.children));
      // obj.children = [];
    }
  });

  return allOrgUnits;
}
