export const templateLength = 9000;

export const permissionsActions = {
  reseller: "checkResellerPermission",
  manage_admins: "checkUserPermission",
  company_profile: "checkCompanyProfilePermission",
  inventory: "checkInventoryPermission",
  billing: "checkBillingPermission",
  reports: "checkReportsPermission",
  conecta_track: "checkProductPermission",
  conecta_suite: "checkProductPermission",
  conecta_sign: "checkProductPermission",
  conecta_control: "checkProductPermission",
};

export const permissionsFunctionActions = {
  reseller: "checkHasResellerPermission",
  manage_admins: "checkHasUserPermission",
  inventory: "checkHasInventoryPermission",
  company_profile: "checkHasCompanyProfilePermission",
  users: "checkHasUsersListPermission",
  user_profile: "checkHasSuitePermission",
  onboarding: "checkHasSuitePermission",
  billing: "checkHasBillingPermission",
  reports: "checkHasReportsPermission",
  conecta_track: "checkHasProductPermissions",
  conecta_suite: "checkHasProductPermissions",
  conecta_sign: "checkHasProductPermissions",
  conecta_control: "checkHasProductPermissions",
};
