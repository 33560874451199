<template>
  <v-dialog
    :id="id"
    scrollable
    v-model="showDialog"
    :width="width"
    :persistent="persistent"
    :fullscreen="fullscreen"
    @keydown.esc="$emit(`close`)"
    @click:outside="$emit(`close`)"
  >
    <v-card flat tile>
      <v-toolbar
        flat
        class="py-2"
        :color="color"
        dark
        min-height="78"
        :max-height="toolbarMaxHeight"
      >
        <v-toolbar-title class="">
          <div class="text-sm-body-2">{{ subtitle }}</div>
          <div>{{ title }}</div>
        </v-toolbar-title>
        <v-spacer />
        <slot name="toolbar-actions" />
      </v-toolbar>

      <v-progress-linear indeterminate v-if="loading" color="primary" />

      <v-card-text class="text-md-body-1 mt-0 px-4">
        <slot name="body" />
      </v-card-text>

      <v-card-actions v-if="customFooter" class="mt-2 mb-1 mx-2">
        <slot name="footer-left" />

        <v-spacer />

        <slot name="footer" />
      </v-card-actions>

      <v-card-actions v-else class="mt-2 mb-1 mx-2">
        <v-spacer />

        <v-btn
          v-if="showCloseBtn"
          color="grey darken-1"
          class="text-body-2 font-weight-medium text-capitalize"
          :disabled="loading"
          @click="$emit(`close`)"
          text
          :small="dense"
          :data-testid="closeText"
        >
          {{ closeText || $t("action.close") }}
        </v-btn>

        <v-btn
          :data-testid="actionText"
          v-if="!readOnly"
          :color="color"
          text
          class="ml-4 px-2 elevation-0 text-sm-body-2 font-weight-medium"
          :disabled="disabledAction || loading"
          :loading="loading"
          @click="$emit('action')"
        >
          {{ actionText || $t("action.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "HeaderDialog",
  props: {
    id: { type: String, required: false },
    dense: { type: String, required: false },
    title: { type: String, required: true },
    subtitle: { type: String, default: "" },
    color: { type: String, default: "primary" },
    width: { type: String, default: "500" },
    persistent: { type: Boolean, default: true },
    show: { type: Boolean, required: true },
    customFooter: { type: Boolean, default: false },
    actionText: { type: String },
    disabledAction: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    closeText: { type: String },
    readOnly: { type: Boolean, default: false },
    showCloseBtn: { type: Boolean, default: true },
    fullscreen: { type: Boolean, default: false },
    toolbarMaxHeight: { type: Number, default: () => 150 },
  },
  computed: {
    hasSubtitle() {
      return this.description;
    },
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>
<style>
.v-dialog .v-card__text p {
  color: #373636 !important;
}
</style>
