var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.chip)?_c('v-chip',{class:`${_vm.styles} ${_vm.$vuetify.theme.dark ? 'white' : 'black'}--text`,attrs:{"small":_vm.small,"large":_vm.large,"to":_vm.userProfileLink}},[(!_vm.hideImage)?_c('v-avatar',{attrs:{"left":"","size":_vm.size}},[_c('img',{attrs:{"src":_vm.userImage,"alt":_vm.user.name}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.userName)+" ")],1):_c('v-list-item',{class:`user-badge transparent px-0 ${_vm.styles} ${
    _vm.link ? 'link blue--text text--darken-2' : ''
  } ${_vm.small ? 'pa-1' : ''} px-0 ma-0`,style:(`max-width: ${_vm.maxWidth}`),attrs:{"to":_vm.redirectToUser ? _vm.userProfileLink : null}},[(!_vm.hideImage && !_vm.inverse)?_c('v-list-item-action',{staticClass:"pr-0 mr-3 ml-0"},[(
        _vm.hasConectaSign &&
        !_vm.simpleView &&
        !_vm.hideBadges &&
        (!_vm.isUserGmailDisabled || _vm.canNotEditUserSignature)
      )?_c('v-badge',{attrs:{"color":"secondary lighten-1","overlap":"","bottom":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(!_vm.isUserGmailDisabled)?_c('TooltipIcon',{attrs:{"color":"white","label":_vm.$t('common.emailServiceIsDisabled'),"max-width":"400","icon":"mdi-email-off","size":"13"}}):(_vm.canNotEditUserSignature)?_c('TooltipIcon',{attrs:{"color":"white","label":_vm.$t('common.userRestrictDescription'),"max-width":"400","icon":"mdi-lock","size":"13"}}):_vm._e()]},proxy:true}],null,false,1014142234)},[_c('v-avatar',{attrs:{"left":"","size":_vm.size}},[_c('img',{attrs:{"src":_vm.userImage,"alt":_vm.user.name}})])],1):_c('v-avatar',{attrs:{"left":"","size":_vm.size}},[_c('img',{attrs:{"src":_vm.userImage,"alt":_vm.user.name}})])],1):_vm._e(),_c('v-list-item-content',{staticClass:"px-0"},[(_vm.userName || _vm.simpleView)?_c('v-list-item-title',{class:`text-subtitle-2 ${_vm.link ? 'blue--text text--darken-2' : ''} ${
        _vm.small ? 'caption' : ''
      } break-word`},[(!_vm.simpleView)?_c('span',{class:_vm.inverse ? '' : 'mr-2'},[_vm._v(" "+_vm._s(_vm.userName)+" ")]):(!_vm.showUserEmail)?_c('span',{class:_vm.inverse ? '' : 'mr-2'},[_vm._v(" "+_vm._s(_vm.userName)+" ("+_vm._s(_vm.userEmail)+") ")]):_vm._e(),(_vm.showAppliedVacation && !_vm.simpleView)?_c('TooltipIcon',{attrs:{"color":_vm.getVacationIconColor,"size":"24","icon":"mdi-umbrella-beach","label":`<b>Mensagem de férias</b> definida de ${_vm.formatDate(
          _vm.user.vacation_init_date
        )} à ${_vm.formatDate(_vm.user.vacation_end_date)}`}}):_vm._e()],1):_vm._e(),(_vm.user.email && _vm.showUserEmail)?_c('v-list-item-subtitle',{staticClass:"text-wrap break-word mt-1 caption"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")]):(_vm.showUserPosition)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.userPosition)+" ")]):_vm._e()],1),(!_vm.hideImage && _vm.inverse)?_c('v-list-item-action',{staticClass:"pr-0 ml-3 mr-0"},[(
        _vm.canNotEditUserSignature &&
        _vm.hasConectaSign &&
        !_vm.simpleView &&
        !_vm.hideBadges
      )?_c('v-badge',{attrs:{"color":"secondary lighten-1","overlap":"","bottom":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('TooltipIcon',{attrs:{"color":"white","label":_vm.$t('common.userRestrictDescription'),"max-width":"400","icon":"mdi-lock","size":"13"}})]},proxy:true}],null,false,247682139)},[_c('v-avatar',{attrs:{"left":"","size":_vm.size}},[_c('img',{attrs:{"src":_vm.userImage,"alt":_vm.user.name}})])],1):_c('v-avatar',{attrs:{"left":"","size":_vm.size}},[_c('img',{attrs:{"src":_vm.userImage,"alt":_vm.user.name}})])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }