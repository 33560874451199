import {
  ACTIVE,
  STATUS,
  SUBSCRIPTIONS,
  TRIAL,
} from "@/helpers/variables/backendConstants";

import { planPeriodic, status } from "@/helpers/variables/translateString";

export const subscriptionPlans = {
  TRIAL: {
    title: "Plano Trial (14 dias)",
    description: "Teste por 14 dias sem compromisso",
    ticketExpiration: 8, //  data final de trial + 8 dias
    status: TRIAL,
  },
  FLEX: {
    title: "Plano Mensal (Pagamento Mensal)",
    description: "Tenha mais Flexibilidade",
    ticketExpiration: 10, // todo dia 10 de cada mês
    status: ACTIVE,
  },
  ANNUAL: {
    title: "Plano Anual (Pagamento Anual)",
    description: "Ganhe 2 meses de desconto",
    ticketExpiration: 7, //  depois de 7 dias da emissão do boleto
    status: ACTIVE,
  },
};

export const subscriptionsList = [
  {
    name: planPeriodic[SUBSCRIPTIONS.TRIAL],
    value: SUBSCRIPTIONS.TRIAL,
  },
  {
    name: planPeriodic[SUBSCRIPTIONS.FLEX],
    value: SUBSCRIPTIONS.FLEX,
  },
  {
    name: planPeriodic[SUBSCRIPTIONS.QUARTELY],
    value: SUBSCRIPTIONS.QUARTELY,
  },
  {
    name: planPeriodic[SUBSCRIPTIONS.ANNUAL],
    value: SUBSCRIPTIONS.ANNUAL,
  },
];

export const statusList = [
  {
    name: status[STATUS.ACTIVE],
    value: STATUS.ACTIVE,
  },
  {
    name: status[STATUS.DISABLED],
    value: STATUS.DISABLED,
  },
  {
    name: "Qualquer status",
    value: "",
  },
];

export const clientStatusList = [
  {
    name: status[STATUS.SUSPENDED],
    value: STATUS.SUSPENDED.toLowerCase(),
  },
  {
    name: status[STATUS.CANCELLED],
    value: "cancellation_date",
  },
];
