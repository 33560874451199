import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";

import { sortByLatest, sortTemplatesByPopular } from "@/helpers/services/utils";

import {
  fluxStringToTemplate,
  fluxTemplateToEditor,
} from "@/helpers/services/tagPreview";

const state = () => ({
  templates: {
    custom: [],
    default: [],
  },
  updated_at: "",
  selectedTemplate: {},
  editorLayout: "editor_only",
  showPreview: false,
  richTextEditorRef: null,
  signatureStructure: "left",
  updatedHtml: "",

  templateEditorLoading: false,
  signLoadingMessage: "Carregando editor, aguarde...",

  showReaplyDialog: false,
  appliedSignatureTemplateStatus: false,
  signatureSchedules: [],
  showSignatureSuccessHintScreen: false,
});

const getters = {
  getUpdatedAtTemplate: (state) => state.updated_at,
  customTemplates: (state) => state.templates.custom,
  defaultTemplates: (state) => sortTemplatesByPopular(state.templates.default),
  allTemplates: (state) => {
    const templates = [];
    return templates.concat(state.templates.custom, state.templates.default);
  },
  allTemplatesWithLabels: (state) => {
    let templates = [];
    templates.push({ header: "Modelos personalizados" });
    Array.prototype.push.apply(templates, state.templates.custom);
    templates.push({ divider: true });
    templates.push({ header: "Modelos padrão" });
    Array.prototype.push.apply(templates, state.templates.default);
    templates.push({ divider: true });

    return templates;
  },
  selectedTemplate: (state) => state.selectedTemplate,
  selectedTemplateHtml: (state) =>
    fluxTemplateToEditor(state.selectedTemplate.html),
  selectedTemplateName: (state) => state.selectedTemplate.name || "",
  selectedTemplateId: (state) => state.selectedTemplate.id || null,
  selectedTemplateType: (state) =>
    state.selectedTemplate.signature_type || "custom",
  selectedTemplateIsPopular: (state) => state.selectedTemplate.is_popular,

  selectedTemplateNewHtml: (state) => state.updatedHtml,

  editorLayout: (state) => state.editorLayout,
  showPreview: (state) => state.showPreview,

  richTextEditorRef: (state) => state.richTextEditorRef,

  selectedIsDefaultForNewUsers: (state, getters, rootState, rootGetters) => {
    if (state.selectedTemplate) {
      return (
        getters.selectedTemplateId === rootGetters.company.new_users_signature
      );
    }
    return false;
  },

  signatureStructure: (state) => state.signatureStructure,

  selectedTemplateEditor: (state) =>
    state.selectedTemplate ? state.selectedTemplate.editor : "rich_text",

  isRichTextEditor(state, getters) {
    if (
      !getters.selectedTemplate.editor ||
      getters.selectedTemplate.editor === "rich_text"
    ) {
      return true;
    }
    return false;
  },

  isDragAndDropEditor(state, getters) {
    return getters.selectedTemplate.editor === "drag_and_drop";
  },

  templateEditorLoading: (state) => state.templateEditorLoading,

  showReaplyDialog: (state) => state.showReaplyDialog,

  signLoadingMessage: (state) => state.signLoadingMessage,

  appliedSignatureTemplateStatus: (state) =>
    state.appliedSignatureTemplateStatus,
  signatureSchedules: (state) => state.signatureSchedules,

  showSuccessHintScreen: (state) => state.showSignatureSuccessHintScreen,
};

// actions
const actions = {
  async getSignatureById({ commit }, id) {
    commit("setLoading", true, { root: true });

    let url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/template/${id}`;

    await this._vm.$axios
      .get(url, {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        commit("setUpdatedAtTemplate", data.updated_at);

        commit("setLoading", false, { root: true });

        commit("setSelectedSignTemplate", data);

        if (data.signature_structure) {
          commit("setSignatureStructure", data.signature_structure);
        } else {
          commit("setSignatureStructure", "left");
        }

        return data;
      })
      .catch(() => {
        commit(
          "setSnackBar",
          {
            color: "error",
            message: errorMessages.model_not_found,
            show: true,
          },
          { root: true }
        );

        return { status: 404 };
      });
  },

  async setNewUsersTemplate({ commit }, template_key) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/update-company-new-users-sign`;

    let config = {
      headers: {
        Authorization: this._vm.token,
      },
    };
    commit("setLoading", true);
    return this._vm.$axios
      .post(url, { template_key }, config)
      .then(() => {
        if (template_key == null) {
          commit(
            "setSnackBar",
            {
              show: true,
              color: "success",
              message: "Modelo desmarcado",
            },
            { root: true }
          );
        } else {
          commit(
            "setSnackBar",
            {
              show: true,
              color: "success",
              message: "Modelo definido para novos usuários",
            },
            { root: true }
          );
        }
        commit("setNewUserSignModel", template_key);

        commit("setLoading", false);

        return true;
      })
      .catch(() => {
        commit(
          "setSnackBar",
          {
            show: true,
            color: "error",
            message:
              "Não foi possível definir este modelo para novos usuários, tente novamente",
          },
          { root: true }
        );

        commit("setLoading", false);

        return false;
      });
  },

  async excludeSignatureTemplate({ commit }, template_to_delete) {
    commit("setLoading", true);

    let url = process.env.VUE_APP_API_BASE_URL + "/conecta-sign/template";
    let data = { key_template: template_to_delete };

    await this._vm.$axios
      .delete(url, {
        headers: { Authorization: this._vm.token },
        data,
      })
      .then(() => {
        commit("setSnackBar", {
          show: true,
          message: successMessages.delete_template,
        });
      })
      .catch(() => {
        commit("setSnackBar", {
          show: true,
          color: "error",
          message: errorMessages.delete_template,
        });
      });

    commit("setLoading", false);
  },

  /**
   * Function do aply the signature template to users
   */

  applyTemplate({ commit, dispatch }, payload) {
    let hide_snackbar = false;

    if (payload.hide_snackbar) {
      hide_snackbar = true;
      delete payload["hide_snackbar"];
    }

    dispatch("setSignatureToUsers", payload)
      .then(() => {
        if (!hide_snackbar) {
          commit("setSnackBar", {
            color: "success",
            message: successMessages.set_signature_to_users,
            show: true,
          });
        }

        return payload;
      })
      .catch(({ data }) => {
        commit("setSnackBar", {
          color: "error",
          message: errorMessages.set_signature_to_users,
          show: true,
        });

        console.error("vuex: applyTemplate(): ", data);

        return data;
      });
  },

  /**
   * Function to update an signature template
   * @param {Object} template that requires: id_template, html, name, description
   */
  updateSignatureTemplate({ commit, state }, template) {
    commit("setLoading", true, { root: true });

    let hide_snackbar = false;

    if (template.hide_snackbar) {
      hide_snackbar = true;
      delete template["hide_snackbar"];
    }

    const url = process.env.VUE_APP_API_BASE_URL + "/conecta-sign/template";

    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    if (template.html) {
      template.html = fluxStringToTemplate(template.html);
    }

    let params = {
      ...template,
      signature_structure: state.signatureStructure,
    };

    return this._vm.$axios
      .put(url, params, auth)
      .then(({ data }) => {
        commit("setUpdatedAtTemplate", data.template.updated_at);

        if (!hide_snackbar) {
          commit("setSnackBar", {
            color: "success",
            message: successMessages.template_editor,
            show: true,
          });
        }

        if (template.editor) {
          commit("setTemplateEditor", data.template.editor);
        }

        commit("updateSelectedTemplateDetails", data.template);

        commit("setLoading", false, { root: true });

        return data;
      })
      .catch(({ response: { data } }) => {
        commit("setSnackBar", {
          color: "error",
          message: errorMessages[data.code],
          show: true,
        });

        commit("setLoading", false, { root: true });

        return false;
      });
  },

  getDefaultTemplates({ commit }) {
    let url =
      process.env.VUE_APP_API_BASE_URL + "/conecta-sign/template-default";

    let auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    return this._vm.$axios
      .get(url, auth)
      .then(({ data }) => {
        commit("setTemplatesDefault", sortTemplatesByPopular(data.data));
      })
      .catch((err) => {
        console.error("Vuex: getDefaultTemplates(): ", err);
      });
  },

  getCustomTemplates({ commit }) {
    let url =
      process.env.VUE_APP_API_BASE_URL + "/conecta-sign/template-custom";

    let auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    return this._vm.$axios
      .get(url, auth)
      .then(({ data }) => {
        commit("setCustomTemplates", sortByLatest(data.data, "updated_at"));
      })
      .catch((err) => {
        console.error("Vuex: getCustomTemplates(): ", err);
      });
  },

  getSignatureSchedules({ commit }) {
    let url =
      process.env.VUE_APP_API_BASE_URL + "/conecta-sign/application-scheduling";

    let auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    return this._vm.$axios
      .get(url, auth)
      .then(({ data }) => {
        commit("setSignatureSchedules", sortByLatest(data, "created_at"));
      })
      .catch((err) => {
        console.error("Vuex: getSignatureSchedules(): ", err);
      });
  },

  deleteSignatureSchedule({ dispatch }, key) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/${key}/application-scheduling`;

    let auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    return this._vm.$axios
      .delete(url, auth)
      .then(() => {
        dispatch("getSignatureSchedules");
      })
      .catch((err) => {
        console.error("Vuex: deleteSignatureSchedule(): ", err);
      });
  },

  getAllSignatureTemplates({ commit, dispatch }) {
    return Promise.all([
      dispatch("getDefaultTemplates"),
      dispatch("getCustomTemplates"),
    ]).catch(() => {
      commit("setSnackBar", {
        message: "Erro ao recuperar os modelos de assinatura de e-mail",
        show: true,
        color: "error",
      });
    });
  },

  getSignatureTemplates({ getters, commit }) {
    if (!getters.hasSignPermission || !getters.hasConectaSign) return;

    const url =
      process.env.VUE_APP_API_BASE_URL + "/conecta-sign/signature/templates";

    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    return this._vm.$axios
      .get(url, auth)
      .then(({ data }) => {
        commit("setSignatureTemplates", data);
      })
      .catch((err) => {
        console.error("Vuex: getSignatureTemplates(): ", err);
      });
  },

  setSignatureTemplateType({ getters, commit }, { id, is_default }) {
    let url =
      process.env.VUE_APP_API_BASE_URL + `/conecta-sign/${id}/template-default`;

    let auth = {
      headers: {
        Authorization: getters.token,
      },
    };

    const updateTo = is_default === "default" ? "personalizado" : "padrão";

    return this._vm.$axios
      .put(url, {}, auth)
      .then(({ data }) => {
        commit("setSnackBar", {
          message: `Modelo atualizado para ${updateTo}`,
          show: true,
          color: "success",
        });

        commit("setSelectedSignTemplate", data);

        return data;
      })
      .catch((error) => {
        commit("setSnackBar", {
          message: `Não foi possível atualizar o modelo para ${updateTo}, por favor, atualize a página e tente novamente`,
          show: true,
          color: "error",
          timeout: 8000,
        });
        console.error("Vuex: setSignatureTemplateType()", error);
        return false;
      });
  },

  setSignaturePopular({ getters, commit }, { id, is_popular }) {
    let url =
      process.env.VUE_APP_API_BASE_URL + `/conecta-sign/${id}/set-popularity`;

    let auth = {
      headers: {
        Authorization: getters.token,
      },
    };

    return this._vm.$axios
      .put(url, { is_popular: !is_popular }, auth)
      .then(({ data }) => {
        commit("setSnackBar", {
          message: "Modelo atualizado com sucesso",
          show: true,
          color: "success",
        });
        commit("setSelectedSignTemplate", data);
        commit("updateTemplateInGlobal", data);

        return data;
      })
      .catch((error) => {
        commit("setSnackBar", {
          message: `Não foi possível atualizar o modelo, por favor, atualize a página e tente novamente`,
          show: true,
          color: "error",
          timeout: 8000,
        });
        console.error("Vuex: setSignaturePopular()", error);
        return false;
      });
  },

  getUserCurrentSignature({ commit }, id_google) {
    let url = process.env.VUE_APP_API_BASE_URL + "/user/sendas";

    const params = {
      id_user: id_google,
    };

    return this._vm.$axios
      .get(url, {
        params: {
          params,
        },
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        const { sendAs } = data;

        return sendAs.find((signature) => signature.isDefault);
      })
      .catch((error) => {
        commit("setSnackBar", {
          message: `Não foi possível obter a assinatura do usuário selecionado.`,
          show: true,
          color: "error",
          timeout: 8000,
        });
        console.error("Vuex: getUserCurrentSignature()", error);
        return false;
      });
  },
};

// mutations
const mutations = {
  setEditorLayout(state, layout) {
    state.editorLayout = layout;
  },

  setSelectedSignTemplate(state, template) {
    state.selectedTemplate = template;
    state.updatedHtml = template.html;
  },

  updateTemplateInGlobal(state, template) {
    const { signature_type } = template;

    const templates = state.templates[signature_type];
    state.templates[signature_type] = [];

    const templatesUpdated = templates.map((item) => {
      if (item.id === template.id) {
        return template;
      }

      return item;
    });

    state.templates[signature_type] = templatesUpdated;
  },

  updateSelectedTemplateDetails(state, template) {
    state.selectedTemplate = template;
  },

  updateSelectedTemplateHtml(state, html) {
    state.updatedHtml = html;
  },

  setSignatureStructure(state, structure) {
    state.signatureStructure = structure;
  },

  setCustomTemplates(state, templates) {
    state.templates.custom = templates;
  },

  setSignatureTemplates(state, signatureTemplates) {
    const { custom, standard } = signatureTemplates;

    state.templates.default = sortByLatest(custom);
    state.templates.custom = sortTemplatesByPopular(standard);
  },

  setTemplatesDefault(state, templates) {
    state.templates.default = templates;
  },

  setUpdatedAtTemplate(state, value) {
    state.updated_at = value;
  },

  setSignLoadingMessage(state, message) {
    if (message) {
      state.signLoadingMessage = message;
    } else {
      state.signLoadingMessage = "Carregando editor, aguarde...";
    }
  },

  setRichTextEditorRef(state, ref) {
    state.richTextEditorRef = ref;
  },

  setSelectedTemplateStructure(state, structure) {
    state.signatureStructure = structure;
  },

  setTemplateEditor(state, editor) {
    state.selectedTemplate.editor = editor;
  },

  setTemplateEditorLoading(state, status) {
    state.templateEditorLoading = status;
  },

  setReaplySignatureDialog(state, status) {
    state.showReaplyDialog = status;
  },

  setAppliedSignatureTemplateStatus(state, status) {
    state.appliedSignatureTemplateStatus = status;
  },
  setSignatureSchedules(state, schedules) {
    state.signatureSchedules = schedules;
  },
  setSignatureSuccessHintScreen(state, show = false) {
    state.showSignatureSuccessHintScreen = show;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
