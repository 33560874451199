const state = () => ({
  offboardingStep: parseInt(localStorage.getItem("offboardingStep")) || 1,
  userOffboardSelected: {},
});

const getters = {
  offboardingStepsNumber: () => 5,
  currentOffboardingStep: (state) => state.offboardingStep,
  userOffboardSelected: (state) => state.userOffboardSelected,
};

// actions
const actions = {};

// mutations
const mutations = {
  setOffboardingStep(state, step) {
    if (step) {
      state.offboardingStep = step;
      localStorage.setItem("offboardingStep", JSON.stringify(step));
    } else {
      state.offboardingStep = 1;
      localStorage.removeItem("offboardingStep");
    }
  },
  setUserOffboardSelected(state, user = {}) {
    state.userOffboardSelected = user;
    if (Object.keys(user).length)
      localStorage.setItem("offboardUser", JSON.stringify(user));
    else localStorage.removeItem("offboardUser");
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
