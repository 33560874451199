<template>
  <v-dialog v-model="show" persistent width="400">
    <v-card color="primary" dark>
      <v-card-text class="py-4 text-center font-weight-bold white--text">
        Sessão expirada, você deve fazer login novamente
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-2 my-4"
        ></v-progress-linear>
        <span class="font-weight-regular font-italic caption">
          Você será redirecionado em {{ seconds }}
          {{ seconds !== 1 ? "segundos" : "segundo" }}...
        </span>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  components: {},
  props: {
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      seconds: 5,
    };
  },
  watch: {
    show(value) {
      if (value) {
        setTimeout(() => {
          this.seconds--;
        }, 1000);
      }
    },

    seconds: {
      immediate: false,
      deep: true,
      handler(value) {
        if (this.show && value > 0) {
          setTimeout(() => {
            this.seconds--;
          }, 1000);
        } else if (value <= 0) {
          this.$emit("closeLogoutDialog");
          this.logout();
        }
      },
    },
  },
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>
