export const social = {
  "[*COMPANY_FACEBOOK_LOGO_PB*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Ffacebook_pb.png?alt=media&token=36437a6e-d562-47d2-ad3f-28612cc15791",
  "[*COMPANY_FACEBOOK_LOGO_C*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Ffacebook_c.png?alt=media&token=3b4033bf-fdec-43cf-9d48-dc358ad0d3e1",
  "[*COMPANY_INSTAGRAM_LOGO_PB*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Finstagram_logo.png?alt=media&token=abc76347-4fb3-4963-9801-5f26ca4cbbff",
  "[*COMPANY_INSTAGRAM_LOGO_C*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Finstagram%20(4).png?alt=media&token=6767064b-3828-4843-b7d7-b6ae609f53e6",
  "[*COMPANY_LINKEDIN_LOGO_PB*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Flinkedin_logo.png?alt=media&token=163fb1a2-efb7-4e88-9cec-e7845c4d0c82",
  "[*COMPANY_LINKEDIN_LOGO_C*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Flinkedin.png?alt=media&token=61d74bc2-f706-422f-8af9-ff683286ba6c",
  "[*COMPANY_TWITTER_LOGO_PB*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Ftwitter%20(2).png?alt=media&token=476cd7a5-7616-4722-ae70-03334d84ea0e",
  "[*COMPANY_TWITTER_LOGO_C*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Ftwitter%20(1).png?alt=media&token=f09cba4c-5928-456f-a985-efe22a801642",
  "[*COMPANY_YOUTUBE_LOGO_PB*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Fyoutube_logo.png?alt=media&token=46d42995-0382-4584-8da4-b31a5d19d6c8",
  "[*COMPANY_YOUTUBE_LOGO_C*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Fyoutube%20(1).png?alt=media&token=a99f140a-ed30-4390-9ac0-22fcaaccad56",
  "[*COMPANY_WHATSAPP_LOGO_PB*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Fwhatsapp_pb.png?alt=media&token=b24ee75a-d854-4dca-b1a4-acb694fcad23",
  "[*COMPANY_WHATSAPP_LOGO_C*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Fwhatsapp_c.png?alt=media&token=dd2fd72e-a3da-4e8a-b33e-77942feb7bf6",
  "[*USER_WHATSAPP_LOGO_PB*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Fwhatsapp_pb.png?alt=media&token=b24ee75a-d854-4dca-b1a4-acb694fcad23",
  "[*USER_WHATSAPP_LOGO_C*]":
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Fwhatsapp_c.png?alt=media&token=dd2fd72e-a3da-4e8a-b33e-77942feb7bf6",
};

export const images = {
  user: "https://lh3.googleusercontent.com/d/1qHWYtybtQpfuVC_qL_Kw_iwYtXjfLujy?authuser=0",
  company:
    "https://drive.google.com/thumbnail?id=1ZBpbRRwd5XxPJmE61d6nPH4-xOAzHjms&sz=w1000",
  conecta_sign:
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Fimagem_conecta_sign.jpg?alt=media&token=69a29c22-23ba-44d3-ac9b-bd2dfe694df0",
  conecta_control_vacation:
    "https://firebasestorage.googleapis.com/v0/b/conectasuite.appspot.com/o/media-logo%2Fimagem_ferias_conecta_control.jpg?alt=media&token=eb21b79b-997c-447b-becd-2f4f02134ed8",
};
