<template>
  <!-- Ref: https://codesandbox.io/s/flamboyant-cdn-e0nre?file=/src/components/resizable-page.vue -->
  <div
    v-resize="onResize"
    :class="`${$vuetify.breakpoint.xs ? 'mx-1' : 'mx-auto'} ${
      outlined ? 'outlined' : ''
    } ${rounded ? 'rounded-lg' : ''} d-flex flex-column`"
    :style="`${
      resizableMaxWidth ? `max-width: ${contentWidth}px` : ''
    } ${styles}`"
  >
    <slot name="header" />
    <div ref="resizableDiv" :class="`${bodyStyle}`">
      <slot name="table" :table-height="tableHeight" />
    </div>
    <v-card v-if="!hideFooter" class="py-2" flat>
      <slot name="footer" />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "resizable-page",
  data() {
    return {
      tableHeight: 0,
    };
  },
  props: {
    footerHeight: {
      type: Number,
      default: 55, //default v-data-table footer height
    },
    hideCustomSpace: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    resizableMaxWidth: {
      type: Boolean,
      default: true,
    },
    bodyStyle: {
      type: String,
      default: "",
    },
    styles: {
      type: String,
      default: "",
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["contentWidth"]),

    customEmptySpace() {
      if (!this.hideCustomSpace) {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 280;
        } else if (this.$vuetify.breakpoint.mobile) {
          return 220;
        }
        return 200;
      }
      return 0;
    },
  },
  methods: {
    onResize() {
      this.tableHeight =
        window.innerHeight -
        this.$refs.resizableDiv.getBoundingClientRect().y -
        this.footerHeight -
        this.customEmptySpace;
    },
  },
};
</script>
