const state = () => ({
  assignedUserLicenses: [],
});

const getters = {
  userLicenses: (state) => state.assignedUserLicenses,
};

const actions = {
  async getAssignedLicenses(_, email = "") {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/licenses?email=${email}`;

    const headers = {
      headers: { Authorization: this._vm.token },
    };

    return await this._vm.$axios.get(url, headers);
  },

  async removeGoogleWorkspaceLicenses(_, data = []) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/licenses`;
    const headers = { Authorization: this._vm.token };
    return await this._vm.$axios.delete(url, { headers, data });
  },
};

const mutations = {
  setGoogleWorkspaceLicensesAssigned(state, data) {
    state.assignedUserLicenses = data;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
