import store from "@/store";

import {
  getWhatsAppLink,
  formatDate,
  formatLink,
} from "@/helpers/services/utils";

import { images } from "@/helpers/variables/defaultImages";
import { socialTypes, USER_TAGS, COMPANY_TAGS } from "@/helpers/variables/tags";

import { changeDriveURL } from "@/helpers/services/images";
import { wartermarkerTemplate } from "@/helpers/variables/templates";

const imagesTags = [
  "[*USER_PHOTO*]",
  "[*USER_SIGN_PHOTO*]",
  "[*COMPANY_LOGO_URL*]",
  "[*COMPANY_BANNER_URL*]",
];
const imagesAlts = ["logo_url", "banner_url"];
const imagesAltsAndTags = [...imagesTags, ...imagesAlts];

function setLinkTagValues(element, previewUser) {
  const company = store.getters.company;

  element.getElementsByTagName("a").forEach((link) => {
    if (link.href.includes("[*COMPANY_INSTAGRAM*]")) {
      link.href = formatLink(company.social_media_instagram);
    } else if (link.href.includes("[*COMPANY_BANNER_LINK*]")) {
      link.href = formatLink(company.banner_link);
    } else if (link.href.includes("[*COMPANY_FACEBOOK*]")) {
      link.href = formatLink(company.social_media_facebook);
    } else if (link.href.includes("[*COMPANY_YOUTUBE*]")) {
      link.href = formatLink(company.social_media_youtube);
    } else if (link.href.includes("[*COMPANY_LINKEDIN*]")) {
      link.href = formatLink(company.social_media_linkedin);
    } else if (link.href.includes("[*COMPANY_TWITTER*]")) {
      link.href = formatLink(company.social_media_twitter);
    } else if (link.href.includes("[*COMPANY_WHATSAPP*]")) {
      link.href = getWhatsAppLink(company.social_media_whatsapp);
    } else if (link.href.includes("[*USER_WHATSAPP*]")) {
      link.href = getWhatsAppLink(previewUser.whatsapp_number);
    } else if (link.href.includes("[*COMPANY_SITE*]")) {
      link.href = formatLink(company.site);
    } else if (link.href.includes("[*CALENDAR_SCHEDULES_LINK*]")) {
      link.href = formatLink(previewUser.calendar_schedules_link);
    }
  });
}

function removeHttpWWW(href) {
  const httpWWWRegex =
    /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)/i;
  return (href || "").replace(httpWWWRegex, "");
}
function removeHrefEnd(href) {
  const hrefEndRegex = /(\/#|\/\?|\/)$/i;
  return (href || "").replace(hrefEndRegex, "");
}
function shouldHrefToTag(href = "", link = "") {
  const linkWOHttpWWWEndFixed = removeHrefEnd(removeHttpWWW(link));
  const hrefEndFixed = removeHrefEnd(href);
  return (
    link &&
    href.includes(linkWOHttpWWWEndFixed) &&
    hrefEndFixed.endsWith(linkWOHttpWWWEndFixed)
  );
}

function setLinkTags(element) {
  const company = store.getters.company;
  const currentUser = store.getters.currentUser;

  element.getElementsByTagName("a").forEach((link) => {
    if (shouldHrefToTag(link.href, company.banner_link)) {
      link.href = "[*COMPANY_BANNER_LINK*]";
    } else if (shouldHrefToTag(link.href, company.social_media_instagram)) {
      link.href = "[*COMPANY_INSTAGRAM*]";
    } else if (shouldHrefToTag(link.href, company.social_media_facebook)) {
      link.href = "[*COMPANY_FACEBOOK*]";
    } else if (shouldHrefToTag(link.href, company.social_media_twitter)) {
      link.href = "[*COMPANY_TWITTER*]";
    } else if (shouldHrefToTag(link.href, company.social_media_youTube)) {
      link.href = "[*COMPANY_YOUTUBE*]";
    } else if (shouldHrefToTag(link.href, company.social_media_linkedin)) {
      link.href = "[*COMPANY_LINKEDIN*]";
    } else if (
      shouldHrefToTag(link.href, currentUser.calendar_schedules_link)
    ) {
      link.href = "[*CALENDAR_SCHEDULES_LINK*]";
    } else if (
      shouldHrefToTag(link.href, getWhatsAppLink(currentUser.whatsapp_number))
    ) {
      link.href = "[*USER_WHATSAPP*]";
    } else if (
      shouldHrefToTag(link.href, getWhatsAppLink(company.social_media_whatsapp))
    ) {
      link.href = "[*COMPANY_WHATSAPP*]";
    }
  });
}

function addWatermark(element) {
  const watermarkTemplate = new DOMParser().parseFromString(
    wartermarkerTemplate,
    "text/html"
  );

  let wartermarkerTable = watermarkTemplate
    .getElementsByTagName("table")
    .item(0);

  const watermarkInElement = element.getElementById("watermark");

  if (watermarkInElement) {
    element.body.removeChild(watermarkInElement);
  }
  element.body.appendChild(wartermarkerTable);
}

function setImageTags(element) {
  const regexAltReplace = new RegExp("[\\[\\*\\]]", "g");
  const currentUser = store.getters.currentUser;
  element.getElementsByTagName("img").forEach((image) => {
    if (imagesAltsAndTags.includes(image.alt)) {
      if (image.alt === "banner_url") {
        image.src = "[*COMPANY_BANNER_URL*]";
        image.alt = "[*COMPANY_BANNER_URL*]".replace(regexAltReplace, "");
      } else if (image.alt === "logo_url") {
        image.src = "[*COMPANY_LOGO_URL*]";
        image.alt = "[*COMPANY_LOGO_URL*]".replace(regexAltReplace, "");
      } else {
        image.src = image.alt.slice();
      }
    } else if (
      [
        store.getters.companyLogo,
        store.getters.companyBanner,
        currentUser.photo,
        currentUser.sign_photo,
      ].includes(image.src)
    ) {
      if (image.src == store.getters.companyLogo) {
        image.src = "[*COMPANY_LOGO_URL*]";
        image.alt = "[*COMPANY_LOGO_URL*]".replace(regexAltReplace, "");
      } else if (image.src == store.getters.companyBanner) {
        image.src = "[*COMPANY_BANNER_URL*]";
        image.alt = "[*COMPANY_BANNER_URL*]".replace(regexAltReplace, "");
      } else if (image.src == currentUser.photo) {
        image.src = "[*USER_PHOTO*]";
        image.alt = "[*USER_PHOTO*]".replace(regexAltReplace, "");
      } else if (image.src == currentUser.sign_photo) {
        image.src = "[*USER_SIGN_PHOTO*]";
        image.alt = "[*USER_SIGN_PHOTO*]".replace(regexAltReplace, "");
      }
    }
  });
}

/**
 * Function set default images instead of user or company images tags.
 * It's used to preview TAG images while editing them inside Conecta Sign.
 * @param {String} html The signature html
 * @param {String} isPreview Send an object to use that contains the images of each tag
 * @return {String} The html with default images
 */
function setDefaultImage(html, isPreview = false) {
  const element = new DOMParser().parseFromString(html, "text/html");

  setImageTagValues(element, isPreview, isPreview);

  return convertDomToHtmlString(element);
}

export function fixDriveUrls(html) {
  const element = new DOMParser().parseFromString(html, "text/html");

  element.getElementsByTagName("img").forEach((image) => {
    image.src = changeDriveURL(image.src);
  });

  return convertDomToHtmlString(element);
}

function setSocialTypesTags(element, socialTypes) {
  element.getElementsByTagName("a").forEach((link) => {
    const image = link.getElementsByTagName("img")?.[0] || {};
    if (socialTypes[image.alt] && link.src != socialTypes[image.alt]) {
      link.href = socialTypes[image.alt];
    }
  });
}

function setSocialTypesTagValues(element, socialTypes) {
  element.getElementsByTagName("a").forEach((link) => {
    const image = link.getElementsByTagName("img")?.[0] || {};
    if (socialTypes[image.alt] && link.src != socialTypes[image.alt]) {
      image.alt = socialTypes[image.alt];
    }
  });
}

/**
 * Function to style images inside an signature html.
 * @param {String} html The signature html
 * @return {String} The html with image and style
 */
export function applyImageStyles(html, removeClasses = true) {
  var element = new DOMParser().parseFromString(html, "text/html");

  element.getElementsByTagName("img").forEach((image) => {
    if (image.className) {
      if (image.className === "img_rounded_border") {
        image.style.borderRadius = "15%";
      } else if (image.className === "img_circle_border") {
        image.style.borderRadius = "100%";
      } else {
        image.style.borderRadius = 0;
      }
    }
    image.src = changeDriveURL(image.src);

    if (removeClasses) {
      image.className = "";
    }
    html = element.innerHTML;
  });

  return convertDomToHtmlString(element);
}

/**
 * Function to get the elements inside the columns
 * @param {String} html The signature html
 * @return {Array} An array with the content of each column
 */
export function getTableColumnsContent(html) {
  var element = new DOMParser().parseFromString(html, "text/html");

  let columns = [];

  element.getElementsByTagName("td").forEach((column) => {
    let columnElements = [];

    let stringArrayOfElements = column.innerHTML.replace("\n", "");

    const doc = new DOMParser().parseFromString(
      stringArrayOfElements,
      "text/html"
    );
    const arrayOfElements = [...doc.body.childNodes].map(
      (child) => child.outerHTML
    );

    arrayOfElements.forEach((htmlElement) => {
      if (htmlElement) {
        columnElements.push(setDefaultImage(htmlElement));
      }
    });

    columns.push(columnElements);
  });

  return columns;
}

export function getCenterContentElements(html) {
  var element = new DOMParser().parseFromString(html, "text/html");
  let columnElements = [];

  element.getElementsByTagName("td").forEach((column) => {
    let stringArrayOfElements = column.innerHTML.replace("\n", "");

    const doc = new DOMParser().parseFromString(
      stringArrayOfElements,
      "text/html"
    );
    const arrayOfElements = [...doc.body.childNodes].map(
      (child) => child.outerHTML
    );

    arrayOfElements.forEach((htmlElement) => {
      if (htmlElement) {
        columnElements.push(setDefaultImage(htmlElement));
      }
    });
  });

  return columnElements;
}

export function getDraggableElementsByType(elements_list) {
  let elementsByType = {};

  elements_list.forEach((list) => {
    if (JSON.stringify(list).includes("img")) {
      elementsByType.images = list;
    } else {
      elementsByType.texts = list;
    }
  });

  return elementsByType;
}

export function getDivAlign(html) {
  var element = new DOMParser().parseFromString(html, "text/html");

  const divAlign = element.getElementsByTagName("div")[0];

  if (divAlign) {
    return divAlign.style ? divAlign.style.textAlign : "";
  } else {
    return "";
  }
}

export function getImageWidth(html) {
  var element = new DOMParser().parseFromString(html, "text/html");

  const image = element.getElementsByTagName("img")[0];

  return image.width ? image.width : 200;
}

export function getImageHeight(html) {
  var element = new DOMParser().parseFromString(html, "text/html");

  const image = element.getElementsByTagName("img")[0];

  const imageWidth = image.width || 200;

  return image.height ? image.height : imageWidth;
}

export function getImageSrc(html) {
  var element = new DOMParser().parseFromString(html, "text/html");

  const image = element.getElementsByTagName("img")[0];

  return image.src ? image.src : "";
}

export function getImageBorderRadius(html) {
  var element = new DOMParser().parseFromString(html, "text/html");

  const image = element.getElementsByTagName("img")[0];

  const borderRadius = image.style.borderRadius || "";

  return borderRadius !== "0%" && borderRadius !== 0 ? borderRadius : "";
}

export function getImageTag(html) {
  var element = new DOMParser().parseFromString(html, "text/html");

  const image = element.getElementsByTagName("img")[0];

  return image.alt ? image.alt : "";
}

export function getTextBetweenHtmlTag(html, image = false) {
  const style = /<([^> ]+)([^>]*)>([^<]+)(<\/\1>)/;

  const updatedHtml = image ? fluxStringToTemplate(html) : html;

  const regexSearch = updatedHtml.match(style);

  return regexSearch ? regexSearch[3] : "";
}

export function getElementLink(html) {
  var element = new DOMParser().parseFromString(html, "text/html");

  const link = element.getElementsByTagName("a")[0];

  if (link) {
    return link.alt ? link.alt : link.href;
  }
  return "";
}

export function verifyIfHasATag(html, tag) {
  return !!html.includes(tag);
}

/**
 * Function to convert DOM HTML into string html
 * @param {Object} element The DOM element we want to convert
 * @return {String} A string html
 */
export function convertDomToHtmlString(element) {
  let stringHtml = element.getElementsByTagName("body")[0].outerHTML;

  stringHtml = stringHtml.replace("<body>", "").replace("</body>", "");

  return stringHtml;
}

export function startOfTheNextImage(html, dismissedCharacters, aux) {
  let aux_html = html.substring(dismissedCharacters);
  let aux_start = aux_html.indexOf(aux);
  if (aux_start < 0) {
    return -1;
  } else {
    return aux_start + dismissedCharacters;
  }
}

/**
 * Function to get the object style, used in Drag and Drop editor
 * @param {String} htmlString The string html that represent the element
 * @return {String} String that represents the element style (if has)
 */
export function getElementStyle(htmlString) {
  const style = new RegExp('styles*=s*"([^"]*)"');
  const regexSearch = htmlString.match(style);
  return regexSearch ? regexSearch[1] : "";
}

/**
 * Function to set the object style, used in Drag and Drop editor
 * @param {String} htmlString The string html that represent the element
 * @param {String} newStyle The string that represent the new style
 * @return {String} string with style updated
 */
export function setElementStyle(htmlString, newStyle) {
  const style = new RegExp('style="[^"]*"');

  if (htmlString.match(style)) {
    htmlString = htmlString.replace(style, `style="${newStyle}"`);
  }

  return htmlString;
}

export function replaceTags(html, user) {
  const company = store.getters.company;
  // TODO: remover [* *] dos demaisl alt
  const regexFind = new RegExp("\\[\\*.+?\\*\\]", "g");
  const tags = html.match(regexFind);
  const regexReplace = new RegExp("[\\[\\*\\]]", "g");

  if (tags) {
    tags.forEach((tag) => {
      if (!imagesAltsAndTags.includes(tag)) {
        let tagValue = __getTagValue(tag.replace(regexReplace, ""), {
          user,
          company,
        });

        if (!tagValue) {
          tagValue = "";
        }

        html = html.replace(tag, tagValue);
      }
    });
  }

  return html;
}

function __getTagValue(tag, { user, company }) {
  if (user && tag in USER_TAGS) {
    if (tag === "USER_WHATSAPP") {
      return getWhatsAppLink(user[USER_TAGS[tag]]);
    } else if (
      ["VACATION_INIT_DATE", "VACATION_END_DATE"].includes(tag) &&
      !user[USER_TAGS[tag]]
    ) {
      if (tag === "VACATION_INIT_DATE") {
        return store.getters.temporaryStartDate
          ? formatDate(store.getters.temporaryStartDate)
          : "DD/MM/AAAA";
      } else if (tag === "VACATION_END_DATE") {
        return store.getters.temporaryEndDate
          ? formatDate(store.getters.temporaryEndDate)
          : "DD/MM/AAAA";
      }
    } else if (
      ["VACATION_INIT_DATE", "VACATION_END_DATE"].includes(tag) &&
      user[USER_TAGS[tag]]
    ) {
      return formatDate(user[USER_TAGS[tag]]);
    }

    return user[USER_TAGS[tag]];
  }

  const { custom_tags: userCustomTags } = user;

  if (user && store.getters.usersCustomTagKeys.includes(tag)) {
    if (!userCustomTags[tag]) {
      return "";
    }
    return userCustomTags[tag].value;
  }

  if (company && tag in COMPANY_TAGS) {
    if (tag === "COMPANY_WHATSAPP") {
      return getWhatsAppLink(company[COMPANY_TAGS[tag]]);
    }

    return company[COMPANY_TAGS[tag]];
  }

  const { custom_tags } = company;

  if (company && Object.keys(custom_tags).includes(tag)) {
    return custom_tags[tag].value;
  }

  return "NOT FOUND";
}

export function getHtmlFromObject(object, currentUser, company) {
  const isImage =
    object.value == "[*COMPANY_LOGO_URL*]" ||
    object.value == "[*COMPANY_BANNER_URL*]" ||
    object.value == "[*USER_PHOTO*]" ||
    object.value == "[*USER_SIGN_PHOTO*]";

  const isWhatsapp =
    object.value == "[*USER_WHATSAPP*]" ||
    object.value == "[*COMPANY_WHATSAPP*]";

  if (isImage) {
    return setDefaultImage(
      `<img src="${object.value}" alt="${object.value}" width="250" />`
    );
  } else if (isWhatsapp) {
    if (object.value == "[*USER_WHATSAPP*]") {
      return `<a href="${getWhatsAppLink(
        currentUser.whatsapp_number
      )}" target="_blank">${object.value}</a>`;
    } else {
      return `<a href="${getWhatsAppLink(
        company.social_media_whatsapp
      )}" target="_blank">${object.value}</a>`;
    }
  } else if (object.type === "url" && !isImage) {
    return `<a style="${object.styles}" href="${object.value}">${object.value}</a>`;
  } else {
    return `<p style="${object.styles}">${object.value}</p>`;
  }
}

function setImageTagValues(element, hasPreviewUser, previewUser) {
  element.getElementsByTagName("img").forEach((image) => {
    if (image.alt !== image.src && imagesAltsAndTags.includes(image.alt)) {
      if (["banner_url", "[*COMPANY_BANNER_URL*]"].includes(image.alt)) {
        image.src =
          store.getters.companyBanner ||
          store.getters.companyBannerPreviewImage;
        image.alt = "[*COMPANY_BANNER_URL*]";
      } else if (["logo_url", "[*COMPANY_LOGO_URL*]"].includes(image.alt)) {
        image.src = store.getters.companyLogo || images.company;
        image.alt = "[*COMPANY_LOGO_URL*]";
      } else if (image.alt === "[*USER_SIGN_PHOTO*]") {
        if (hasPreviewUser) {
          image.src = previewUser.sign_photo || images.user;
        } else {
          image.src = images.user;
        }
      } else {
        if (hasPreviewUser) {
          let img = "";
          try {
            img = previewUser.user.photo;
          } catch {
            img = previewUser.photo;
          }
          image.src = img || store.getters.currentUserPhoto || images.user;
        } else {
          image.src = images.user;
        }
      }
    }
    image.src = changeDriveURL(image.src);
  });
}

export function fluxTemplateToPreview(
  htmlString = "",
  user = {},
  showWatermark = false
) {
  const hasPreviewUser = Object.keys(user).length > 0;

  const previewUser = hasPreviewUser ? user : store.getters.currentUser;

  let html = htmlString.slice();

  const element = new DOMParser().parseFromString(html, "text/html");

  setSocialTypesTagValues(element, socialTypes);

  setLinkTagValues(element, previewUser);

  setImageTagValues(element, hasPreviewUser, previewUser);

  if (showWatermark) {
    addWatermark(element);
  }

  html = applyImageStyles(convertDomToHtmlString(element), true);

  if (hasPreviewUser) {
    html = replaceTags(html, previewUser);
  }

  return html;
}

export function fluxTemplateToEditor(html, applyStyles = true) {
  const element = new DOMParser().parseFromString(html, "text/html");

  setImageTags(element);

  setImageTagValues(element);

  setLinkTags(element);

  setSocialTypesTags(element, socialTypes);

  if (applyStyles) {
    return applyImageStyles(convertDomToHtmlString(element), true);
  } else {
    return convertDomToHtmlString(element);
  }
}

export function fluxStringToTemplate(html, applyStyles = true) {
  const element = new DOMParser().parseFromString(html, "text/html");

  setImageTags(element);

  setLinkTags(element);

  setSocialTypesTags(element, socialTypes);

  if (applyStyles) {
    return applyImageStyles(convertDomToHtmlString(element), true);
  } else {
    return convertDomToHtmlString(element);
  }
}

export function insertLinkInTagImage(html, tag) {
  if (Object.keys(socialTypes).includes(tag)) {
    return `<a href="${socialTypes[tag]}" target="_blank">${html}</a>`;
  } else {
    return html;
  }
}
