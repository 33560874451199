<template>
  <v-card v-if="show" flat class="meet-banner elevation-5" max-width="400">
    <v-toolbar color="primary" dark extended flat>
      <v-spacer></v-spacer>

      <v-btn :disabled="declined || accepted" icon @click="manualCloseInvite">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card
      color="transparent"
      elevation="0"
      class="mx-auto d-flex flex-column align-center px-8"
      max-width="700"
      style="margin-top: -64px"
    >
      <v-avatar size="100" class="mx-auto border-white">
        <img :src="getAttendentCalling.photo" />
      </v-avatar>

      <v-card-title
        class="text-h5 font-weight-bold primarySuite--text text--lighten-3 pt-1"
      >
        {{ getAttendentCalling.name }}
      </v-card-title>

      <v-card-subtitle class="text-center font-weight-bold">
        Especialista Conecta Suite
      </v-card-subtitle>

      <v-card
        max-width="380"
        class="mx-auto d-flex flex-column align-center elevation-0 grey lighten-3"
      >
        <v-card-text
          style="max-width: 300px"
          class="text-center font-weight-medium"
        >
          Está convidando você para uma conversa ao vivo no Google Meet.
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-if="!declined"
            large
            :disabled="accepted"
            color="green"
            class="text-none text-body-1 font-weight-medium text-center px-4 white--text"
            :class="accepted ? '' : 'pulse'"
            @click="acceptCall"
          >
            <v-icon
              v-text="'mdi-phone'"
              class="pa-2"
              :class="accepted ? '' : 'shake'"
            />
            {{ accepted ? "Convite Aceito" : "Entrar" }}
          </v-btn>
          <v-btn
            v-if="!accepted"
            large
            color="red"
            class="text-none text-body-1 font-weight-medium text-center px-4 white--text"
            :disabled="declined"
            @click="declineCall"
          >
            <v-icon v-text="'mdi-phone-hangup'" class="pa-2" />
            {{ declined ? "Convite Recusado" : "Recusar" }}
          </v-btn>
        </v-card-actions>
        <v-card-text v-if="!declined" class="caption text-center">
          O convite será recusado automaticamente em
          {{ formatTime(timeRemaining) }}
        </v-card-text>
        <v-card-text v-else class="caption text-center">
          Essa janela será fechada em {{ formatTime(timeRemaining) }}
        </v-card-text>
      </v-card>
      <v-btn
        large
        outlined
        block
        color="primarySuite"
        class="text-none text-body-1 font-weight-medium my-4"
        @click="scheduleMeeting"
      >
        <v-icon v-text="'mdi-phone-clock'" class="pa-2" />
        Agendar em outro horário
      </v-btn>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getDatabase, ref, update } from "firebase/database";
import { redirectToTheCommercialManagerGoogleCalendar } from "@/helpers/services/utils";
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";

export default {
  name: "BannerMeetInvite",

  data() {
    return {
      meetLink: "",
      timeRemaining: 300, // 5 minutos em segundos
      countdownInterval: null,
      declined: false,
      accepted: false,
    };
  },
  components: {},

  computed: {
    ...mapGetters([
      "showMeetInviteDialog",
      "getAttendentCalling",
      "getMeetLink",
      "currentUser",
      "company",
    ]),

    show: {
      get() {
        return this.showMeetInviteDialog;
      },
      set(newValue) {
        this.setMeetInviteDialog(newValue);
        this.resetInfo();
      },
    },
  },

  methods: {
    ...mapMutations(["setMeetInviteDialog"]),

    resetInfo() {
      this.declined = false;
      this.accepted = false;
      this.timeRemaining = 300;
    },

    declineCall() {
      this.declined = true;
      this.timeRemaining = 5;
      this.updateDeclinedRTDB();

      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const action = "declined";

      logEvent(analytics, "commercial_call", {
        main_domain,
        action,
        date: moment().format("DD/MM/YYYY"),
      });
    },

    manualCloseInvite() {
      this.updateDeclinedRTDB();
      this.show = false;
    },

    timeOutCloseInvite() {
      if (!this.declined && !this.accepted) {
        this.updateExpiredRTDB();
      }
      this.show = false;
    },

    updateDeclinedRTDB() {
      const db = getDatabase();
      const waitingRef = ref(db, `waiting/${this.currentUser.key}/meetCall`);
      update(waitingRef, {
        status: "declined",
      });

      const attendantRef = ref(
        db,
        `attendant/${this.getAttendentCalling.key}/meetCall`
      );
      update(attendantRef, {
        status: "declined",
      });
    },

    updateExpiredRTDB() {
      const db = getDatabase();
      const waitingRef = ref(db, `waiting/${this.currentUser.key}/meetCall`);
      update(waitingRef, {
        status: "expired",
      });

      const attendantRef = ref(
        db,
        `attendant/${this.getAttendentCalling.key}/meetCall`
      );
      update(attendantRef, {
        status: "expired",
      });

      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const action = "expired";

      logEvent(analytics, "commercial_call", {
        main_domain,
        action,
        date: moment().format("DD/MM/YYYY"),
      });
    },

    acceptCall() {
      window.open(this.getMeetLink, "_blank");
      this.accepted = true;
      this.timeRemaining = 5;
      this.updateAcceptedRTDB();

      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const action = "accepted";

      logEvent(analytics, "commercial_call", {
        main_domain,
        action,
        date: moment().format("DD/MM/YYYY"),
      });
    },

    updateAcceptedRTDB() {
      const db = getDatabase();
      const waitingRef = ref(db, `waiting/${this.currentUser.key}/meetCall`);
      update(waitingRef, {
        status: "accepted",
      });

      const attendantRef = ref(
        db,
        `attendant/${this.getAttendentCalling.key}/meetCall`
      );
      update(attendantRef, {
        status: "accepted",
      });
    },

    scheduleMeeting() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "opened from meet calling banner";
      const clicked_from = "Agendar ao recusar chamada de reunião pelo Suite";

      logEvent(analytics, "schedule_meeting", {
        main_domain,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });

      redirectToTheCommercialManagerGoogleCalendar(clicked_from);
    },

    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    },
  },
  mounted() {
    this.countdownInterval = setInterval(() => {
      if (this.timeRemaining > 0) {
        this.timeRemaining--;
      } else {
        this.timeOutCloseInvite();
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval);
  },
};
</script>

<style scoped>
.meet-banner {
  position: fixed;
  bottom: 120px;
  right: 16px;
  z-index: 9999;
  border-bottom: 0 solid;
}

.border-white {
  border: 4px solid white;
}

.pulse {
  animation: pulse 1s infinite;
}

.shake {
  animation: shake 0.5s infinite;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.6);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
