const state = () => ({});

const getters = {};

// actions
const actions = {
  async saveUsersTags({ commit, dispatch }, users) {
    const hasManyUsers = users.length >= 500;

    const url = `${process.env.VUE_APP_API_BASE_URL}/users/tags/batch${
      hasManyUsers ? ":async" : ""
    }`;
    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };
    const payload = {
      tags: users,
    };
    await this._vm.$axios
      .post(url, payload, auth)
      .then(() => {
        dispatch("getUsers", { root: true });
        commit(
          "setSnackBar",
          {
            message: hasManyUsers
              ? "As informações dos usuários serão atualizadas em breve"
              : "As informações dos usuários foram atualizadas",
            show: true,
          },
          { root: true }
        );
      })
      .catch((error) => {
        console.error(error);

        commit(
          "setSnackBar",
          {
            message:
              "Não foi possível atualizar as informações dos usuários, por favor, tente novamente.",
            color: "error",
            show: true,
          },
          { root: true }
        );
      });
  },
};

// mutations
const mutations = {};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
