var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-alert',{class:`${_vm.expansion ? 'cursor-pointer' : ''}`,attrs:{"outlined":_vm.outlined,"text":_vm.text,"prominent":_vm.prominent,"icon":_vm.getIcon,"elevation":_vm.elevation,"max-width":_vm.max_width,"color":_vm.color,"dense":_vm.dense,"dark":_vm.dark || !_vm.text,"tile":_vm.tile},on:{"click":_vm.changeShowHide}},[_c('v-row',{class:`pa-0 ma-0 ${_vm.prominent ? 'mx-4' : 'mx-1'} ${
      _vm.dense ? 'text-body-2' : ''
    }`,attrs:{"align":"center","dense":"","no-gutters":""}},[_c('v-col',{class:`${
        _vm.$vuetify.theme.dark || _vm.dark || !_vm.text
          ? 'white--text'
          : `${_vm.color}--text text--darken-4`
      } ${_vm.text ? `${_vm.color}--text text--darken-2` : ''} alert-link pa-0`,attrs:{"cols":"12","lg":_vm.actionText ? 9 : 12,"sm":"12"}},[_vm._t("default")],2),(_vm.actionText)?_c('v-col',{class:`${
        _vm.$vuetify.breakpoint.smAndUp ? 'd-flex justify-end' : ''
      } pa-0 ${_vm.$vuetify.breakpoint.smAndDown ? 'mt-4' : ''}`,attrs:{"cols":"12","lg":"3","sm":"12"}},[_c('v-btn',{staticClass:"text-body-1 font-weight-medium",attrs:{"dark":!_vm.text || _vm.dark,"text":_vm.text,"color":_vm.text ? _vm.color : '',"small":"","outlined":_vm.$vuetify.breakpoint.smAndDown,"block":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){return _vm.$emit('action')}}},[(_vm.actionIcon)?_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.actionIcon)}}):_vm._e(),_vm._v(" "+_vm._s(_vm.actionText)+" ")],1)],1):_vm._e()],1),_c('v-expand-transition',[(_vm.showHideExpansion && _vm.expansion)?_c('v-row',{class:`pa-0 ma-0 ${_vm.prominent ? 'mx-4' : 'mx-1'} ${
        _vm.dense ? 'text-body-2' : ''
      } ${
        _vm.$vuetify.theme.dark || _vm.dark || !_vm.text
          ? 'white--text'
          : `${_vm.color}--text text--darken-4`
      } ${_vm.text ? `${_vm.color}--text text--darken-2` : ''}`,attrs:{"align":"center","dense":"","no-gutters":""}},[_vm._t("expansion")],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }