import { modules } from "@/helpers/variables/modules";
import { responseStatus } from "@/helpers/variables/backendConstants";
import store from "@/store";

export function verifyPagePermission(permissions, app, admin = false) {
  // Páginas especiais que precisam mais do que a permissão do suite apenas
  const isSuiteSpecialPage =
    permissions.includes(modules.suite) && permissions.length > 1;

  // Páginas que precisam da permissão de reseller
  const isResellerPage = isSuiteSpecialPage && permissions.includes("reseller");

  if (isSuiteSpecialPage) {
    if (isResellerPage) {
      if (store.getters.isReseller) {
        return store.getters.hasSuitePagePermission[app]
          ? store.getters.hasSuitePagePermission[app]
          : responseStatus.USER_IS_NOT_ADMIN;
      } else {
        return responseStatus.USER_IS_NOT_ALLOWED_TO_ACCESS_THIS_RESOURCE;
      }
    } else if (admin) {
      return store.getters.isConecta ? "ACCESS_ALLOWED" : false;
    } else {
      return store.getters.hasSuitePagePermission[app]
        ? "ACCESS_ALLOWED"
        : responseStatus.USER_IS_NOT_ADMIN;
    }
  } else {
    if (
      store.getters.disabledTrialProducts.includes(app) ||
      !store.getters.activeProducts.includes(app)
    ) {
      return responseStatus.PRODUCT_IN_DISABLED_PLAN;
    }

    return store.getters.hasProductPermission[app];
  }
}

// TODO: quando tiver internacionalização, essa const poderá ir para lá
const pageTitle = {
  // Routes Suite
  Home: "Início",
  Users: "Usuários",
  AdminFunctions: "Permissões de usuários",
  Reports: "Relatórios",
  ReportsSignatures: "Relatórios de assinaturas de e-mail",
  AccountsReports: "Relatórios de contas de usuários",
  Inventory: "Inventário digital",
  Company: "Perfil da Empresa",
  User: "Perfil do Usuário",
  BillingPlans: "Meus planos e serviços",
  BillingAddPlan: "",
  BillingUpgradePlan: "Contratar plano",
  BillingTransactions: "Histórico de Cobrança",
  PlansManager: "Gerenciar planos",
  ServicesManager: "Gerenciar serviços",
  BanClients: "Clientes banidos",
  ResellerClients: "Clientes",
  ResellerClientInfo: "Perfil do Cliente",
  ResellerNewPlan: "Plano do Cliente",
  ResellerNewService: "Serviço do Cliente",
  ResellerBilling: "Faturamento",
  CentralNotifications: "Central de notificações",
  // Routes Sign
  SignMain: "Conecta Sign",
  SignTemplates: "Modelos de assinatura",
  TemplateEdit: "Edição de modelo",
  SignOrganizationUnits: "",
  ApplySignTemplates: "Aplicar em massa",
  SignAutomation: "Aplicação automática",
  SignTags: "Informações da Empresa",
  VacationMessageTemplates: "Modelos de mensagens de férias",
  NewVacationTemplate: "Criar modelo",
  VacationTemplateEdit: "Edição de modelo",
  // Routes Track
  TrackUsers: "Conecta Track",
  TrackUser: "Histórico do usuário",
  // Routes General
  Auth: "",
  AuthCallback: "",
  Login: "",
  NotFound: "",
  Disabled: "",
  UserConfirmation: "",
  UserTagsConfirmation: "Atualização das informações de Usuário",
  SignDragInDropEditor: "Editor de assinaturas de e-mail",
  SignSelectEditor: "Selecionar tipo de editor",
};

export function getPageTitleTranslate(routeName) {
  // TODO: quando tiver internacionalização, atualizar essa função para pegar a tradução baseado na opção de linguagem
  return pageTitle[routeName];
}
